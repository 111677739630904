import React, { useEffect, useState } from 'react';
import Profile from "../../assets/images/profile/profile.png"
import ApiService from '../../services/api.service'
import Dot from '../../components/icon/Dot'
import Pencil from '../../components/icon/Pencil'
import Trash from '../../components/icon/Trash'
import { toastSuccess, toastError } from '../../components/toast';

export default function DashboardAdmin() {

    const [company, setCompany] = useState([])
    const [paginate, setPaginate] = useState({
        'firstPage': 1,
        'lastPage': 1,
        'totalPage': 1,
        'activePage': 1,
        'from': 0,
        'to': 0
    })
    const [currentCompany, setCurrentCompany] = useState(null)
    const [currentCompanyExpiredAt, setCurrentCompanyExpiredAt] = useState(null)
    const [toggleEdit, setToggleEdit] = useState(false)


    function showEditForm(value) {
        setCurrentCompany({
            'id': value.id,
            'lisenceId': value?.owner?.lisenceId,
            'maxMeetingRoom': value.maxMeetingRoom,
            'maxCampaign': value.maxCampaign,
            'maxBranch': value.maxBranch,
            'maxUserContentDesigner': value.maxUserContentDesigner,
            'maxUserContentPublisher': value.maxUserContentPublisher,
            'maxMedia': value.maxMedia,
            'maxCampaign': value.maxCampaign,
            'companyName': value.companyName,
        })

        let expiredAt = new Date(value.createdAt)
        expiredAt.setDate(new Date(value.createdAt).getDate() + 30 + value?.masaTrial ?? 0)
        setCurrentCompanyExpiredAt(expiredAt.toISOString().slice(0, 10))

        setToggleEdit(true)
    }

    function CompanyList() {
        if (Object.keys(company).length !== 0) {
            let companyList = [];

            for (const [index, value] of company.entries()) {
                let expiredAt = new Date(value.createdAt);
                expiredAt.setDate(expiredAt.getDate() + 30 + value?.masaTrial ?? 0);

                companyList.push(
                    <tr key={'company-' + index}>
                        <td className='text-[#292D32] font-medium text-[12px] font-[Inter] capitalize px-[16px] 2xl:px-[32px] py-[16px]'>
                            {value?.owner?.name}
                        </td>
                        <td className='text-[#292D32] font-medium text-[12px] font-[Inter] capitalize px-[16px] 2xl:px-[32px] py-[16px]'>
                            {value?.owner?.email}
                        </td>
                        <td className='text-[#14AE5C] font-medium text-[12px] font-[Inter] capitalize px-[0px] 2xl:px-[32px] py-[16px]'>
                            {value?.owner?.lisenceId == 3 ? "Premium Multibranch" : value?.owner?.lisenceId == 2 ? "Lite Premium Single Branch" : "Trial"}
                        </td>
                        <td className='text-[#292D32] font-medium text-[12px] font-[Inter] capitalize px-[16px] 2xl:px-[32px] py-[16px]'>
                            {expiredAt != undefined ? expiredAt.toISOString() : '-'}
                        </td>
                        <td>
                            <div className="dropdown dropdown-end">
                                <label tabIndex={0} className="cursor-pointer bg-[gray]">
                                    <div className="w-10 rounded-full flex justify-end">
                                        <Dot />
                                    </div>
                                </label>
                                <ul
                                    tabIndex={0}
                                    className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-[113px]"
                                >
                                    <li className="border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]">
                                        <div
                                            className="flex flex-wrap justify-between"
                                            onClick={showEditForm.bind(this, value)}
                                        >
                                            <div className="">Ubah</div>
                                            <div className="">
                                                <Pencil />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="pt-[8px]">
                                        <div
                                            className="flex flex-wrap justify-between"
                                        >
                                            <div>Hapus</div>
                                            <div>
                                                <Trash />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                )
            }
            return companyList
        }
    }

    useEffect(() => {
        let role = localStorage.getItem("role");
        if (role !== 'Superadmin') {
            window.location = '/'
        } else {
            ApiService.init()
            ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/company?per_page=' + 8, {})
                .then((response) => {
                    setCompany(response.data.data)

                    let paginateData = {
                        'firstPage': 1,
                        'lastPage': response.data.lastPage,
                        'totalPage': response.data.total,
                        'activePage': 1,
                        'from': response.data.from,
                        'to': response.data.to
                    }

                    setPaginate({ ...paginateData })
                })
        }
    }, [])

    function Pagination() {
        let page = []
        for (let i = 0; i < paginate.lastPage; i++) {
            page.push(
                <button
                    className={
                        paginate.activePage !== i + 1 ? 'btn paginate deactive' : 'btn paginate active'
                    }
                    onClick={goTo.bind(this, i + 1)}
                    key={'page-' + i}
                >
                    {i + 1}
                </button>,
            )
        }
        return page
    }

    function goTo(page) {

        let newPaginate = { ...paginate }
        newPaginate['activePage'] = page;
        setPaginate({ ...newPaginate })

        let perPage = 8;
        ApiService.init()
        ApiService.get(
            process.env.REACT_APP_BACKEND_DOMAIN + '/api/company?page=' + page + '&per_page=' + perPage,
        )
            .then((response) => {
                setCompany(response.data.data)
                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'activePage': page,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }

    function updateData(type, value) {
        let newCurrentCompany = { ...currentCompany };

        if (type == 'lisenceId') {
            if (value == 1 || value == 2) {
                newCurrentCompany['maxBranch'] = 2
                newCurrentCompany['maxUserContentDesigner'] = 1
                newCurrentCompany['maxUserContentPublisher'] = 1
            } else if (value == 3) {
                newCurrentCompany['maxBranch'] = 2
                newCurrentCompany['maxUserContentDesigner'] = 3
                newCurrentCompany['maxUserContentPublisher'] = 3
            }
        }

        newCurrentCompany[type] = value;
        setCurrentCompany(newCurrentCompany)
    }


    function saveData() {
        const daysDifference = Math.floor((new Date(currentCompany.expiredAt) - new Date(currentCompanyExpiredAt)) / (1000*60*60*24));

        ApiService.initFormData()
        ApiService.put(process.env.REACT_APP_BACKEND_DOMAIN + '/api/change-license/' + currentCompany.id, {
            'lisenceId': currentCompany.lisenceId,
            'maxBranch': currentCompany.lisenceId != 3 ? 1 : currentCompany.maxBranch,
            'maxMedia': currentCompany.maxMedia,
            'maxCampaign': currentCompany.maxCampaign,
            'masaTrial': daysDifference,
            'maxUserContentDesigner': currentCompany.maxUserContentDesigner,
            'maxUserContentPublisher': currentCompany.maxUserContentPublisher,
        }).then((response) => {
            toastSuccess("Berhasil mengedit lisensi.", "center")
        }).catch((_) => {
            toastError("Gagal mengedit lisensi.", "center")
        }).finally(() => {
            // closePopup()
            window.location = '/dashboard'
        })
        // ApiService.put(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/api/change-license/' + currentCompany.companyProductId, {
        //     'status': currentCompany.status,
        //     'maxBranch': currentCompany.status !== 'Premium' ? 1 : currentCompany.maxBranch,
        //     'maxMedia': currentCompany.maxMedia,
        //     'maxCampaign': currentCompany.maxCampaign
        // }).then((response) => {
        //     toastSuccess("Berhasil mengedit lisensi.", "center")
        // }).catch((_) => {
        //     toastError("Gagal mengedit lisensi.", "center")
        // }).finally(() => {
        //     closePopup()
        // })
    }

    function closePopup() {
        setToggleEdit(false)
    }

    if (localStorage.getItem("role") !== 'Superadmin') {
        window.location = '/'
    } else {
        return (
            <div className="w-screen min-h-[100vh] bg-[linear-gradient(0deg,#FFFFFF_60%,#3375F5_60%)] dashboard">
                <div className='middle-content flex flex-wrap !h-fit !p-0'>
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th className='bg-[rgba(51,117,245,0.05)] text-[#3375F5] font-[Inter] text-[12px] font-medium py-[16px] md:px-[16px] 2xl:px-[32px]'>NAMA USER</th>
                                <th className='bg-[rgba(51,117,245,0.05)] text-[#3375F5] font-[Inter] text-[12px] font-medium py-[16px] md:px-[16px] 2xl:px-[32px]'>EMAIL</th>
                                <th className='bg-[rgba(51,117,245,0.05)] text-[#3375F5] font-[Inter] text-[12px] font-medium py-[16px] md:px-[16px] 2xl:px-[32px]'>TIPE LISENSI</th>
                                <th className='bg-[#F5F8FE] text-[#3375F5] font-[Inter] text-[12px] font-medium py-[16px] px-[32px]'>KADALUARSA</th>
                                <th className='bg-[#F5F8FE] text-[#3375F5] font-[Inter] text-[12px] font-medium py-[16px] px-[32px]'></th>
                            </tr>
                        </thead>
                        <tbody>
                            <CompanyList />
                        </tbody>
                    </table>
                    <div className="w-full flex justiy-center mt-[50px]">
                        <div className="btn-group mx-auto gap-x-[12px]">
                            <Pagination />
                        </div>
                    </div>
                </div>
                <div className={toggleEdit === true ? 'fixed w-screen h-full top-0 right-0 flex justify-end toggle-menu overflow-y-scroll' : 'hidden'}>
                    <div className="h-max">
                        <div className='title flex flex-wrap items-center'>
                            <div className='basis-10/12'>
                                <h3>Edit Lisensi</h3>
                            </div>
                            <div className='basis-2/12 flex justify-end'>
                                <button className='btn btn-transparent mr-0' onClick={closePopup.bind(this)}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z" fill="black" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className='content'>
                            <div>
                                <label htmlFor="company_name">Company Name</label>
                                <input type="text" value={currentCompany?.companyName} disabled />
                            </div>
                            <div>
                                <label htmlFor="provinsi">Role</label>
                                <div className='relative'>
                                    <div className='svg-pin'></div>
                                    <select className="select w-full" onChange={e => updateData('lisenceId', e.target.value)} placeholder="Pilih Role">
                                        <option disabled>Pilih Lisensi</option>
                                        <option selected={currentCompany?.lisenceId == 1} value="1">Trial</option>
                                        <option selected={currentCompany?.lisenceId == 2} value="2">Lite Premium Single Branch</option>
                                        <option selected={currentCompany?.lisenceId == 3} value="3">Premium Multibranch</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="expired_at">Tanggal Kadaluarsa</label>
                                <input type="date" value={currentCompany?.expiredAt ?? currentCompanyExpiredAt} onChange={e => {
                                    updateData('expiredAt', e.target.value)}} />
                            </div>
                            <div>
                                <label htmlFor="max_branch">Max Branch</label>
                                <input type="number" value={currentCompany?.maxBranch} onChange={e => updateData('maxBranch', e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="max_content_designer">Max Content Designer</label>
                                <input type="number" value={currentCompany?.maxUserContentDesigner} onChange={e => updateData('maxUserContentDesigner', e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="max_content_publisher">Max Content Publisher</label>
                                <input type="number" value={currentCompany?.maxUserContentPublisher} onChange={e => updateData('maxUserContentPublisher', e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="max_media">Max Media</label>
                                <input type="number" value={currentCompany?.maxMedia} onChange={e => updateData('maxMedia', e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="max_campaign">Max Campaign</label>
                                <input type="number" value={currentCompany?.maxCampaign} onChange={e => updateData('maxCampaign', e.target.value)} />
                            </div>
                            <div>
                                <button className='btn' onClick={saveData.bind(this)} > SIMPAN </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
