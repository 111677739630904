import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import ApiService from '../../services/api.service'
import Navbar from '../../components/menu/Navbar';
import Add from '../../components/icon/Add';
import dateFormat from 'dateformat';
import Copy from '../../components/icon/Copy';
import Time from '../../components/icon/Time';
import Pencil from '../../components/icon/Pencil';
import Active from '../../components/icon/Active';
import Deactive from '../../components/icon/Deactive';
import Dot from '../../components/icon/Dot';
import Duplicate from '../../components/icon/Duplicate';
import TrashBlue from '../../components/icon/TrashBlue';
import Close from '../../components/icon/Close'
import Accept from '../../components/icon/Accept';
import Reject from '../../components/icon/Reject';
import LoadingTable from '../../components/LoadingTable'

export default function Index() {
    const [display, setDisplay] = useState('list')
    const [campaignList, setCampaignList] = useState([])
    const [paginate, setPaginate] = useState({
        'firstPage': 1,
        'lastPage': 1,
        'nextPageUrl': '',
        'prevPageUrl': '',
        'totalPage': 1,
        'activePage': 1,
        'from': 0,
        'to': 0
    })
    const [search, setSearch] = useState('')
    const [perPage, setPerPage] = useState(10);
    const [isToggleDelete, setIstToggleDelete] = useState([])
    const [isToggleDuplicate, setIstToggleDuplicate] = useState([])
    const [deleteId, setDeleteId] = useState()
    const [duplicateId, setDuplicateId] = useState()
    let campaignData = []
    const [isAction, setIsAction] = useState(true)
    const [isCampaignExceed, setIsCampaignExceed] = useState(true)

    function countDuration(startTime, endTime) {
        var timeStart = new Date("01/01/2007 " + startTime).getMinutes();
        var timeEnd = new Date("01/01/2007 " + endTime).getMinutes();
        console.log(new Date("01/01/2007 " + endTime))
        console.log(new Date("01/01/2007 " + endTime).getMinutes())
        return timeEnd - timeStart;
    }

    useEffect(() => {
        ApiService.init()
        ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign?per_page=' + perPage)
            .then((response) => {
                setCampaignList([...response.data.data])

                const campaign = localStorage.getItem("campaign");
                if (response.data.data.length < campaign) {
                    setIsCampaignExceed(false)
                } else {
                    setIsCampaignExceed(true)
                }

                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'nextPageUrl': response.data.nextPageUrl,
                    'prevPageUrl': response.data.prevPageUrl,
                    'activePage': 1,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
            .finally(() => {
                setIsAction(false)
            })

    }, [display])

    function prevPage() {
        ApiService.init()
        ApiService.get(paginate.prevPageUrl +'&per_page='+perPage)
            .then((response) => {
                setCampaignList([...response.data.data])

                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'nextPageUrl': response.data.nextPageUrl,
                    'prevPageUrl': response.data.prevPageUrl,
                    'activePage': 1,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }

    function nextPage() {
        ApiService.init()
        ApiService.get(paginate.nextPageUrl +'&per_page='+perPage)
            .then((response) => {
                setCampaignList([...response.data.data])

                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'nextPageUrl': response.data.nextPageUrl,
                    'prevPageUrl': response.data.prevPageUrl,
                    'activePage': 1,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }

    function updatePerPage(value) {
        setPerPage(value)
        ApiService.init()
        ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign?per_page=' + value)
            .then((response) => {
                setCampaignList([...response.data.data])

                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'nextPageUrl': response.data.nextPageUrl,
                    'prevPageUrl': response.data.prevPageUrl,
                    'activePage': 1,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })

    }

    function toggleDelete(value) {
        if (isToggleDelete === true) {
            setIstToggleDelete(false)
        } else {
            setDeleteId(value)
            setIstToggleDelete(true)
        }
    }

    function toggleDuplicate(value) {
        if (isToggleDuplicate === true) {
            setIstToggleDuplicate(false)
        } else {
            setDuplicateId(value)
            setIstToggleDuplicate(true)
        }
    }

    function deleteCampaign() {
        ApiService.init()
        ApiService.delete(
            process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign/' + deleteId,
        )
            .then((response) => {
                window.location = '/campaign'
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }


    function duplicateCampaign() {
        ApiService.init()
        ApiService.get(
            process.env.REACT_APP_BACKEND_DOMAIN + '/api/copy-campaign/' + duplicateId, {}
        )
            .then((response) => {
                window.location = '/campaign'
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }


    for (const [index, value] of campaignList.entries()) {
        console.log(value)
        campaignData.push(
            <tr className="" key={index + '-' + value.id}>
                {/* <td>
                    <input type="checkbox" className="checkbox" />
                </td> */}
                <td>
                    {value.campaignName}
                </td>
                <td>
                    <div className='flex gap-x-[4px]'>
                        {dateFormat(value.startDate, "dd-mmm-yy")}
                    </div>
                </td>
                <td>
                    <div className='flex gap-x-[4px]'>
                        {dateFormat(value.endDate, "dd-mmm-yy")}
                    </div>
                </td>
                <td>
                    <div className={value.campaignStatus === 'active' ? 'status active' : 'status inactive'}>
                        {value.campaignStatus}
                    </div>
                    <p className='last-update'>
                        Last update: {dateFormat(value.updatedAt, "yyyy-mm-dd hh:MM:ss")}
                    </p>
                </td>
                <td>
                    <p className='name'>
                        {value.name}
                    </p>
                    <p className='email'>
                        {value.email}
                    </p>
                </td>
                <td>
                    <div className={value.campaignStatus === 'active' ? 'flex items-center copy-link gap-x-[4px]' : 'flex items-center copy-link gap-x-[4px] !text-[#3375f594]'}>
                        <div>
                            <Copy />
                        </div>
                        <div>
                            <button disabled={value.campaignStatus !== 'active'} className={value.campaignStatus !== 'active' ? 'cursor-not-allowed' : ''} onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_DOMAIN}/view/${value.templateId}/${value.id}/${value.url}`)}>COPY LINK</button>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="cursor-pointer bg-[gray]">
                            <div className="w-10 rounded-full flex justify-end">
                                <Dot />
                            </div>
                        </label>
                        {generateMenu(value)}
                    </div>
                </td>
            </tr>
        )
    }

    function generateMenu(value) {
        let menu = []
        let role = localStorage.getItem("role");

        if (role === 'Admin') {
            if (value.campaignStatus === 'active') {
                menu.push(
                    <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]'>
                            <div onClick={changeStatusCampaign.bind(this, value.id, 'inactive')} className='flex flex-wrap justify-between'>
                                <div className=''>Aktif</div>
                                <div className=''>
                                    <Active />
                                </div>
                            </div>
                        </li>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]'>
                            <Link to={"/campaign/template/" + value.templateId + "/" + value.id} className='flex flex-wrap justify-between'>
                                <div className=''>Ubah</div>
                                <div className=''>
                                    <Pencil />
                                </div>
                            </Link>
                        </li>
                        <li className='py-[8px] border-b-[1px] border-[rgba(173,173,173,0.3)] '>
                            <div className='flex flex-wrap justify-between' onClick={toggleDuplicate.bind(this, value.id)}>
                                <div>
                                    Duplikasi
                                </div>
                                <div>
                                    <Duplicate />
                                </div>
                            </div>
                        </li>
                        <li className='pt-[8px]'>
                            <div className='flex flex-wrap justify-between' onClick={toggleDelete.bind(this, value.id)}>
                                <div>
                                    Hapus
                                </div>
                                <div>
                                    <TrashBlue />
                                </div>
                            </div>
                        </li>
                    </ul>
                )
            } else if (value.campaignStatus === 'inactive') {
                menu.push(
                    <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]'>
                            <div onClick={changeStatusCampaign.bind(this, value.id, 'active')} className='flex flex-wrap justify-between'>
                                <div className=''>Tidak Aktif </div>
                                <div className=''>
                                    <Deactive />
                                </div>
                            </div>
                        </li>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]'>
                            <Link to={"/campaign/template/" + value.templateId + "/" + value.id} className='flex flex-wrap justify-between'>
                                <div className=''>Ubah</div>
                                <div className=''>
                                    <Pencil />
                                </div>
                            </Link>
                        </li>
                        <li className='py-[8px] border-b-[1px] border-[rgba(173,173,173,0.3)] '>
                            <div className='flex flex-wrap justify-between' onClick={toggleDuplicate.bind(this, value.id)}>
                                <div>
                                    Duplikasi
                                </div>
                                <div>
                                    <Duplicate />
                                </div>
                            </div>
                        </li>
                        <li className='pt-[8px]'>
                            <div className='flex flex-wrap justify-between' onClick={toggleDelete.bind(this, value.id)}>
                                <div>
                                    Hapus
                                </div>
                                <div>
                                    <TrashBlue />
                                </div>
                            </div>
                        </li>
                    </ul>
                )
            } else {
                menu.push(
                    <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]'>
                            <div onClick={approveCampaign.bind(this, value.id)} className='flex flex-wrap justify-between'>
                                <div className=''>Approve</div>
                                <div className=''>
                                    <Accept />
                                </div>
                            </div>
                        </li>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]'>
                            <div onClick={rejectCampaign.bind(this, value.id)} className='flex flex-wrap justify-between'>
                                <div className=''>Reject</div>
                                <div className=''>
                                    <Reject />
                                </div>
                            </div>
                        </li>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]'>
                            <Link to={"/campaign/template/" + value.templateId + "/" + value.id} className='flex flex-wrap justify-between'>
                                <div className=''>Ubah</div>
                                <div className=''>
                                    <Pencil />
                                </div>
                            </Link>
                        </li>
                        <li className='py-[8px] border-b-[1px] border-[rgba(173,173,173,0.3)] '>
                            <div className='flex flex-wrap justify-between' onClick={toggleDuplicate.bind(this, value.id)}>
                                <div>
                                    Duplikasi
                                </div>
                                <div>
                                    <Duplicate />
                                </div>
                            </div>
                        </li>
                        <li className='pt-[8px]'>
                            <div className='flex flex-wrap justify-between' onClick={toggleDelete.bind(this, value.id)}>
                                <div>
                                    Hapus
                                </div>
                                <div>
                                    <TrashBlue />
                                </div>
                            </div>
                        </li>
                    </ul>
                )
            }
        } else if (role === 'Content Designer') {
            menu.push(
                <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                    <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]'>
                        <Link to={"/campaign/template/" + value.templateId + "/" + value.id} className='flex flex-wrap justify-between'>
                            <div className=''>Ubah</div>
                            <div className=''>
                                <Pencil />
                            </div>
                        </Link>
                    </li>
                    <li className='py-[8px] border-b-[1px] border-[rgba(173,173,173,0.3)] '>
                        <div className='flex flex-wrap justify-between' onClick={toggleDuplicate.bind(this, value.id)}>
                            <div>
                                Duplikasi
                            </div>
                            <div>
                                <Duplicate />
                            </div>
                        </div>
                    </li>
                    <li className='pt-[8px]'>
                        <div className='flex flex-wrap justify-between' onClick={toggleDelete.bind(this, value.id)}>
                            <div>
                                Hapus
                            </div>
                            <div>
                                <TrashBlue />
                            </div>
                        </div>
                    </li>
                </ul>
            )
        } else if (role === 'Content Publisher') {
            if (value.campaignStatus === 'active') {
                menu.push(
                    <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]'>
                            <div onClick={changeStatusCampaign.bind(this, value.id, 'inactive')} className='flex flex-wrap justify-between'>
                                <div className=''>Aktif</div>
                                <div className=''>
                                    <Active />
                                </div>
                            </div>
                        </li>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]'>
                            <Link to={"/campaign/template/" + value.templateId + "/" + value.id} className='flex flex-wrap justify-between'>
                                <div className=''>Ubah</div>
                                <div className=''>
                                    <Pencil />
                                </div>
                            </Link>
                        </li>
                        <li className='py-[8px] border-b-[1px] border-[rgba(173,173,173,0.3)] '>
                            <div className='flex flex-wrap justify-between' onClick={toggleDuplicate.bind(this, value.id)}>
                                <div>
                                    Duplikasi
                                </div>
                                <div>
                                    <Duplicate />
                                </div>
                            </div>
                        </li>
                        <li className='pt-[8px]'>
                            <div className='flex flex-wrap justify-between' onClick={toggleDelete.bind(this, value.id)}>
                                <div>
                                    Hapus
                                </div>
                                <div>
                                    <TrashBlue />
                                </div>
                            </div>
                        </li>
                    </ul>
                )
            } else if (value.campaignStatus === 'inactive') {
                menu.push(
                    <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]'>
                            <div onClick={changeStatusCampaign.bind(this, value.id, 'active')} className='flex flex-wrap justify-between'>
                                <div className=''>Tidak Aktif </div>
                                <div className=''>
                                    <Deactive />
                                </div>
                            </div>
                        </li>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]'>
                            <Link to={"/campaign/template/" + value.templateId + "/" + value.id} className='flex flex-wrap justify-between'>
                                <div className=''>Ubah</div>
                                <div className=''>
                                    <Pencil />
                                </div>
                            </Link>
                        </li>
                        <li className='py-[8px] border-b-[1px] border-[rgba(173,173,173,0.3)] '>
                            <div className='flex flex-wrap justify-between' onClick={toggleDuplicate.bind(this, value.id)}>
                                <div>
                                    Duplikasi
                                </div>
                                <div>
                                    <Duplicate />
                                </div>
                            </div>
                        </li>
                        <li className='pt-[8px]'>
                            <div className='flex flex-wrap justify-between' onClick={toggleDelete.bind(this, value.id)}>
                                <div>
                                    Hapus
                                </div>
                                <div>
                                    <TrashBlue />
                                </div>
                            </div>
                        </li>
                    </ul>
                )
            } else {
                menu.push(
                    <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]'>
                            <div onClick={approveCampaign.bind(this, value.id)} className='flex flex-wrap justify-between'>
                                <div className=''>Approve</div>
                                <div className=''>
                                    <Accept />
                                </div>
                            </div>
                        </li>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]'>
                            <div onClick={rejectCampaign.bind(this, value.id)} className='flex flex-wrap justify-between'>
                                <div className=''>Reject</div>
                                <div className=''>
                                    <Reject />
                                </div>
                            </div>
                        </li>
                        <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]'>
                            <Link to={"/campaign/template/" + value.templateId + "/" + value.id} className='flex flex-wrap justify-between'>
                                <div className=''>Ubah</div>
                                <div className=''>
                                    <Pencil />
                                </div>
                            </Link>
                        </li>
                        <li className='py-[8px] border-b-[1px] border-[rgba(173,173,173,0.3)] '>
                            <div className='flex flex-wrap justify-between' onClick={toggleDuplicate.bind(this, value.id)}>
                                <div>
                                    Duplikasi
                                </div>
                                <div>
                                    <Duplicate />
                                </div>
                            </div>
                        </li>
                        <li className='pt-[8px]'>
                            <div className='flex flex-wrap justify-between' onClick={toggleDelete.bind(this, value.id)}>
                                <div>
                                    Hapus
                                </div>
                                <div>
                                    <TrashBlue />
                                </div>
                            </div>
                        </li>
                    </ul>
                )
            }
        } else {
            if (value.campaignStatus === 'active') {
                menu.push(
                    <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                        <li className='pb-[8px]'>
                            <div onClick={changeStatusCampaign.bind(this, value.id, 'inactive')} className='flex flex-wrap justify-between'>
                                <div className=''>Aktif</div>
                                <div className=''>
                                    <Active />
                                </div>
                            </div>
                        </li>
                    </ul>
                )
            } else {
                menu.push(
                    <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-fit" key={"menu" + value.id}>
                        <li className='pb-[8px]'>
                            <div onClick={changeStatusCampaign.bind(this, value.id, 'active')} className='flex flex-wrap justify-between'>
                                <div className=''>Tidak Aktif </div>
                                <div className=''>
                                    <Deactive />
                                </div>
                            </div>
                        </li>
                    </ul>
                )
            }
        }
        return menu;
    }

    function changeStatusCampaign(id, value) {
        ApiService.initFormData()
        ApiService.put(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign/update-status/' + id, {
            'status': value
        }).then((response) => {
            window.location = '/campaign'
        })
    }

    function approveCampaign(id) {
        ApiService.initFormData()
        ApiService.put(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign/update-status/' + id, {
            'status': 'active'
        }).then((response) => {
            window.location = '/campaign'
        })
    }

    function rejectCampaign(id) {
        ApiService.initFormData()
        ApiService.put(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign/update-status/' + id, {
            'status': 'inactive'
        }).then((response) => {
            window.location = '/campaign'
        })
    }

    function searchData(value) {
        setSearch(value)
        ApiService.init()
        ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign?per_page=' + perPage + '&search=' + value)
            .then((response) => {
                setCampaignList([...response.data.data])
                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'nextPageUrl': response.data.nextPageUrl,
                    'prevPageUrl': response.data.prevPageUrl,
                    'activePage': 1,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }

    function addCampaign() {
        if (localStorage.getItem('role') !== 'Content Publisher') {
            return (
                <Link to="/campaign/template" className={`${isCampaignExceed ? "btn-disabled" : ""} btn gap-x-[12px]`} disabled={isCampaignExceed}>
                    <Add />
                    Tambah Campaign
                </Link>
            )
        }
    }

    return (
        <div className="w-full min-h-screen h-fit campaign">
            <div className='top-content'>
                <Navbar />
                <div className='content mb-[50px]'>
                    <div className='header flex flex-wrap'>
                        <div className='basis-4/12 title'>
                            <h3>Campaign</h3>
                        </div>
                        <div className='basis-5/12 flex items-center'>
                            <div className='w-full h-full'>
                                <input type="text" placeholder='CARI' className='search h-full bg-transparent px-[10px] w-full' value={search} onChange={(e) => searchData(e.target.value)} />
                            </div>
                        </div>
                        <div className='basis-3/12 action flex flex-wrap items-center justify-end gap-x-[24px]'>
                            {addCampaign()}
                        </div>
                    </div>
                    <div className='cabang-table'>
                        <table className='table w-full'>
                            <thead>
                                <tr>
                                    <th>NAMA</th>
                                    <th>DURASI AWAL</th>
                                    <th>DURASI AKHIR</th>
                                    <th>STATUS</th>
                                    <th className='status-col' colSpan={3}>USER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(isAction) 
                                    ? (
                                        <LoadingTable colSpan="6"/>
                                    ) 
                                    : campaignData
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan="3">
                                        {/* <Trash /> */}
                                    </th>
                                    <th className="flex flex-wrap items-center">
                                        <div className='basis-6/12 pl-[5%]'>
                                            Baris per halaman:
                                            <select className="select w-fit m-0" value={perPage} onChange={(e) => updatePerPage(e.target.value)}>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                                <option>40</option>
                                                <option>50</option>
                                            </select>
                                        </div>
                                    </th>
                                    <th colSpan="3">
                                        <div className='basis-6/12 flex justify-end items-center'>
                                            <div>
                                                {paginate.from}-{paginate.to} dari {paginate.totalPage}
                                            </div>
                                            <div>
                                                <div className="w-[24px]">
                                                    <button className={paginate.prevPageUrl === null ? 'hidden' : 'btn btn-transparent'} onClick={prevPage.bind(this)} >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.9802 5.32024L10.7702 8.53024L8.80023 10.4902C7.97023 11.3202 7.97023 12.6702 8.80023 13.5002L13.9802 18.6802C14.6602 19.3602 15.8202 18.8702 15.8202 17.9202V12.3102V6.08024C15.8202 5.12024 14.6602 4.64024 13.9802 5.32024Z" fill="#3375F5" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <button className={paginate.nextPageUrl === null ? 'hidden' : 'btn btn-transparent'} onClick={nextPage.bind(this)}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.2002 10.4899L13.2302 8.51993L10.0202 5.30993C9.34018 4.63993 8.18018 5.11993 8.18018 6.07993V12.3099V17.9199C8.18018 18.8799 9.34018 19.3599 10.0202 18.6799L15.2002 13.4999C16.0302 12.6799 16.0302 11.3199 15.2002 10.4899Z" fill="#3375F5" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div
                    className={
                        isToggleDelete === true
                            ? 'delete-popup fixed bg-[rgba(41,45,50,0.5)] top-[0] h-[100vh] w-[100vw] -ml-[16rem] flex justify-center'
                            : '!hidden'
                    }
                    id="deleteForm"
                >
                    <div className="">
                        <div className="border-b-[0.5px] border-[#D9D9D9]">
                            <div className="flex justify-between px-[32px] items-center !h-[80px]">
                                <h3>Hapus Campaign</h3>
                                <button
                                    className="btn btn-transparent !p-0"
                                    onClick={toggleDelete.bind(this)}
                                >
                                    <Close />
                                </button>
                            </div>
                        </div>
                        <div className="px-[32px]">
                            <h4>Apakah Anda yakin akan menghapus campaign?</h4>
                            <div className="grid grid-cols-2 gap-x-[26px]">
                                <button className="btn-outline" onClick={toggleDelete.bind(this)}>
                                    BATAL
                                </button>
                                <button className="btn" onClick={deleteCampaign.bind(this)}>
                                    HAPUS
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        isToggleDuplicate === true
                            ? 'delete-popup fixed bg-[rgba(41,45,50,0.5)] top-[0] h-[100vh] w-[100vw] -ml-[16rem] flex justify-center'
                            : '!hidden'
                    }
                    id="duplicateForm"
                >
                    <div className="">
                        <div className="border-b-[0.5px] border-[#D9D9D9]">
                            <div className="flex justify-between px-[32px] items-center !h-[80px]">
                                <h3>Duplicate Campaign</h3>
                                <button
                                    className="btn btn-transparent !p-0"
                                    onClick={toggleDuplicate.bind(this)}
                                >
                                    <Close />
                                </button>
                            </div>
                        </div>
                        <div className="px-[32px]">
                            <h4>Apakah Anda yakin akan duplicate campaign?</h4>
                            <div className="grid grid-cols-2 gap-x-[26px]">
                                <button className="btn-outline" onClick={toggleDuplicate.bind(this)}>
                                    BATAL
                                </button>
                                <button className="btn" onClick={duplicateCampaign.bind(this)}>
                                    DUPLICATE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
