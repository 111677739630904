import React, { useEffect } from "react";
import axios from 'axios'
import ApiService from '../services/api.service'
import ShimmerLoading from '../components/shimmerLoading';

export default function ApiPopup(props) {
    const checkAuthorization = async () => {
        await ApiService.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/checkAccurateAPI`)
            .then((response) => {
                console.log(response)
            })
    }

    function setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          let expires = "expires="+d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }


    return (
        <div className={props.isApiOn === true ? ' text-popup' : 'hidden'}>
            <div className='title flex flex-wrap items-center'>
                <div className='basis-10/12'>
                    <h3>Tambah API</h3>
                </div>
                <div className='basis-2/12 flex justify-end'>
                    <button className='btn btn-transparent mr-0' onClick={props.togglePopup.bind(this, 'api')}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z" fill="black" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className='content'>
                {props?.isFetchingDB && props?.DBList?.length == 0 ? (
                    <div className='mb-[8vh] w-full'>
                        <div className="h-[48px]">
                            <ShimmerLoading/>
                        </div>
                    </div>
                ) : props?.DBList?.length == 0 ? (
                    <>
                        <div className='mb-2'>
                            <label htmlFor="selectedDb">Client ID</label>
                            <input type="text" name="clientId" placeholder="Masukkan Client ID" value={props.api.clientId} onChange={(e) => props.updateData('api', 'clientId', e.target.value)} />
                        </div>
                        <div className='mb-2'>
                            <label htmlFor="selectedDb">Client Secret</label>
                            <input type="text" name="clientSecret" placeholder="Masukkan Client Secret" value={props.api.clientSecret} onChange={(e) => props.updateData('api', 'clientSecret', e.target.value)} />
                        </div>
                        <div className='mb-[8vh]'>
                            <a className='btn w-full' onClick={(e) => props.integrateApiMutation.mutate()}>integrasi api</a>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='h-[11vh] mb-4'>
                            <label htmlFor="selectedDb">Database Accurate</label>
                            <select name="selectedDb" className="w-full" defaultValue={props?.api?.selectedDb} onChange={(e) => {
                                if (e.target.value) {
                                    props.updateData('api', 'selectedDb', e.target.value)
                                }
                            }}>
                                <option key={-1} value={null}>Pilih database</option>
                                {props?.DBList?.map((obj, index) => (
                                    <option key={index} value={obj.id}>{obj.alias}</option>
                                ))}
                            </select>
                        </div>
                        <div className='h-[11vh] mb-4'>
                            <label htmlFor="selectedWarehouse">Gudang</label>
                            <select name="selectedWarehouse" className="w-full" defaultValue={props?.api?.selectedWarehouse} onChange={(e) => props.updateData('api', 'selectedWarehouse', e.target.value)}>
                                <option key={-1} value={null}>Pilih gudang</option>
                                {props?.warehouseList?.map((obj, index) => (
                                    <option key={index} value={obj.name}>{obj.name}</option>
                                ))}
                            </select>
                        </div>
                    </>
                )}
                <div className='h-[11vh] mb-4'>
                    <label htmlFor="headerColor">Warna Background Header</label>
                    <input type="color" name="headerColor" value={props.api.headerColor} onChange={(e) => props.updateData('api', 'headerColor', e.target.value)} />
                </div>
                <div className='h-[11vh] mb-4'>
                    <label htmlFor="headerFontColor">Warna Font</label>
                    <input type="color" name="headerFontColor" value={props.api.headerFontColor} onChange={(e) => props.updateData('api', 'headerFontColor', e.target.value)} />
                </div>
                <div className='flex justify-end mt-[8vh]'>
                    <button className='btn' disabled={props.api.selectedDb == null} onClick={(e) => {
                        props.apiDataMutation.mutate()
                        props.togglePopup('api')
                    }}>simpan url</button>
                </div>
            </div>
        </div>
    );
}