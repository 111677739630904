import { useOutlet, Navigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import ApiService from './services/api.service'
import JwtService from './services/jwt.service'
import moment from "moment";

export default function ProtectedRoute() {
  const outlet = useOutlet();
  const [isLogin, setIsLogin] = useState(false)
  const token = JwtService.getToken();

  const fetchData = async () => {
    if (token == undefined || token == "" || token == null) {
      window.location = '/login'
    }

    if (localStorage.getItem('role') !== "Superadmin" && localStorage.getItem('lisenceId') == 1) {
      let endDate = moment(localStorage.getItem('tanggal'));
      let startDate = moment();

      let diff = moment.duration(endDate.diff(startDate)).asDays();
      let rentangHari = Math.abs(Math.round(diff));
      if (rentangHari > 30 + parseInt(localStorage.getItem('tambahanMasaTrial'))) {
        ApiService.purgeAuth()
        window.location = '/login'
      }
    }

    if (localStorage.getItem('role') == 'Admin') {
      ApiService.init()
      ApiService.get(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/api/get-my-data', {})
        .catch((error) => {
          console.log(error)
          window.location = '/login'
        })
    }

    setIsLogin(true)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (isLogin) {
    return (
      <div>
        {outlet}
      </div>
    )
  }
};
