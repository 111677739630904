import '../../view-template1-grid.css';
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Close from '../../components/icon/Close'
import Logo from '../../components/panel/Logo'
import moment from 'moment';

export default function Template10(props) {
  const [currentTime, setCurrentTime] = useState(new Date());
  const dateNow = new Date();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const day = currentTime.toLocaleString('en-US', { weekday: 'long' });
  const date = currentTime.toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
  const time = currentTime.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });

  function RenderTimestamp() {
    return (
      <div className="flex items-center font-bold">
        <div className="flex flex-col items-end pe-2 me-2 border-black border-r-2">
          <span>{day}</span>
          <span>{date}</span>
        </div>
        <h4 className="text-xl">{time}</h4>
      </div>
    )
  }

  function RenderTable() {
    if (props.api.length !== 0) {
      return (
        <table className='h-[100%] w-[100%] text-xl'>
          <thead style={{
            color: props.api.headerFontColor,
            backgroundColor: props.api.headerColor ?? "#fff",
          }}>
            <tr>
              <th className="py-2 uppercase">No.</th>
              <th className="py-2 uppercase">Item</th>
              <th className="py-2 uppercase">Stock</th>
              <th className="py-2 uppercase">Status</th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {Array.from({ length: 15 }).map((_, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">Nama Item</td>
                <td className="text-center">{index + 10}</td>
                <td className={`text-center uppercase text-green-500`}>available</td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    } else {
      <div className='max-w-full w-[100%] h-[100%] object-cover'></div>
    }
  }

  function RenderLastUpdated() {
    return (
      <div className="running-text px-[8px] overflow-hidden py-[11px] cursor-pointer">
        {"Last Updated : " + moment(dateNow).format("D MMMM YYYY HH:mm:ss")}
      </div>
    )
  }

  return (
    <div className={props.preview === true ? "template template-preview gap-[20px] gap-y-[20px] fixed top-0 left-0 w-full h-full overflow-hidden" : "hidden"}>
      <div className='absolute top-[0] right-[0]'>
        <button onClick={props.changePreview.bind(this)}>
          <Close />
        </button>
      </div>
      <div className='mb-[10px] max-h-[9vh] grid grid-cols-12 gap-x-[20px]'>
        <div className="col-span-12 logo bg-white flex justify-between items-center h-[8vh] max-h-[8vh] h-[100%] px-[8px] py-[11px]">
          {props.singleImages.length !== 0 ?
            (
              <img alt="logo" className="h-[100%] object-cover" src={props.singleImages[0].src} />
            ) : (
              <Logo/>
            )
          }
          <RenderTimestamp />
        </div>
      </div>
      <div className='mb-[10px] max-h-[77vh] grid grid-cols-12 gap-x-[20px]'>
        <div className="col-span-12 max-h-[77vh] h-[77vh] object-cover">
          <RenderTable />
        </div>
      </div>
      <div className='w-full bg-[#FFFFFF] h-[10vh]'>
        <RenderLastUpdated />
      </div>
    </div>
  )
}