import '../../view-template1-grid.css';
import React from 'react'
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Close from '../../components/icon/Close'

export default function Template8(props) {

  function RenderHtml() {
      let htmlValue = props.html
      return (
        <div className='bg-white h-full'>
          <div dangerouslySetInnerHTML={{ __html: htmlValue.html }} className="w-full h-full"></div>
        </div>
      )
  }

  return (
    <div className={props.preview === true ? "template template-preview gap-[20px] gap-y-[20px] fixed top-0 left-0 w-full h-full overflow-hidden" : "hidden"}>
      <div className='absolute top-[0] right-[0]'>
        <button onClick={props.changePreview.bind(this)}>
          <Close />
        </button>
      </div>
      <div className='mb-[20px] h-[94vh] max-h-[94vh] col-span-10 bg-[#FFFFFF]'>
        <RenderHtml />
      </div>
    </div>
  )
}