  import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import Navbar from '../../components/menu/Navbar'
import Dot from '../../components/icon/Dot'
import Pencil from '../../components/icon/Pencil'
import Trash from '../../components/icon/Trash'
import TrashGray from '../../components/icon/TrashGray'
import Close from '../../components/icon/Close'
import CreateUser from '../../components/user/CreateUser'
import EditUser from '../../components/user/EditUser'
import { toastSuccess, toastError } from '../../components/toast';
import { useNavigate } from "react-router-dom";

export default function UpdateProfile() {
  const navigate = useNavigate();
  const [user, setUser] = useState({})

  useEffect(() => {

    fetchData()

  }, [])

  function fetchData() {
    if (localStorage.getItem('role') === 'Admin') {
      ApiService.init()
      ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/user-show`)
        .then((response) => {
          setUser(response.data)
        })
        .catch((error) => {
          // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
        })
    }
  }

  function updateData(field, value) {
    let newUser = { ...user };
    newUser[field] = value;
    setUser(newUser)
  }

  const updateProfile = () => {
    if (
      user.name === '',
      user.email === ''
    ) {
      toastError("Silahkan isi semua field yang ada.", "left", "validation")
    } else {
      ApiService.init()
      ApiService
        .put('/api/user/' + user.id, {
            name: user.name,
            email: user.email,
        })
        .then((response) => {
            toastSuccess("Profil berhasil diupdate!", "center", "success")
            navigate('/dashboard')
        })
        .catch((error) => {
          toastError("Profil gagal diupdate!", "center", "failed")
        })
    }
  }

  if (localStorage.getItem('role') === 'Admin') {
    return (
      <div className="w-full min-h-screen h-fit bg-white cabang">
        <div className="top-content">
          <Navbar />
          <div className="content">
            <div className="header flex flex-wrap">
              <div className="basis-4/12 title">
                <h3>Profil User</h3>
              </div>
            </div>
            <div className="grid grid-cols-4 items-center mb-2">
              <span>Nama Lengkap</span>
              <div className="col-span-2">
                <input
                  type="text"
                  className={`input input-bordered !outline-0 input-bordered !border-custom-purple-light text-gray-900 text-sm !focus:ring-0 !focus:ring-offset-0 focus:border-2 block w-full`}
                  placeholder="Nama Lengkap"
                  value={user.name} onChange={e => updateData('name', e.target.value)}
                  name="name"
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center mb-2">
              <span>Email</span>
              <div className="col-span-2">
                <input
                  type="text"
                  className={`input input-bordered !outline-0 input-bordered !border-custom-purple-light text-gray-900 text-sm !focus:ring-0 !focus:ring-offset-0 focus:border-2 block w-full disabled:bg-slate-50 disabled:text-slate-500`}
                  placeholder="Email"
                  value={user.email} onChange={e => updateData('email', e.target.value)}
                  name="email"
                  disabled
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center mb-2">
              <span>Status</span>
              <div className="col-span-2">
                <input
                  type="text"
                  className={`input input-bordered !outline-0 input-bordered !border-custom-purple-light text-gray-900 text-sm !focus:ring-0 !focus:ring-offset-0 focus:border-2 block w-full disabled:bg-slate-50 disabled:text-slate-500`}
                  placeholder="Status"
                  value={user.status} onChange={e => updateData('status', e.target.value)}
                  name="status"
                  disabled
                />
              </div>
            </div>

            <div className="grid grid-cols-4 my-4">
              <div className="relative col-span-2">
                <button type='submit' onClick={updateProfile} className='btn'> SIMPAN </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
