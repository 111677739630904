import React from 'react'
export default function HtmlPopup(props) {
  return (
    <div className={props.isHtmlOn === true ? ' text-popup' : 'hidden'}>
      <div className="title flex flex-wrap items-center">
        <div className="basis-10/12">
          <h3>Tambah HTML</h3>
        </div>
        <div className="basis-2/12 flex justify-end">
          <button
            className="btn btn-transparent mr-0"
            onClick={props.togglePopup.bind(this, 'html')}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="content">
        <div className=" h-[11vh]">
          <label htmlFor="ukuranJudul">Ukuran Teks</label>
          <select className="select w-full" value={props.html.tipe} onChange={(e) => props.updateData('html', 'tipe', e.target.value)}>
            <option disabled>
              Pilih Sumber
            </option>
            <option>Instagram</option>
            <option>Youtube</option>
          </select>
        </div>
        <div className="">
          <label htmlFor="ukuranJudul">Teks</label>
          <textarea name="teks" id="" cols="30" rows="10" value={props.html.html} onChange={(e) => props.updateData('html', 'html', e.target.value)}></textarea>
        </div>
        <div className="flex justify-end mt-[8vh]">
          <button className="btn upercase" disabled={props.html.html === ""} onClick={props.togglePopup.bind(this, 'html')}>simpan teks</button>
        </div>
      </div>
    </div>
  )
}
