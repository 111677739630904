import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import Navbar from '../../components/menu/Navbar'
import Dot from '../../components/icon/Dot'
import Pencil from '../../components/icon/Pencil'
import Trash from '../../components/icon/Trash'
import TrashGray from '../../components/icon/TrashGray'
import Close from '../../components/icon/Close'
import Key from '../../components/icon/Key'
import CreateUser from '../../components/user/CreateUser'
import EditUser from '../../components/user/EditUser'
import LoadingTable from '../../components/LoadingTable'
import { toastSuccess, toastError } from '../../components/toast';

export default function Index() {
  const [perPage, setPerPage] = useState(10);
  const [toggleAddUser, setToggleAddUser] = useState(false)
  const [isToggleDelete, setIstToggleDelete] = useState([])
  const [isToggleEdit, setIsToggleEdit] = useState(false)
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({
    name: '',
    email: '',
    status: 'Content Designer'
  })
  const [deleteId, setDeleteId] = useState()
  const [paginate, setPaginate] = useState({
    'firstPage': 1,
    'lastPage': 1,
    'totalPage': 1,
    'nextPageUrl': '',
    'prevPageUrl': '',
    'activePage': 1,
    'from': 0,
    'to': 0
  })
  const [search, setSearch] = useState('')
  let tableUserList = []
  const [isAction, setIsAction] = useState(true)

  useEffect(() => {

    if (localStorage.getItem('role') === 'Admin') {
      ApiService.init()
      ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/user?per_page=` + perPage)
        .then((response) => {
          setUsers([...response.data.data])

          let paginateData = {
            'firstPage': 1,
            'lastPage': response.data.lastPage,
            'totalPage': response.data.total,
            'nextPageUrl': response.data.nextPageUrl,
            'prevPageUrl': response.data.prevPageUrl,
            'activePage': 1,
            'from': response.data.from,
            'to': response.data.to
          }

          setPaginate({ ...paginateData })
        })
        .catch((error) => {
          // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
        })
        .finally(() => {
                setIsAction(false)
            })
    }

  }, [])

  function updatePerPage(value) {
    setPerPage(value)
    ApiService.init()
    ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/user?per_page=' + value)
      .then((response) => {
        setUsers([...response.data.data])

        let paginateData = {
          'firstPage': 1,
          'lastPage': response.data.lastPage,
          'totalPage': response.data.total,
          'nextPageUrl': response.data.nextPageUrl,
          'prevPageUrl': response.data.prevPageUrl,
          'activePage': 1,
          'from': response.data.from,
          'to': response.data.to
        }

        setPaginate({ ...paginateData })
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })

  }

  function toggleUser() {
    setToggleAddUser(!toggleAddUser)
  }

  function toggleDelete(value) {
    if (isToggleDelete === true) {
      setIstToggleDelete(false)
    } else {
      setDeleteId(value)
      setIstToggleDelete(true)
    }
  }


  function resetPass(value) {
    ApiService.init()
    ApiService
      .post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/send-reset-password', {
          email: value,
      })
      .then((response) => {
          toastSuccess('Silahkan cek email anda.');
      })
      .catch((error) => {
          toastError('Gagal mengirim email.');
      })
  }

  function toggleUpdate(value) {
    if (isToggleEdit === true) {
      setIsToggleEdit(false)
    } else {
      setUser(value)
      setIsToggleEdit(true)
    }
  }

  function deleteUser() {
    ApiService.init()
    ApiService.delete(
      process.env.REACT_APP_BACKEND_DOMAIN + '/api/user/' + deleteId,
    )
      .then((response) => {
        window.location = '/user'
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function searchData(value) {
    setSearch(value)
    ApiService.init()
    ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/user?per_page` + perPage + `&search=` + value)
      .then((response) => {
        setUsers([...response.data.data])
        let paginateData = {
          'firstPage': 1,
          'lastPage': response.data.lastPage,
          'totalPage': response.data.total,
          'nextPageUrl': response.data.nextPageUrl,
          'prevPageUrl': response.data.prevPageUrl,
          'activePage': 1,
          'from': response.data.from,
          'to': response.data.to
        }

        setPaginate({ ...paginateData })
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function prevPage() {
    ApiService.init()
    ApiService.get(paginate.prevPageUrl + '&per_page=' + perPage)
      .then((response) => {
        setUsers([...response.data.data])

        let paginateData = {
          'firstPage': 1,
          'lastPage': response.data.lastPage,
          'totalPage': response.data.total,
          'nextPageUrl': response.data.nextPageUrl,
          'prevPageUrl': response.data.prevPageUrl,
          'activePage': 1,
          'from': response.data.from,
          'to': response.data.to
        }

        setPaginate({ ...paginateData })
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function nextPage() {
    ApiService.init()
    ApiService.get(paginate.nextPageUrl + '&per_page=' + perPage)
      .then((response) => {
        setUsers([...response.data.data])

        let paginateData = {
          'firstPage': 1,
          'lastPage': response.data.lastPage,
          'totalPage': response.data.total,
          'nextPageUrl': response.data.nextPageUrl,
          'prevPageUrl': response.data.prevPageUrl,
          'activePage': 1,
          'from': response.data.from,
          'to': response.data.to
        }

        setPaginate({ ...paginateData })
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function updateData(field, value) {
    let newUser = { ...user };
    newUser[field] = value;
    setUser(newUser)
  }

  for (const [index, value] of users.entries()) {
    tableUserList.push(
      <tr key={'table-user-' + index}>
        <td>{value.name}</td>
        <td className='!lowercase'>{value.email}</td>
        <td className=''>{value.status}</td>
        <td>
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="cursor-pointer bg-[gray]">
              <div className="w-10 rounded-full flex justify-end">
                <Dot />
              </div>
            </label>
            <ul
              tabIndex={0}
              className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px]"
            >
              <li className="border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]">
                <div
                  className="flex flex-wrap justify-between"
                  onClick={toggleUpdate.bind(this, value)}
                >
                  <div className="">Ubah</div>
                  <div className="">
                    <Pencil />
                  </div>
                </div>
              </li>
              <li className="border-b-[1px] border-[rgba(173,173,173,0.3)] py-[8px]">
                <div
                  className="flex flex-wrap justify-between"
                  onClick={resetPass.bind(this, value.email)}
                >
                  <div className="">Reset Password</div>
                  <div className="">
                    <Key />
                  </div>
                </div>
              </li>
              <li className="pt-[8px]">
                <div
                  className="flex flex-wrap justify-between"
                  onClick={toggleDelete.bind(this, value.id)}
                >
                  <div>Hapus</div>
                  <div>
                    <Trash />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    )
  }

  if (localStorage.getItem('role') === 'Admin') {
    return (
      <div className="w-full min-h-screen h-fit bg-white cabang">
        <div className="top-content">
          <Navbar />
          <div className="content">
            <div className="header flex flex-wrap">
              <div className="basis-4/12 title">
                <h3>User</h3>
              </div>
              <div className="basis-4/12 search flex items-center">
                <div className='w-full h-full'>
                  <input type="text" placeholder='CARI' className='search h-full bg-transparent px-[10px] w-full' value={search} onChange={(e) => searchData(e.target.value)} />
                </div>
              </div>
              <div className="basis-4/12 action flex flex-wrap items-center justify-end gap-x-[24px] pl-[24px]">
                <button
                  className="btn gap-x-[12px]"
                  onClick={toggleUser.bind(this)}
                >
                  TAMBAH USER
                </button>
              </div>
            </div>
            <div className="cabang-table">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>NAMA USER</th>
                    <th>EMAIL</th>
                    <th>ROLE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {(isAction) 
                      ? (
                          <LoadingTable colSpan="6"/>
                      ) 
                      : tableUserList
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan="2">
                      <TrashGray />
                    </th>
                    <th colSpan="2" className="flex flex-wrap items-center">
                      <div className="basis-6/12">
                        Baris per halaman:
                        <select className="select w-fit m-0" value={perPage} onChange={(e) => updatePerPage(e.target.value)}>
                          <option>10</option>
                          <option>20</option>
                          <option>30</option>
                          <option>40</option>
                          <option>50</option>
                        </select>
                      </div>
                      <div className="basis-6/12 flex justify-end items-center">
                        <div>
                          {paginate.from}-{paginate.to} dari {paginate.totalPage}
                        </div>
                        <div>
                          <div className="w-[24px]">
                            <button className={paginate.prevPageUrl === null ? 'hidden' : 'btn btn-transparent'} onClick={prevPage.bind(this)} >
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.9802 5.32024L10.7702 8.53024L8.80023 10.4902C7.97023 11.3202 7.97023 12.6702 8.80023 13.5002L13.9802 18.6802C14.6602 19.3602 15.8202 18.8702 15.8202 17.9202V12.3102V6.08024C15.8202 5.12024 14.6602 4.64024 13.9802 5.32024Z" fill="#3375F5" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div>
                          <button className={paginate.nextPageUrl === null ? 'hidden' : 'btn btn-transparent'} onClick={nextPage.bind(this)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.2002 10.4899L13.2302 8.51993L10.0202 5.30993C9.34018 4.63993 8.18018 5.11993 8.18018 6.07993V12.3099V17.9199C8.18018 18.8799 9.34018 19.3599 10.0202 18.6799L15.2002 13.4999C16.0302 12.6799 16.0302 11.3199 15.2002 10.4899Z" fill="#3375F5" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <CreateUser
          toggleAddUser={toggleAddUser}
          toggleUser={toggleUser}
        />
        <div
          className={
            isToggleDelete === true
              ? 'delete-popup fixed bg-[rgba(41,45,50,0.5)] top-[0] h-[100vh] w-[100vw] -ml-[16rem] flex justify-center'
              : '!hidden'
          }
          id="deleteForm"
        >
          <div className="">
            <div className="border-b-[0.5px] border-[#D9D9D9]">
              <div className="flex justify-between px-[32px] items-center !h-[80px]">
                <h3>Nonaktifkan User</h3>
                <button
                  className="btn btn-transparent !p-0"
                  onClick={toggleDelete.bind(this)}
                >
                  <Close />
                </button>
              </div>
            </div>
            <div className="px-[32px]">
              <h4>Apakah Anda yakin akan menonaktifkan user?</h4>
              <div className="grid grid-cols-2 gap-x-[26px]">
                <button className="btn-outline" onClick={toggleDelete.bind(this)}>
                  BATAL
                </button>
                <button className="btn" onClick={deleteUser.bind(this)}>
                  NONAKTIFKAN
                </button>
              </div>
            </div>
          </div>
        </div>
        <EditUser
          isToggleEdit={isToggleEdit}
          toggleUpdate={toggleUpdate.bind(this, user)}
          updateData={updateData}
          user={user}
        />
      </div>
    )
  }
}
