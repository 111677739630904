import '../../view-template1-grid.css';
import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Template2() {
  const { id, code } = useParams();
  const [campaign, setCampaign] = useState([])
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let day = [], startTime = null, endTime = null;

    const d = new Date();
    let dayName = weekday[d.getDay()];

    var seconds = d.getSeconds();
    var minutes = d.getMinutes();
    var hour = d.getHours();
    ApiService.init()
    ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign/${id}/preview?code=${code}`)
      .then((response) => {
        setCampaign({ ...response.data.data });
        let weekList = response.data.data.workdays;

        if (weekList['sunday'] === true) {
          day.push('sunday')
        }

        if (weekList['monday'] === true) {
          day.push('monday')
        }

        if (weekList['tuesday'] === true) {
          day.push('tuesday')
        }

        if (weekList['wednesday'] === true) {
          day.push('wednesday')
        }

        if (weekList['thursday'] === true) {
          day.push('thursday')
        }

        if (weekList['friday'] === true) {
          day.push('friday')
        }

        if (weekList['saturday'] === true) {
          day.push('saturday')
        }

        startTime = response.data.data.startTime;
        endTime = response.data.data.endTime;

        if (day.includes(dayName) === true) {

          seconds = d.getSeconds();
          minutes = d.getMinutes();
          hour = d.getHours();

          let times = hour + ':' + minutes + ':' + seconds;
          console.log(times)
          if (times > startTime && times < endTime) {
            setVisible(true)
          } else {
            setVisible(false)
          }
        } else {
          setVisible(false)
        }
      })

    setInterval(() => {
      const d = new Date();
      let dayName = weekday[d.getDay()];

      var seconds = d.getSeconds();
      var minutes = d.getMinutes();
      var hour = d.getHours();
      ApiService.init()
      ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign/${id}/preview?code=${code}`)
        .then((response) => {
          setCampaign({ ...response.data.data });
          let weekList = response.data.data.workdays;

          if (weekList['sunday'] === true) {
            day.push('sunday')
          }

          if (weekList['monday'] === true) {
            day.push('monday')
          }

          if (weekList['tuesday'] === true) {
            day.push('tuesday')
          }

          if (weekList['wednesday'] === true) {
            day.push('wednesday')
          }

          if (weekList['thursday'] === true) {
            day.push('thursday')
          }

          if (weekList['friday'] === true) {
            day.push('friday')
          }

          if (weekList['saturday'] === true) {
            day.push('saturday')
          }

          startTime = response.data.data.startTime;
          endTime = response.data.data.endTime;

          // console.log(startTime, endTime)

          if (day.includes(dayName) === true) {
            seconds = d.getSeconds();
            minutes = d.getMinutes();
            hour = d.getHours();

            let times = hour + ':' + minutes + ':' + seconds;
            if (times > startTime && times < endTime) {
              setVisible(true)
            } else {
              setVisible(false)
            }
          } else {
            setVisible(false)
          }
        })
    }, 10000);

  }, [id, code])

  function RenderMedia() {
    if (Object.keys(campaign).length !== 0) {

      let slideValue = campaign.campaignDetails[0]

      if (slideValue.length > 1) {
        let slider = []
        slideValue.forEach(function (value, index) {
          slider.push(
            <div key={index} className="max-w-full w-[100%] max-h-[100%] h-[100%]">
              <img alt="sliderImg" className="max-w-full w-[100%] max-h-[100%] h-[100%] object-cover" src={process.env.REACT_APP_BACKEND_DOMAIN + "/storage/" + value.gallery.value} />
            </div>
          )
        })
        var settings = {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 3000,
          cssEase: "linear"
        };
        return (
          <Slider {...settings}>
            {slider}
          </Slider>
        );
      } else {
        return (
          <div className="max-w-full w-[100%] h-[100%] max-h-[100%] ">
            <img alt="media" className="max-w-full w-[100%] h-[100%] max-h-[100%] object-cover" src={process.env.REACT_APP_BACKEND_DOMAIN + "/storage/" + slideValue[0].gallery.value} />
          </div>
        )
      }
    }
  }

  function RenderRunningText() {
    if (Object.keys(campaign).length !== 0) {
      let runningText = campaign.campaignDetails[1][0]
      if( runningText.valueFont === 'Arial') {
        return (
          <div id="scroll-container" className='flex items-center h-[100%]'>
            <p id="scroll-text" className={`arial text-[` + runningText.valueFontSize + `px]`}>
              {runningText.value}
            </p>
          </div>
        )
      }

      if( runningText.valueFont === 'Helvetica') {
        return (
          <div id="scroll-container" className='flex items-center h-[100%]'>
            <p id="scroll-text" className={`helvetica text-[` + runningText.valueFontSize + `px]`}>
              {runningText.value}
            </p>
          </div>
        )
      }

      if( runningText.valueFont === 'Times New Roman') {
        return (
          <div id="scroll-container" className='flex items-center h-[100%]'>
            <p id="scroll-text" className={`timesNewRoman text-[` + runningText.valueFontSize + `px]`}>
              {runningText.value}
            </p>
          </div>
        )
      }

      if( runningText.valueFont === 'Gotham') {
        return (
          <div id="scroll-container" className='flex items-center h-[100%]'>
            <p id="scroll-text" className={`gotham text-[` + runningText.valueFontSize + `px]`}>
              {runningText.value}
            </p>
          </div>
        )
      }
    }
  }
  
  if (visible === false) {
    return (
      <div></div>
    )
  } else {
    return (
      <div className="template template-preview gap-[20px] gap-y-[20px] overflow-hidden">
        <div className='mb-[20px] h-[87vh] max-h-[87vh] w-full bg-[#FFFFFF]'>
          <RenderMedia />
        </div>
        <div className='w-full bg-[#FFFFFF] h-[5vh]'>
          <RenderRunningText />
        </div>
      </div>
    )
  }
}