import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Dashboard from './pages/dashboard/Dasboard';

import App from './App';
import reportWebVitals from './reportWebVitals';
import "react-datepicker/dist/react-datepicker.css";
import { QueryClient, QueryClientProvider } from 'react-query'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import IndexCabang from './pages/cabang/Index';
import DetailCabang from './pages/cabang/Detail';
import CreateCampaign from './pages/cabang/CreateCampaign';
import IndexMeetingRoom from './pages/ruang-meeting/Index';
import DetailMeetingRoom from './pages/ruang-meeting/Detail';
import CreateMeetingBoard from './pages/ruang-meeting/CreateMeetingBoard';
import IndexCampaign from './pages/campaign/Index';
import TemplateCampaign from './pages/campaign/Template';
import IndexLaporan from './pages/laporan/Index';
import IndexUser from './pages/user/Index';
import ViewTemplate1 from './pages/campaign/ViewTemplate1';
import ViewTemplate2 from './pages/campaign/ViewTemplate2';
import ViewTemplate3 from './pages/campaign/ViewTemplate3';
import ViewTemplate4 from './pages/campaign/ViewTemplate4';
import ViewTemplate5 from './pages/campaign/ViewTemplate5';
import ViewTemplate6 from './pages/campaign/ViewTemplate6';
import ViewTemplate7 from './pages/campaign/ViewTemplate7';
import ViewTemplate8 from './pages/campaign/ViewTemplate8';
import ViewTemplate9 from './pages/campaign/ViewTemplate9';
import ViewTemplate10 from './pages/campaign/ViewTemplate10';
import Template1 from './pages/campaign/Template1';
import Template2 from './pages/campaign/Template2';
import Template3 from './pages/campaign/Template3';
import Template4 from './pages/campaign/Template4';
import Template5 from './pages/campaign/Template5';
import Template6 from './pages/campaign/Template6';
import Template7 from './pages/campaign/Template7';
import Template8 from './pages/campaign/Template8';
import Template9 from './pages/campaign/Template9';
import Template10 from './pages/campaign/Template10';
import AolCallback from './pages/campaign/AolCallback';
import ViewMeeting from './pages/ruang-meeting/ViewMeeting';
import UpdatePassword from './pages/setting/UpdatePassword';
import UpdateProfile from './pages/setting/UpdateProfile';

import ProtectedRoute from './ProtectedRoute';
import DashboardAdmin from './pages/dashboard/DasboardAdmin';

import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <App />
);

const queryClient = new QueryClient()

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={<Login />} />
        <Route
          path="/register"
          element={<Register />} />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />} />
        <Route
          path="/password-reset/:token"
          element={<ResetPassword />} />
          
        <Route element={<ProtectedRoute />}>
          {/* 1, 2, 3 = id template, :id = id campaign, code = code url */}
          <Route path='/view/1/:id/:code' element={<ViewTemplate1 />} />
          <Route path='/view/2/:id/:code' element={<ViewTemplate2 />} />
          <Route path='/view/3/:id/:code' element={<ViewTemplate3 />} />
          <Route path='/view/4/:id/:code' element={<ViewTemplate4 />} />
          <Route path='/view/5/:id/:code' element={<ViewTemplate5 />} />
          <Route path='/view/6/:id/:code' element={<ViewTemplate6 />} />
          <Route path='/view/7/:id/:code' element={<ViewTemplate7 />} />
          <Route path='/view/8/:id/:code' element={<ViewTemplate8 />} />
          <Route path='/view/9/:id/:code' element={<ViewTemplate9 />} />
          <Route path='/view/10/:id/:code' element={<ViewTemplate10 />} />
          <Route path='/view-meeting/:id/:code' element={<ViewMeeting />} />

          <Route
            path="/"
            element={<App />}>
            <Route
              path="/"
              element={<Dashboard />} />
            <Route
              path="/dashboard"
              element={<DashboardAdmin />} />
            <Route
              path="/user"
              element={<IndexUser />} />
            <Route
              path="/cabang"
              element={<IndexCabang />} />
            <Route
              path="/cabang/detail/:id"
              element={<DetailCabang />} />
            <Route
              path="/cabang/create-campaign"
              element={<CreateCampaign />} />
            {/*<Route
              path="/ruang-meeting"
              element={<IndexMeetingRoom />} />
            <Route
              path="/ruang-meeting/detail"
              element={<DetailMeetingRoom />} />
            <Route
              path="/ruang-meeting/create-board"
              element={<CreateMeetingBoard />} />
            <Route
              path="/ruang-meeting/:id"
              element={<CreateMeetingBoard />} />*/}
            <Route
              path="/campaign"
              element={<IndexCampaign />} />
            <Route
              path="/campaign/template"
              element={<TemplateCampaign />} />
            <Route
              path="/campaign/template/1"
              element={<Template1 />} />
            <Route
              path="/campaign/template/1/:id"
              element={<Template1 />} />
            <Route
              path="/campaign/template/2"
              element={<Template2 />} />
            <Route
              path="/campaign/template/2/:id"
              element={<Template2 />} />
            <Route
              path="/campaign/template/3"
              element={<Template3 />} />
            <Route
              path="/campaign/template/3/:id"
              element={<Template3 />} />
            <Route
              path="/campaign/template/4"
              element={<Template4 />} />
            <Route
              path="/campaign/template/4/:id"
              element={<Template4 />} />
            <Route
              path="/campaign/template/5"
              element={<Template5 />} />
            <Route
              path="/campaign/template/5/:id"
              element={<Template5 />} />
            <Route
              path="/campaign/template/6"
              element={<Template6 />} />
            <Route
              path="/campaign/template/6/:id"
              element={<Template6 />} />
            <Route
              path="/campaign/template/7"
              element={<Template7 />} />
            <Route
              path="/campaign/template/7/:id"
              element={<Template7 />} />
            <Route
              path="/campaign/template/8"
              element={<Template8 />} />
            <Route
              path="/campaign/template/8/:id"
              element={<Template8 />} />
            <Route
              path="/campaign/template/9"
              element={<Template9 />} />
            <Route
              path="/campaign/template/9/:id"
              element={<Template9 />} />
            <Route
              path="/campaign/template/10"
              element={<Template10 />} />
            <Route
              path="/campaign/template/10/:id"
              element={<Template10 />} />
            <Route
              path="/aol-oauth-callback"
              element={<AolCallback />} />
            <Route
              path="/laporan"
              element={<IndexLaporan />} />
            <Route
              path="/update-password"
              element={<UpdatePassword />} />
            <Route
              path="/update-profile"
              element={<UpdateProfile />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </QueryClientProvider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
