import React, { useState, useEffect } from "react";
import Alert from '../../components/alert'
import ApiService from '../../services/api.service'
import { toastSuccess, toastError } from '../../components/toast';

export default function TableBranch(props) {
    async function saveBranch() {
        if (props.branch.name === '' || props.address === '') {
            toastError("Silahkan isi semua field yang ada.", "left", "validation")
        } else {

            let kotaData = null, provinceData = null, kecamatanData = null;

            await fetch(
                `https://www.emsifa.com/api-wilayah-indonesia/api/district/` +
                props.kecamatan +
                `.json`,
            )
                .then((response) => response.json())
                .then((kecamatan) => {
                    kecamatanData = kecamatan.name
                })


            await fetch(
                `https://www.emsifa.com/api-wilayah-indonesia/api/province/` +
                props.provinsi +
                `.json`,
            )
                .then((response) => response.json())
                .then((provinsi) => {
                    provinceData = provinsi.name
                })

            await fetch(
                `https://www.emsifa.com/api-wilayah-indonesia/api/regency/` +
                props.kota +
                `.json`,
            )
                .then((response) => response.json())
                .then((regency) => {
                    kotaData = regency.name
                })

            ApiService.init()
            ApiService
                .post('/api/branch', {
                    name: props.branch.name,
                    province: provinceData,
                    city: kotaData,
                    district: kecamatanData,
                    address: props.branch.address
                })
                .then((response) => {
                    window.location = '/cabang'
                    toastSuccess("Cabang berhasil ditambahkan!", "left", "success")
                })
                .catch((error) => {
                    toastError("Cabang gagal ditambahkan!", "left", "failed")
                })
        }
    }

    return (
        <>
            <div className={props.toggleAddCabang === true ? 'fixed w-screen h-screen top-0 right-0 flex justify-end toggle-menu' : 'hidden'}>
                <div>
                    <div className='title flex flex-wrap items-center'>
                        {/*{(alertProps) ? (
                            <div className="fixed w-2/5">
                                <Alert {...alertProps} />
                            </div>
                            ) : null}*/}
                        <div className='basis-10/12'>
                            <h3>Tambah Cabang</h3>
                        </div>
                        <div className='basis-2/12 flex justify-end'>
                            <button className='btn btn-transparent mr-0' onClick={props.toggleCabang}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z" fill="black" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='content'>
                        <div>
                            <label htmlFor="namaBranch">Nama Branch</label>
                            <input type="text" className="text" name="" id="" value={props.branch.name} onChange={e => props.updateData('name', e.target.value)} />
                        </div>

                        <div className="">
                            <label htmlFor="provinsi">Provinsi</label>
                            <div className='relative'>
                                <div className='svg-home'></div>
                                <select className="select w-full" id="provinsi" value={props.provinsi} onChange={(e) => props.changeListKota(e)}>
                                    <option disabled>Pilih Provinsi</option>
                                    {props.pronvisiOption}
                                </select>
                            </div>
                        </div>

                        <div className="">
                            <label htmlFor="provinsi">Kota</label>
                            <div className='relative'>
                                <div className='svg-pin'></div>
                                <select className="select w-full" id="kota" value={props.kota} onChange={(e) => props.changeListKecamatan(e)}>
                                    <option disabled>Pilih Kota</option>
                                    {props.kotaOption}
                                </select>
                            </div>
                        </div>

                        <div className="">
                            <label htmlFor="provinsi">Kecamatan</label>
                            <div className='relative'>
                                <div className='svg-pin'></div>
                                <select className="select w-full" id="kecamatan" value={props.kecamatan} onChange={(e) => props.changeBranchKecamatan(e.target.value)}>
                                    <option disabled>Pilih Kecamatan</option>
                                    {props.kecamatanOption}
                                </select>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="alamatLengkap">Alamat Lengkap</label>
                            <textarea name="alamatLengkap" id="" value={props.branch.address} onChange={(e) => props.updateData('address', e.target.value)}></textarea>
                        </div>

                        <div>
                            <button className='btn' onClick={saveBranch.bind(this)}> SIMPAN </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}