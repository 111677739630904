import React, { useState, useCallback, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import Dropzone from './dropzone/Dropzone'
import ImageGrid from './dropzone/components/ImageGridSingle'
import './dropzone/index.css'
import ApiService from '../services/api.service'
import { Gallery } from 'react-grid-gallery'

import '../components/component.scss'

export default function SignleImagePopup(props) {
  const [random, setRandom] = useState(false)
  const [gallery, setGallery] = useState([])
  const [selectedImage, setSelectedImage] = useState([])
  const [section, setSection] = useState('unggah')
  const hasSelected = [[]]
  const [images, setImages] = useState([])

  let selectedGallery = []
  let files = []

  function updateValue(value) {
    setRandom(value)
  }

  function sectionChange(newValue) {
    if (newValue == 'gallery') {
      ApiService.init()
      ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/gallery', {})
        .then((response) => {
          let galleryList = response.data.data
          let imagesList = []

          galleryList.forEach((value) => {
            imagesList.push({
              src:
                process.env.REACT_APP_BACKEND_DOMAIN +
                '/storage/' +
                value.value,
              id: value.id,
              width: 1000,
              height: 1000,
              isSelected: false,
              name: value.filename
            })
          })
          setGallery([...imagesList])
          hasSelected = gallery.some((image) => image.isSelected)
        })
        .catch((error) => {
        })
    }

    setSection(newValue)
  }

  function ImagePopup() {
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.map((file, index) => {
        const reader = new FileReader()

        var bodyFormData = new FormData();
        bodyFormData.append('type', 'image');
        bodyFormData.append('data', file);

        reader.onload = function (e) {

          ApiService.initFormData()
          ApiService.postFormData(process.env.REACT_APP_BACKEND_DOMAIN + '/api/gallery', bodyFormData)
            .then((response) => {
              setImages((prevState) => [
                ...prevState,
                {
                  id: response.data.id,
                  src: e.target.result,
                  type: checkType(e.target.result),
                  name: file.name,
                  file: file,
                },
              ])
            })
            .catch((error) => {
              // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
        }
        reader.readAsDataURL(file)
        return file
      })
    }, [])

    function checkType(file) {
      if (file.includes('video')) {
        return 'video'
      } else if (file.includes('image')) {
        return 'image'
      }
    }

    function deleteImage(index) {
      let newImages = [...images]
      newImages.splice(index, 1)
      setImages([...newImages])
    }

    function handleSelect(index: number) {
      let imageCollection = [...selectedImage];
      imageCollection.push(gallery[index]);
      setSelectedImage(...[imageCollection]);

      selectedGallery.push({ ...gallery[index] })

      const nextImages = gallery.map((image, i) =>
        i === index ? { ...image, isSelected: !image.isSelected } : image,
      )

      setGallery(nextImages)
    }

    function SelectedImageGallery() {
      let images = []

      // Rendering individual images
      const Image = ({ image }) => {
        return (
          <div className="file-item">
            <img
              alt={`img - ${image.id}`}
              src={image.src}
              className="file-img"
            />
          </div>
        );
      };

      for (const [index, value] of selectedImage.entries()) {
        images.push(
          <div className="w-full flex flex-wrap tr" key={`${value.id}-image`} >
            <div className="relative basis-6/12 td">
              <div className="image-layout">
                <button
                  className="btn btn-transparent mr-0"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#242424" />
                  </svg>
                </button>
                <Image image={value} />
              </div>
            </div>
            <div className="basis-6/12 td flex items-center justify-center">
              {value.name}
            </div>
          </div>
        )
      }

      return images
    }

    return (
      <div className={props.isSingleImageOn === true ? ' image-popup' : 'hidden'}>
        <div className="title flex flex-wrap items-center">
          <div className="basis-10/12">
            <h3>Tambah Media</h3>
          </div>
          <div className="basis-2/12 flex justify-end">
            <button
              className="btn btn-transparent mr-0"
              onClick={props.togglePopup.bind(this, 'single')}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="tabs w-full flex">
          <a
            className={
              section === 'unggah'
                ? 'tab tab-bordered basis-6/12 h-[50px] tab-active'
                : 'tab tab-bordered basis-6/12 h-[50px]'
            }
            onClick={sectionChange.bind(this, 'unggah')}
          >
            Unggah
          </a>
          <a
            className={
              section === 'gallery'
                ? 'tab tab-bordered basis-6/12 h-[50px] tab-active'
                : 'tab tab-bordered basis-6/12 h-[50px]'
            }
            onClick={sectionChange.bind(this, 'gallery')}
          >
            Galeri
          </a>
        </div>
        <section
          className={
            section === 'unggah' ? 'content unggah relative' : 'hidden'
          }
        >
          <div className={images.length > 0 ? 'hidden' : 'asd'}>
            <Dropzone onDrop={onDrop} multiple={false} />
          </div>
          <div className={images.length === 0 ? 'hidden' : ' gap-x-[42px] mt-[2vh]'}>
            <ImageGrid images={images} tpye={'single'} deleteImage={deleteImage} />
            <div className="basis-full duration">
              <div className='flex justify-end mt-[2vh]'>
                <button className='btn upercase' onClick={props.updateSingleImageList.bind(this, images)}>Simpan Gambar</button>
              </div>
            </div>
          </div>
        </section>
        <section
          className={
            section === 'gallery' ? 'content relative gallery mb-[100px]' : 'hidden'
          }
        >
          <div className={props.isSingleImageOn === true ? '' : 'hidden'}>
            <div className={selectedImage.length > 0 ? 'hidden' : 'gallery-section max-h-[30vh] overflow-auto'}>
              <Gallery images={gallery} onSelect={handleSelect} />
            </div>
            <div className='px-[33px] mt-[30px]'>
              <section className={selectedImage.length === 0 ? 'hidden' : "file-list table w-full"}>
                <div className="thead">
                  <div className="flex tr">
                    <div className="th basis-6/12">File</div>
                    <div className="th basis-6/12">Nama File</div>
                  </div>
                </div>
                <div className="tbody">
                  <SelectedImageGallery />
                </div>
              </section>
              <div className="basis-full duration relative">
                <div className='flex justify-end mt-[2vh]'>
                  <button className='btn upercase' onClick={props.updateSingleImageList.bind(this, selectedImage)}>Simpan Gambar</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  return <ImagePopup />
}
