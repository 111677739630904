import React from "react";
import './component.scss'

export default function alert(props) {
    return (
        <div id="alert" className={`${props?.type === 'error' ? "alert alert-error !w-2/4" : "alert alert-success w-2/4"}`}  >
            <div className="!text-center">
                <span className="font-[Poppins]">{props?.msg}</span>
            </div>
        </div>
    );
}