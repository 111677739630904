const ProgressBar = ({ percentage  }) => {
  const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 36,
  };

  const containerStyles = {
    height: 15,
    width: '100%',
    backgroundColor: '#e0e0df',
    borderRadius: '50px',
  };

  const fillerStyles = {
    height: '100%',
    width: `${percentage}%`,
    backgroundImage: 'linear-gradient(to right, #e3e3e3, #9c9c9c)',
    borderRadius: 'inherit',
    textAlign: 'right',
  };

  const progressLabelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  const percentageLabelStyles = {
    padding: 5,
    fontSize: '32px',
    fontWeight: 'light',
    color: '#9c9c9c',
  };

  const messageLabelStyles = {
    padding: 5,
    fontSize: '16px',
    fontWeight: 'light',
    color: '#9c9c9c',
  };

  return (
    <div style={{
        backgroundColor: '#c6faaa',
        padding: '24px',
        width: '80%',
      }}>
      <div style={cardStyles}>
        <span style={percentageLabelStyles}>{`${percentage}%`}</span>
        <div style={containerStyles}>
          <div style={fillerStyles}>
            {/*<span style={progressLabelStyles}>{`${percentage}%`}</span>*/}
          </div>
        </div>
        <span style={messageLabelStyles}>Proses penarikan data sedang berlangsung</span>
      </div>
    </div>
  );
};

export default ProgressBar;