import React from "react";
import { useDropzone } from "react-dropzone";

function Dropzone({ onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept,
      onDrop,
    });

  return (
    <div>
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <h3 className="dropzone-content">
              Lepaskan untuk mengupload file
            </h3>
          ) : (
            <h3 className="dropzone-content">
              Seret dan jatuhkan file
            </h3>
          )}
          <div className="divider">atau</div>
          <button type="button" onClick={open} className="btn btn-outline">
            Jelajahi file
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dropzone;