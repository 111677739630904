import React from "react";
import { ColorPicker } from '@wellbees/color-picker-input'

export default function TextPopup(props) {
    return (
        <div className={props.isTextOn === true ? ' text-popup' : 'hidden'}>
            <div className='title flex flex-wrap items-center'>
                <div className='basis-10/12'>
                    <h3>Tambah Text</h3>
                </div>
                <div className='basis-2/12 flex justify-end'>
                    <button className='btn btn-transparent mr-0' onClick={props.togglePopup.bind(this, 'teks')}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z" fill="black" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className='content overflow-auto'>
                <div className='h-[11vh]'>
                    <label htmlFor="judul">Judul</label>
                    <input type="text" name="judul" className="w-full" placeholder='Masukkan judul anda disini' value={props.text.title} onChange={(e) => props.updateData('text', 'title', e.target.value)} />
                </div>
                <div className='grid grid-cols-12 gap-x-[1vw] h-[11vh]'>
                    <div className='col-span-3'>
                        <label htmlFor="ukuranJudul">Ukuran Judul</label>
                        <input type="number" name="ukuranJudul" className="w-full" placeholder='24' value={props.text.fontSize} onChange={(e) => props.updateData('text', 'fontSize', e.target.value)} />
                    </div>
                    <div className='col-span-6'>
                        <label htmlFor="fontJudul">Font Judul</label>
                        <select className="select w-full"  value={props.text.fontFamily} onChange={(e) => props.updateData('text', 'fontFamily', e.target.value)}>
                            <option disabled>Pilih Font</option>
                            <option>Arial</option>
                            <option>Times New Roman</option>
                            <option>Sans Serif</option>
                            <option>Helvetica</option>
                            <option>Gotham</option>
                        </select>
                    </div>
                    <div className='col-span-3'>
                        <label htmlFor="titleColor">Warna Font</label>
                        <input type="color" name="titleColor" value={props.text.titleColor} onChange={(e) => props.updateData('text', 'titleColor', e.target.value)} />
                    </div>
                </div>
                <div className='h-[14vh]'>
                    <label htmlFor="teks">Teks</label>
                    <textarea name="teks"  value={props.text.teks} onChange={(e) => props.updateData('text', 'teks', e.target.value)}></textarea>
                </div>
                <div className='grid grid-cols-12 gap-x-[1vw] h-[11vh] mt-[4vh]'>
                    <div className='col-span-3'>
                        <label htmlFor="ukuranJudul">Ukuran Teks</label>
                        <input type="number" name="ukuranJudul" className="w-full" placeholder='24'  value={props.text.teksFontSize} onChange={(e) => props.updateData('text', 'teksFontSize', e.target.value)} />
                    </div>
                    <div className='col-span-6'>
                        <label htmlFor="fontJudul">Font Teks</label>
                        <select className="select w-full"  value={props.text.teksFontFamily} onChange={(e) => props.updateData('text', 'teksFontFamily', e.target.value)}>
                            <option disabled>Pilih Font</option>
                            <option>Arial</option>
                            <option>Times New Roman</option>
                            <option>Sans Serif</option>
                            <option>Helvetica</option>
                            <option>Gotham</option>
                        </select>
                    </div>
                    <div className='col-span-3'>
                        <label htmlFor="teksColor">Warna Font</label>
                        <input type="color" name="teksColor" value={props.text.teksColor} onChange={(e) => props.updateData('text', 'teksColor', e.target.value)} />
                    </div>
                </div>
                <div className='w-full'>
                    <label htmlFor="bgColor">Background</label>
                    <input type="color" name="bgColor" value={props.text.bgColor} onChange={(e) => props.updateData('text', 'bgColor', e.target.value)} />
                </div>
                <div className='flex justify-end mt-[4vh]'>
                    <button className='btn upercase' disabled={props.text.teks === '' || props.text.title === '' } onClick={props.togglePopup.bind(this, 'teks')}>simpan teks</button>
                </div>
            </div>
        </div>
    );
}