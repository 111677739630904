import React, { useState, useEffect, useRef } from 'react';
import Profile from "../../assets/images/profile/profile.png"
import Navbar from '../../components/menu/Navbar';
import ApiService from '../../services/api.service'
import LoadingTable from '../../components/LoadingTable'

export default function Index() {
    const timer = useRef(null)
    const [display, setDisplay] = useState('list')
    const [report, setReport] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [isAction, setIsAction] = useState(true)
    const [paginate, setPaginate] = useState({
        'firstPage': 1,
        'lastPage': 1,
        'totalPage': 1,
        'activePage': 1,
        'from': 0,
        'to': 0
    })
    const [search, setSearch] = useState('')
    function updateDisplay() {
        if (display === 'table') {
            setDisplay('list')
        } else {
            setDisplay('table')
        }
    }


    useEffect(() => {
        ApiService.init()
        ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-report?per_page=' + perPage)
            .then((response) => {
                setReport([...response.data.data])
                setPaginate({
                    ...{
                        'firstPage': 1,
                        'lastPage': response.data.lastPage,
                        'totalPage': response.data.total,
                        'nextPageUrl': response.data.nextPageUrl,
                        'prevPageUrl': response.data.prevPageUrl,
                        'activePage': 1,
                        'from': response.data.from,
                        'to': response.data.to
                    }
                })
            })
            .catch(() => {
            })
            .finally(() => {
                setIsAction(false)
            })
    }, [display])

    function searchData(value) {
        clearTimeout(timer.current)
        setSearch(value)

        timer.current = setTimeout(() => {

            ApiService.init()
            ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-report?per_page=' + perPage + '&search=' + value)
                .then((response) => {
                    setReport([...response.data.data])
                    setPaginate({
                        ...{
                            'firstPage': 1,
                            'lastPage': response.data.lastPage,
                            'totalPage': response.data.total,
                            'nextPageUrl': response.data.nextPageUrl,
                            'prevPageUrl': response.data.prevPageUrl,
                            'activePage': 1,
                            'from': response.data.from,
                            'to': response.data.to
                        }
                    })
                })
                .catch(() => {
                })
        }, 1000)
    }

    function prevPage() {
        ApiService.init()
        ApiService.get(paginate.prevPageUrl +'&per_page='+perPage)
            .then((response) => {
                setReport([...response.data.data])

                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'nextPageUrl': response.data.nextPageUrl,
                    'prevPageUrl': response.data.prevPageUrl,
                    'activePage': 1,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }

    function nextPage() {
        ApiService.init()
        ApiService.get(paginate.nextPageUrl +'&per_page='+perPage)
            .then((response) => {
                setReport([...response.data.data])

                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'nextPageUrl': response.data.nextPageUrl,
                    'prevPageUrl': response.data.prevPageUrl,
                    'activePage': 1,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }

    function updatePerPage(value) {
        setPerPage(value)
        ApiService.init()
        ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-report?per_page=' + value)
            .then((response) => {
                setReport([...response.data.data])

                let paginateData = {
                    'firstPage': 1,
                    'lastPage': response.data.lastPage,
                    'totalPage': response.data.total,
                    'nextPageUrl': response.data.nextPageUrl,
                    'prevPageUrl': response.data.prevPageUrl,
                    'activePage': 1,
                    'from': response.data.from,
                    'to': response.data.to
                }

                setPaginate({ ...paginateData })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })

    }



    const reportList = []
    for (const [index, value] of report.entries()) {
        reportList.push(
            <tr key={'table-cabang-' + index}>
                <td>
                    {value.campaignName}
                </td>
                <td>
                    <div className='flex gap-x-[4px]'>
                        <div>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8333 9.33333C13.8333 12.5533 11.22 15.1667 7.99996 15.1667C4.77996 15.1667 2.16663 12.5533 2.16663 9.33333C2.16663 6.11333 4.77996 3.5 7.99996 3.5C11.22 3.5 13.8333 6.11333 13.8333 9.33333Z" fill="white" stroke="#292D32" strokeOpacity="0.64" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 5.8335V9.16683" stroke="#292D32" strokeOpacity="0.64" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6 1.8335H10" stroke="#292D32" strokeOpacity="0.64" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div>
                            {value.duration}
                        </div>
                    </div>
                </td>
                <td>
                    {value.campaignLastUpdate}
                </td>
                <td>
                    {value.branchName}
                </td>
                <td>
                    {value.deviceName}
                </td>
                <td>
                    <p className='name'>
                        {value.name}
                    </p>
                    <p className='email'>
                        {value.email}
                    </p>
                </td>
            </tr>
        )
    }

    function download() {
        ApiService.initFormData()
        ApiService.getFile(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-report-download').then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Skrin Report.xlsx')
            document.body.appendChild(link)
            link.click()
        });
    }

    return (
        <div className="w-full h-min-screen h-fit campaign">
            <div className='top-content'>
                <Navbar />
                <div className='content mb-[50px]'>
                    <div className='header flex flex-wrap'>
                        <div className='basis-4/12 title'>
                            <h3>Laporan</h3>
                        </div>
                        <div className='basis-4/12 search flex items-center'>
                            <div className='w-full h-full'>
                                <input type="text" placeholder='CARI' className='search h-full bg-transparent px-[10px] w-full' value={search} onChange={(e) => searchData(e.target.value)} />
                            </div>
                        </div>
                        <div className='basis-4/12 action flex flex-wrap items-center justify-end gap-x-[24px]'>
                            {/* <button className='btn btn-view' onClick={updateDisplay.bind(this)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button> */}
                            <button className='btn gap-x-[12px]' onClick={download.bind(this)}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.6665 6.99984C1.6665 4.06181 4.06181 1.6665 6.99984 1.6665C9.93786 1.6665 12.3332 4.06181 12.3332 6.99984C12.3332 9.93786 9.93786 12.3332 6.99984 12.3332C4.06181 12.3332 1.6665 9.93786 1.6665 6.99984ZM7.93734 9.33317V7.93734H9.33317C9.84848 7.93734 10.2707 7.51515 10.2707 6.99984C10.2707 6.48453 9.84848 6.06234 9.33317 6.06234H7.93734V4.6665C7.93734 4.15119 7.51515 3.729 6.99984 3.729C6.48453 3.729 6.06234 4.15119 6.06234 4.6665V6.06234H4.6665C4.15119 6.06234 3.729 6.48453 3.729 6.99984C3.729 7.51515 4.15119 7.93734 4.6665 7.93734H6.06234V9.33317C6.06234 9.84848 6.48453 10.2707 6.99984 10.2707C7.51515 10.2707 7.93734 9.84848 7.93734 9.33317Z" fill="white" stroke="white" />
                                </svg>
                                Unduh Laporan
                            </button>
                        </div>
                    </div>
                    <div className='cabang-table'>
                        <table className='table w-full'>
                            <thead>
                                <tr>
                                    <th>NAMA</th>
                                    <th>DURASI</th>
                                    <th>TERAKHIR DIUBAH</th>
                                    <th>CABANG</th>
                                    <th>PERANGKAT</th>
                                    <th className='status-col'>USER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(isAction) 
                                    ? (
                                        <LoadingTable colSpan="6"/>
                                    ) 
                                    : reportList
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>
                                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.1108 6.09337C16.1508 5.80004 13.1731 5.64893 10.2042 5.64893C8.44417 5.64893 6.68417 5.73781 4.92417 5.91559L3.11084 6.09337" fill="#96A0AA" />
                                            <path d="M19.1108 6.09337C16.1508 5.80004 13.1731 5.64893 10.2042 5.64893C8.44417 5.64893 6.68417 5.73781 4.92417 5.91559L3.11084 6.09337" stroke="#96A0AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8 5.19566L8.19556 4.03122C8.33778 3.18678 8.44444 2.55566 9.94667 2.55566H12.2756C13.7778 2.55566 13.8933 3.22233 14.0267 4.04011L14.2222 5.19566" fill="#96A0AA" />
                                            <path d="M8 5.19566L8.19556 4.03122C8.33778 3.18678 8.44444 2.55566 9.94667 2.55566H12.2756C13.7778 2.55566 13.8933 3.22233 14.0267 4.04011L14.2222 5.19566" stroke="#96A0AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M17.1998 8.90234L16.622 17.8535C16.5242 19.249 16.4442 20.3335 13.9642 20.3335H8.25753C5.77753 20.3335 5.69753 19.249 5.59975 17.8535L5.02197 8.90234" stroke="#96A0AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.62646 15.4443H12.5865" stroke="#96A0AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.88867 11.8892H13.3331" stroke="#96A0AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th className="flex flex-wrap items-center">
                                        <div className='basis-6/12 pl-[5%]'>
                                            Baris per halaman:
                                            <select className="select w-fit m-0" value={perPage} onChange={(e) => updatePerPage(e.target.value)}>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                                <option>40</option>
                                                <option>50</option>
                                            </select>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='basis-6/12 flex justify-end items-center'>
                                            <div>
                                                {paginate.from}-{paginate.to} dari {paginate.totalPage}
                                            </div>
                                            <div>
                                                <div className="w-[24px]">
                                                    <button className={paginate.prevPageUrl === null ? 'hidden' : 'btn btn-transparent'} onClick={prevPage.bind(this)} >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.9802 5.32024L10.7702 8.53024L8.80023 10.4902C7.97023 11.3202 7.97023 12.6702 8.80023 13.5002L13.9802 18.6802C14.6602 19.3602 15.8202 18.8702 15.8202 17.9202V12.3102V6.08024C15.8202 5.12024 14.6602 4.64024 13.9802 5.32024Z" fill="#3375F5" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <button className={paginate.nextPageUrl === null ? 'hidden' : 'btn btn-transparent'} onClick={nextPage.bind(this)}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.2002 10.4899L13.2302 8.51993L10.0202 5.30993C9.34018 4.63993 8.18018 5.11993 8.18018 6.07993V12.3099V17.9199C8.18018 18.8799 9.34018 19.3599 10.0202 18.6799L15.2002 13.4999C16.0302 12.6799 16.0302 11.3199 15.2002 10.4899Z" fill="#3375F5" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
