import React from "react";
export default function RunningTextPopup(props) {
    return (
        <div className={props.isRunningTextOn === true ? ' running-text-popup' : 'hidden'}>
            <div className='title flex flex-wrap items-center'>
                <div className='basis-10/12'>
                    <h3>Tambah Teks</h3>
                </div>
                <div className='basis-2/12 flex justify-end'>
                    <button className='btn btn-transparent mr-0' onClick={props.togglePopup.bind(this, 'running')}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z" fill="black" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className='content relative'>
                <div className=''>
                    <label htmlFor="teks">Teks</label>
                    <textarea name="teks" value={props.runningText.teks} onChange={(e) => props.updateData('running', 'teks', e.target.value)}></textarea>
                </div>
                <div className='grid grid-cols-12 gap-x-[1vw]'>
                    <div className='col-span-3'>
                        <label htmlFor="ukuranJudul">Ukuran Judul</label>
                        <input type="number" value={props.runningText.fontSize} onChange={(e) => props.updateData('running', 'fontSize', e.target.value)} name="ukuranJudul" className="w-full" placeholder='24' />
                    </div>
                    <div className='col-span-6'>
                        <label htmlFor="fontJudul">Font Judul</label>
                        <select className="select w-full" value={props.runningText.fontFamily} onChange={(e) => props.updateData('running', 'fontFamily', e.target.value)}>
                            <option disabled >Pilih Font</option>
                            <option>Arial</option>
                            <option>Times New Roman</option>
                            <option>Sans Serif</option>
                            <option>Helvetica</option>
                            <option>Gotham</option>
                        </select>
                    </div>
                    <div className='col-span-3'>
                        <label htmlFor="teksColor">Warna Font</label>
                        <input type="color" name="teksColor" value={props.runningText.teksColor} onChange={(e) => props.updateData('running', 'teksColor', e.target.value)} />
                    </div>
                </div>
                <div className='w-full'>
                    <label htmlFor="bgColor">Background</label>
                    <input type="color" name="bgColor" value={props.runningText.bgColor} onChange={(e) => props.updateData('running', 'bgColor', e.target.value)} />
                </div>
                <div className=''>
                    <label htmlFor="runningSpeed">Kecepatan Running Teks</label>
                    <input type="number" value={props.runningText.runningSpeed} onChange={(e) => props.updateData('running', 'runningSpeed', e.target.value)} name="runningSpeed" className="w-full" placeholder='20' />
                </div>
                <div className='flex justify-end absolute bottom-0 right-[32px]'>
                    <button className='btn upercase' disabled={props.runningText.teks === '' || props.runningText.fontSize == "" ? true : false} onClick={props.togglePopup.bind(this, 'running')}>simpan teks</button>
                </div>
            </div>
        </div>
    );
}