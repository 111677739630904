import '../../view-template1-grid.css';
import React from 'react'
import Slider from "react-slick";
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Close from '../../components/icon/Close'

export default function Preview6(props) {

  function RenderMedia() {
    if (props.images.length != 0) {
      let slideValue = props.images
      if (slideValue.length > 1) {
        let slider = []
        slideValue.forEach(function (value, index) {
          slider.push(
            <div key={index} className="max-w-full w-[100%] max-h-[100%] h-[100%]">
              <img alt="SliderImage" className="max-w-full w-[100%] max-h-[100%] h-[100%] object-cover" src={value.src} />
            </div>
          )
        })

        var settings = {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: props.duration * 1000,
          cssEase: "linear"
        };
        return (
          <Slider {...settings}>
            {slider}
          </Slider>
        );
      } else {
        return (
          <div className="max-w-full w-[100%] h-[100%] max-h-[100%] ">
            <img alt="SliderImage" className="max-w-full w-[100%] h-[100%] max-h-[100%] object-cover" src={props.images[0].src} />
          </div>
        )
      }
    }
  }

  function RenderRunningText() {
    return (
      <div id="scroll-container" className='flex items-center h-[100%]'>
        <p id="scroll-text" className={`text-[` + props.runningText.fontFamily + `] text-[` + props.runningText.fontSize + `px]`}>
          {props.runningText.teks}
        </p>
      </div>
    )
  }

  return (
    <div className={props.preview === true ? "template template-preview gap-[20px] gap-y-[20px] fixed top-0 left-0 w-full h-full overflow-hidden" : "hidden"}>
      <div className='absolute top-[0] right-[0]'>
        <button onClick={props.changePreview.bind(this)}>
          <Close />
        </button>
      </div>
      <div className='h-[87vh] max-h-[87vh] w-full bg-[#FFFFFF]'>
        <RenderMedia />
      </div>
      <div className='mt-[20px] w-full bg-[#FFFFFF] h-[5vh]'>
        <RenderRunningText />
      </div>
    </div>
  )
}