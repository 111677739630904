import { toastSuccess, toastError } from '../../components/toast';

export function validateCampaign(data) {
  // console.log(isValueNotEmpty(data.values[0]))
  // console.log(data.values.every(isValueNotEmpty))

  if (!data.values.every(isValueNotEmpty)) {
    toastError('Silahkan isi semua data')
    return false;
  } else {
    if (data.campaignName === '') {
      toastError('Silahkan isi nama campaign')
      return false;
    } else {
      if (data.devices.length === 0) {
        toastError('Silahkan pilih device')
        return false;
      } else {
        return true;
      }
    }
  }

}

function isValueNotEmpty(value) {
  return value !== '' && value !== 0;
}