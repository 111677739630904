import React from "react";
import Profile from '../../assets/images/profile/profile.png'
import HeaderDropdown from "../../components/menu/HeaderDropdown"

export default function Navbar() {
  return (
    <div className="navbar flex flex-wrap justify-end">
      <div className="menu !bg-[transparent] !border-[none] !outline-[none] !shadow-none">
        <div className="">
          <div className="flex justify-center">
            <a href={process.env.REACT_APP_AWD_DOMAIN+'/dashboard'}>
              <button className="btn btn-transparent btn-menu">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                > 
                  <path
                    d="M20.0001 5.85014C20.0001 7.20876 18.8987 8.31014 17.5401 8.31014C16.1815 8.31014 15.0801 7.20876 15.0801 5.85014C15.0801 4.49152 16.1815 3.39014 17.5401 3.39014C18.8987 3.39014 20.0001 4.49152 20.0001 5.85014Z"
                    fill="#3375F5"
                    stroke="#3375F5"
                  />
                  <path
                    d="M8.92 5.85014C8.92 7.20876 7.81862 8.31014 6.46 8.31014C5.10138 8.31014 4 7.20876 4 5.85014C4 4.49152 5.10138 3.39014 6.46 3.39014C7.81862 3.39014 8.92 4.49152 8.92 5.85014Z"
                    fill="#3375F5"
                    stroke="#3375F5"
                  />
                  <path
                    d="M20.0001 18.1499C20.0001 19.5086 18.8987 20.6099 17.5401 20.6099C16.1815 20.6099 15.0801 19.5086 15.0801 18.1499C15.0801 16.7913 16.1815 15.6899 17.5401 15.6899C18.8987 15.6899 20.0001 16.7913 20.0001 18.1499Z"
                    fill="#3375F5"
                    stroke="#3375F5"
                  />
                  <path
                    d="M6.46 21.1099C8.09476 21.1099 9.42 19.7847 9.42 18.1499C9.42 16.5152 8.09476 15.1899 6.46 15.1899C4.82524 15.1899 3.5 16.5152 3.5 18.1499C3.5 19.7847 4.82524 21.1099 6.46 21.1099Z"
                    fill="#3375F5"
                  />
                </svg>
              </button>
            </a>
            <HeaderDropdown/>
          </div>
        </div>
      </div>
    </div>
  );
}