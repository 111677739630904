import React from "react";

export default function ToastInfo() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="6" fill="url(#paint0_linear_103_82)"/>
        <path d="M12 16V11" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_103_82" x1="12" y1="-4.5" x2="12" y2="28" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4DCAFF"/>
        <stop offset="1" stop-color="#4EA3E0"/>
        </linearGradient>
        </defs>
        </svg>
    );
}