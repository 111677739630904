import React from "react";
import { useState } from 'react';
import Profile from "../../assets/images/profile/profile.png"
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

export default function HeaderDropdown() {
	const [isDropdownOpen, setDropdownOpen] = useState(false);

	  const toggleDropdown = () => {
	    setDropdownOpen(!isDropdownOpen);
	  };

	return (
        <>
	        <div className="relative">
	          <div className="flex justify-center">
	            <button
	              className="btn btn-transparent btn-profile"
	              onClick={toggleDropdown}
	            >
	            	<Avatar
		              color={Avatar.getRandomColor("sitebase", [
		                "red",
		                "green",
		                "blue",
		              ])}
		                name={localStorage.getItem('name')}
		                className="w-8 h-8 rounded-full"
		                size="30"
		              />
	            </button>
	          </div>

	          {isDropdownOpen && (
				<div className="absolute top-10 right-0 bg-white border border-gray-300 shadow-md rounded-md">
			      <ul className="w-full">
          			<Link to="/update-profile">
				        <li>
				        	<span className="py-4 bg-white hover:bg-gray-100 hover:text-dark !focus:text-dark w-40">
				        		Lihat Profil
				        	</span>
				        </li>
          			</Link>
          			<Link to="/update-password">
				        <li>
				        	<span className="py-4 bg-white hover:bg-gray-100 hover:text-dark !focus:text-dark w-40">
				        		Ubah Password
				        	</span>
				        </li>
          			</Link>
			      </ul>
			    </div>
		    	)}
	        </div>
        </>
	)
}