import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../components/menu/Navbar'
import Frame1 from '../../components/frame/Frame1'
import Frame2 from '../../components/frame/Frame2'
import Frame3 from '../../components/frame/Frame3'
import Frame5 from '../../components/frame/Frame5'
import Frame4 from '../../components/frame/Frame4'
import Frame6 from '../../components/frame/Frame6'
import Frame7 from '../../components/frame/Frame7'
import Frame8 from '../../components/frame/Frame8'
import Frame9 from '../../components/frame/Frame9'
import Frame10 from '../../components/frame/Frame10'

export default function Detail() {
  return (
    <div className="w-full h-min-screen h-fit campaign-template">
      <div className="top-content">
        <Navbar />
      </div>
      <div className="content mb-[50px]">
        <div className="header flex flex-wrap">
          <div className="basis-4/12 title ">
            <Link to="/campaign" className="flex items-center gap-x-[4px]">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.165 6.91846L4.08337 14.0001L11.165 21.0818"
                  stroke="#1A67FB"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.9166 14H4.28162"
                  stroke="#1A67FB"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h3>Template</h3>
            </Link>
          </div>
        </div>

        <div className="template-list grid grid-cols-3 gap-[24px]">
          {/*<Link to="/campaign/template/1">
            <div className="col-span-1">
              <Frame1 />
            </div>
          </Link>*/}
          <Link to="/campaign/template/2">
            <div className="col-span-1">
              <Frame2 />
            </div>
          </Link>
          {/*<Link to="/campaign/template/3">
            <div className="col-span-1">
              <Frame3 />
            </div>
          </Link>*/}
          <Link to="/campaign/template/4">
            <div className="col-span-1">
              <Frame4 />
            </div>
          </Link>
          <Link to="/campaign/template/5">
            <div className="col-span-1">
              <Frame6 />
            </div>
          </Link>
          <Link to="/campaign/template/6">
            <div className="col-span-1">
              <Frame5 />
            </div>
          </Link>
          {/*<Link to="/campaign/template/7">
            <div className="col-span-1">
              <Frame7 />
            </div>
          </Link>*/}
          {/*<Link to="/campaign/template/8">
            <div className="col-span-1">
              <Frame8 />
            </div>
          </Link>*/}
          <Link to="/campaign/template/9">
            <div className="col-span-1">
              <Frame9 />
            </div>
          </Link>
          <Link to="/campaign/template/10">
            <div className="col-span-1">
              <Frame10 />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
