import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import CreateBranch from '../../components/cabang/CreateBranch'
import { Link } from 'react-router-dom'
import Add from '../../components/icon/Add'
import Grid from '../../components/icon/Grid'
import Navbar from '../../components/menu/Navbar'
import Branch from '../../components/icon/Branch'
import Dot from '../../components/icon/Dot'
import Pencil from '../../components/icon/Pencil'
import Trash from '../../components/icon/Trash'
import TrashGray from '../../components/icon/TrashGray'
import Close from '../../components/icon/Close'
import ShimmerLoading from '../../components/shimmerLoading';
import LoadingTable from '../../components/LoadingTable'

export default function Index() {
  const [display, setDisplay] = useState('list')
  const [toggleAddCabang, setToggleAddCabang] = useState(false)
  const [provinsi, setProvinsi] = useState(11)
  const [kota, setKota] = useState(1101)
  const [kecamatan, setKecamatan] = useState(1101010)
  const [listProvinsi, setListProvinsi] = useState([])
  const [listKota, setListKota] = useState([])
  const [listKecamatan, setListKecamatan] = useState([])
  const [isToggleDelete, setIstToggleDelete] = useState([])
  const [deleteId, setDeleteId] = useState()
  const [isFetchingData, setIsFetchingData] = useState(false)
  const [branch, setBranch] = useState({
    name: '',
    province: 'Aceh',
    city: 'Kabupaten Simeuleu',
    district: 'Teupah Selatah',
    address: '',
  })
  const [branchList, setBranchList] = useState([])
  const [paginate, setPaginate] = useState({
    'firstPage': 1,
    'lastPage': 1,
    'totalPage': 1,
    'activePage': 1,
    'from': 0,
    'to': 0
  })
  const [search, setSearch] = useState('')
  const pronvisiOption = []
  const kotaOption = []
  const kecamatanOption = []
  let gridBranchList = []
  let tableBranchList = []

  useEffect(() => {
    setIsFetchingData(true)

    fetch('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json')
      .then((response) => response.json())
      .then((province) => {
        setListProvinsi([...province])
      })

    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/11.json`)
      .then((response) => response.json())
      .then((city) => {
        setListKota([...city])
      })

    fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/1101.json`)
      .then((response) => response.json())
      .then((districts) => {
        setListKecamatan([...districts])
      })

    let perPage = 10;
    if (display === 'list') {
      perPage = 9;
    }
    ApiService.init()
    ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch?per_page=' + perPage)
      .then((response) => {
        setBranchList([...response.data.data])

        let paginateData = {
          'firstPage': 1,
          'lastPage': response.data.lastPage,
          'totalPage': response.data.total,
          'activePage': 1,
          'from': response.data.from,
          'to': response.data.to
        }

        setPaginate({ ...paginateData })
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
      .finally(() => {
        setIsFetchingData(false)
      })
  }, [display])


  function updateDisplay() {
    if (display === 'table') {
      setDisplay('list')
    } else {
      setDisplay('table')
    }
  }

  function toggleCabang() {
    setToggleAddCabang(!toggleAddCabang)
  }

  function changeListKota(e) {

    setProvinsi(e.target.value)

    fetch(
      `https://www.emsifa.com/api-wilayah-indonesia/api/regencies/` +
      e.target.value +
      `.json`,
    )
      .then((response) => response.json())
      .then((city) => {
        setListKota([...city])
        updateData('city', city[0].name)
        setKota(city[0].id)

        fetch(
          `https://www.emsifa.com/api-wilayah-indonesia/api/districts/` +
          city[0].id +
          `.json`,
        )
          .then((response) => response.json())
          .then((districts) => {
            setListKecamatan([...districts])
            setKecamatan(districts[0].id)
            updateData('district', districts[0].name)
          })
      })
  }


  function changeBranchKecamatan(id) {
    setKecamatan(id)
    // fetch(
    //   `https://www.emsifa.com/api-wilayah-indonesia/api/district/` +
    //   id +
    //   `.json`,
    // )
    //   .then((response) => response.json())
    //   .then((kecamatan) => {
    //     updateData('district', kecamatan.name)
    //   })
  }

  function updateData(field, newValue) {
    let newBranch = { ...branch }
    newBranch[field] = newValue
    setBranch({ ...newBranch })
  }

  function toggleDelete(value) {
    if (isToggleDelete === true) {
      setIstToggleDelete(false)
    } else {
      setDeleteId(value)
      setIstToggleDelete(true)
    }
  }

  function changeListKecamatan(e) {
    setKota(e.target.value)
    fetch(
      `https://www.emsifa.com/api-wilayah-indonesia/api/districts/` +
      e.target.value +
      `.json`,
    )
      .then((response) => response.json())
      .then((districts) => {
        setListKecamatan([...districts])
        setKecamatan(districts[0].id)
      })
  }

  function deleteBranch() {
    ApiService.init()
    ApiService.delete(
      process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch/' + deleteId,
    )
      .then((response) => {
        window.location = '/cabang'
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function searchData(value) {
    setSearch(value)
    let perPage = 10;
    if (display === 'list') {
      perPage = 9;
    }
    ApiService.init()
    ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch?per_page=' + perPage + '&search=' + value)
      .then((response) => {
        setBranchList([...response.data.data])

        let paginateData = {
          'firstPage': 1,
          'lastPage': response.data.lastPage,
          'totalPage': response.data.total,
          'activePage': 1,
          'from': response.data.from,
          'to': response.data.to
        }

        setPaginate({ ...paginateData })
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function goTo(page) {

    let newPaginate = { ...paginate }
    newPaginate['acitve'] = page;
    setPaginate({ ...newPaginate })

    let perPage = 10;
    if (display === 'list') {
      perPage = 9;
    }

    ApiService.init()
    ApiService.get(
      process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch?page=' + page + '&per_page=' + perPage,
    )
      .then((response) => {
        setBranchList([...response.data.data])

        let paginateData = {
          'firstPage': 1,
          'lastPage': response.data.lastPage,
          'totalPage': response.data.total,
          'activePage': 1,
          'from': response.data.from,
          'to': response.data.to
        }

        setPaginate({ ...paginateData })
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function Pagination() {
    let page = []
    for (let i = 0; i < paginate.lastPage; i++) {
      page.push(
        <button
          className={
            paginate.activePage !== i + 1 ? 'btn btn-transparent' : 'btn active'
          }
          onClick={goTo.bind(this, i + 1)}
          key={'page-' + i}
        >
          {i + 1}
        </button>,
      )
    }
    return page
  }

  for (const [index, item] of listProvinsi.entries()) {
    pronvisiOption.push(
      <option value={item.id} key={'provinsi' + index}>
        {item.name}
      </option>,
    )
  }

  for (const [index, item] of listKota.entries()) {
    kotaOption.push(
      <option value={item.id} key={'kota' + index}>
        {item.name}
      </option>,
    )
  }

  for (const [index, value] of branchList.entries()) {
    gridBranchList.push(
      <div className="col-span-1 item" key={'grid-' + index}>
        <div className="flex flex-wrap action">
          <div className="basis-6/12">
            <Branch />
          </div>
          <div className="basis-6/12 flex justify-end">
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="cursor-pointer bg-[gray]">
                <div className="w-10 rounded-full flex justify-end">
                  <Dot />
                </div>
              </label>
              <ul
                tabIndex={0}
                className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-[113px]"
              >
                <li className="border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]">
                  <Link
                    to={'/cabang/detail/' + value.id}
                    className="flex flex-wrap justify-between"
                  >
                    <div className="">Ubah</div>
                    <div className="">
                      <Pencil />
                    </div>
                  </Link>
                </li>
                <li className="pt-[8px]">
                  <div
                    className="flex flex-wrap justify-between"
                    onClick={toggleDelete.bind(this, value.id)}
                  >
                    <div>Hapus</div>
                    <div>
                      <Trash />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h3>{value.name}</h3>
          <h5>
            {value.province}, {value.city}, {value.district}
          </h5>
          <p>{value.address}</p>
        </div>
      </div>,
    )
  }

  for (const [index, value] of branchList.entries()) {
    tableBranchList.push(
      <tr key={'table-cabang-' + index}>
        <td>{value.name}</td>
        <td>{value.province}</td>
        <td>{value.city}</td>
        <td>{value.district}</td>
        <td className="address">{value.district}</td>
        <td>
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="cursor-pointer bg-[gray]">
              <div className="w-10 rounded-full flex justify-end">
                <Dot />
              </div>
            </label>
            <ul
              tabIndex={0}
              className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-[113px]"
            >
              <li className="border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]">
                <Link
                  to={'/cabang/detail/' + value.id}
                  className="flex flex-wrap justify-between"
                >
                  <div className="">Ubah</div>
                  <div className="">
                    <Pencil />
                  </div>
                </Link>
              </li>
              <li className="pt-[8px]">
                <div
                  className="flex flex-wrap justify-between"
                  onClick={toggleDelete.bind(this)}
                >
                  <div>Hapus</div>
                  <div>
                    <Trash />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    )
  }

  for (const [index, item] of listKecamatan.entries()) {
    kecamatanOption.push(
      <option value={item.id} key={'kecamatan' + index}>
        {item.name}
      </option>,
    )
  }

  function generateButton() {
    if (!isFetchingData && branchList.length < localStorage.getItem('branch')) {
      return (
        <button
          className="btn gap-x-[12px]"
          onClick={toggleCabang.bind(this)}
        >
          <Add />
          Tambah Cabang
        </button>
      )
    }
  }

  return (
    <div className="w-full min-h-screen h-fit bg-white cabang">
      <div className="top-content">
        <Navbar />
        <div className="content">
          <div className="header flex flex-wrap">
            <div className="basis-4/12 title">
              <h3>PERANGKAT</h3>
            </div>
            <div className="basis-4/12 search flex items-center">
              <div className='w-full h-full'>
                <input type="text" placeholder='CARI' className='search h-full bg-transparent px-[10px] w-full' value={search} onChange={(e) => searchData(e.target.value)} />
              </div>
            </div>
            <div className="basis-4/12 action flex flex-wrap items-center justify-end gap-x-[24px] pl-[24px]">
              <button
                className="btn btn-view"
                onClick={updateDisplay.bind(this)}
              >
                <Grid />
              </button>
              {generateButton()}
            </div>
          </div>
          <div className="cabang-table">
            <table className={display === 'table' ? 'table w-full' : 'hidden'}>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Provinsi</th>
                  <th>Kota</th>
                  <th>Kecamatan</th>
                  <th>Alamat</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(isFetchingData) 
                    ? (
                        <LoadingTable colSpan="6"/>
                    ) 
                    : tableBranchList
                }
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan="4">
                    <TrashGray />
                  </th>
                  <th colSpan="2" className="flex flex-wrap items-center">
                    <div className="basis-6/12">
                      Baris per halaman:
                      <select className="select w-fit m-0">
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                      </select>
                    </div>
                    <div className="basis-6/12 flex justify-end items-center">
                      <div>{paginate.from}-{paginate.to} dari {paginate.totalPage}</div>
                      <div>
                        <button className="btn btn-transparent">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.9802 5.32024L10.7702 8.53024L8.80023 10.4902C7.97023 11.3202 7.97023 12.6702 8.80023 13.5002L13.9802 18.6802C14.6602 19.3602 15.8202 18.8702 15.8202 17.9202V12.3102V6.08024C15.8202 5.12024 14.6602 4.64024 13.9802 5.32024Z"
                              fill="#3375F5"
                            />
                          </svg>
                        </button>
                      </div>
                      <div>
                        <button className="btn btn-transparent">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2002 10.4899L13.2302 8.51993L10.0202 5.30993C9.34018 4.63993 8.18018 5.11993 8.18018 6.07993V12.3099V17.9199C8.18018 18.8799 9.34018 19.3599 10.0202 18.6799L15.2002 13.4999C16.0302 12.6799 16.0302 11.3199 15.2002 10.4899Z"
                              fill="#3375F5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
            <div className={display === 'list' ? 'w-full ' : 'hidden'}>
            {isFetchingData ? (
                <div className="grid grid-cols-3 list gap-[24px]">
                  <div className="h-[186px]">
                    <ShimmerLoading/>
                  </div>
                  <div className="h-[186px]">
                    <ShimmerLoading/>
                  </div>
                  <div className="h-[186px]">
                    <ShimmerLoading/>
                  </div>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-3 list gap-[24px]">
                    {gridBranchList}
                  </div>
                  <div className="w-full flex justiy-center">
                    <div className="btn-group mx-auto">
                      <Pagination />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CreateBranch
        toggleAddCabang={toggleAddCabang}
        toggleCabang={toggleCabang}
        branch={branch}
        provinsi={provinsi}
        kota={kota}
        kecamatan={kecamatan}
        pronvisiOption={pronvisiOption}
        kecamatanOption={kecamatanOption}
        kotaOption={kotaOption}
        updateData={updateData}
        changeListKota={changeListKota}
        changeListKecamatan={changeListKecamatan}
        changeBranchKecamatan={changeBranchKecamatan}
      />
      <div
        className={
          isToggleDelete === true
            ? 'delete-popup fixed bg-[rgba(41,45,50,0.5)] top-[0] h-[100vh] w-[100vw] -ml-[16rem] flex justify-center'
            : '!hidden'
        }
        id="deleteForm"
      >
        <div className="">
          <div className="border-b-[0.5px] border-[#D9D9D9]">
            <div className="flex justify-between px-[32px] items-center !h-[80px]">
              <h3>Hapus Cabang</h3>
              <button
                className="btn btn-transparent !p-0"
                onClick={toggleDelete.bind(this)}
              >
                <Close />
              </button>
            </div>
          </div>
          <div className="px-[32px]">
            <h4>Apakah Anda yakin akan menghapus campaign?</h4>
            <div className="grid grid-cols-2 gap-x-[26px]">
              <button className="btn-outline" onClick={toggleDelete.bind(this)}>
                BATAL
              </button>
              <button className="btn" onClick={deleteBranch.bind(this)}>
                HAPUS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
