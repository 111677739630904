import React from 'react'

export default function Html() {
  return (
    <svg
      width="57"
      height="16"
      viewBox="0 0 57 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V4.66634C2 2.66634 3 1.33301 5.33333 1.33301H10.6667C13 1.33301 14 2.66634 14 4.66634Z"
        stroke="#3375F5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332"
        stroke="#3375F5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66683 8.66699L5.3335 10.0003L6.66683 11.3337"
        stroke="#3375F5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 8.66699L10.6668 10.0003L9.3335 11.3337"
        stroke="#3375F5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8608 13V4.27273H22.4418V7.96733H26.4858V4.27273H28.071V13H26.4858V9.29261H22.4418V13H20.8608ZM30.007 5.59801V4.27273H36.97V5.59801H34.2726V13H32.7044V5.59801H30.007ZM38.9124 4.27273H40.847L43.4379 10.5966H43.5402L46.1311 4.27273H48.0658V13H46.5487V7.00426H46.4678L44.0558 12.9744H42.9223L40.5104 6.99148H40.4294V13H38.9124V4.27273ZM50.3874 13V4.27273H51.9683V11.6747H55.8121V13H50.3874Z"
        fill="#3375F5"
      />
    </svg>
  )
}
