import React, { useState } from "react";
import Skrin from "../../assets/images/logo/skrin.png"
import { Link } from 'react-router-dom'
import ApiService from '../../services/api.service'
import JwtService from '../../services/jwt.service'

export default function Sidebar() {
    const [path, setPath] = useState(window.location.pathname);

    function updatePath(newPath) {
        setPath(newPath)
    }

    function AdminSidebar() {
        return (
            <aside className="w-64 fixed h-screen bg-white border-r-[1px] border-[#EFECEC]" aria-label="Sidebar">
                <div className="overflow-y-auto py-4 px-3 bg-white rounded relative h-screen">
                    <ul className="border-b-[1px] border-[#EFECEC]">
                        <img src={Skrin} className="mx-auto my-[24.5px]" alt="" />
                    </ul>
                    <ul className="space-y-2">
                        <li>
                            <Link to="/" onClick={updatePath.bind(this, '/')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5913 10.6409L12.2013 14.8409C11.3013 15.5409 10.5713 17.0309 10.5713 18.1609V25.5709C10.5713 27.8909 12.4613 29.7909 14.7813 29.7909H26.3613C28.6813 29.7909 30.5713 27.8909 30.5713 25.5809V18.3009C30.5713 17.0909 29.7613 15.5409 28.7713 14.8509L22.5913 10.5209C21.1913 9.54094 18.9413 9.59094 17.5913 10.6409Z" stroke={path === '/' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19.0713 25.8008H22.0713C23.7213 25.8008 25.0713 24.4508 25.0713 22.8008V19.8008C25.0713 18.1508 23.7213 16.8008 22.0713 16.8008H19.0713C17.4213 16.8008 16.0713 18.1508 16.0713 19.8008V22.8008C16.0713 24.4508 17.4213 25.8008 19.0713 25.8008Z" stroke={path === '/' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5713 16.8008V25.8008" stroke={path === '/' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.0713 21.3008H25.0713" stroke={path === '/' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/cabang" onClick={updatePath.bind(this, '/cabang')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5811 19.0208V23.5108C11.5811 28.0008 13.3811 29.8008 17.8711 29.8008H23.2611C27.7511 29.8008 29.5511 28.0008 29.5511 23.5108V19.0208" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5713 19.8008C22.4013 19.8008 23.7513 18.3108 23.5713 16.4808L22.9113 9.80078H18.2413L17.5713 16.4808C17.3913 18.3108 18.7413 19.8008 20.5713 19.8008Z" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M26.8811 19.8008C28.9011 19.8008 30.3811 18.1608 30.1811 16.1508L29.9011 13.4008C29.5411 10.8008 28.5411 9.80078 25.9211 9.80078H22.8711L23.5711 16.8108C23.7411 18.4608 25.2311 19.8008 26.8811 19.8008Z" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.2112 19.8008C15.8612 19.8008 17.3512 18.4608 17.5112 16.8108L17.7312 14.6008L18.2112 9.80078H15.1612C12.5412 9.80078 11.5412 10.8008 11.1812 13.4008L10.9112 16.1508C10.7112 18.1608 12.1912 19.8008 14.2112 19.8008Z" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5713 24.8008C18.9013 24.8008 18.0713 25.6308 18.0713 27.3008V29.8008H23.0713V27.3008C23.0713 25.6308 22.2413 24.8008 20.5713 24.8008Z" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path.includes('/cabang') === true ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Cabang</span>
                            </Link>
                        </li>
                        {/*<li>
                            <Link to="/ruang-meeting" onClick={updatePath.bind(this, '/ruang-meeting')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.5714 14.9608C26.5114 14.9508 26.4414 14.9508 26.3814 14.9608C25.0014 14.9108 23.9014 13.7808 23.9014 12.3808C23.9014 10.9508 25.0514 9.80078 26.4814 9.80078C27.9114 9.80078 29.0614 10.9608 29.0614 12.3808C29.0514 13.7808 27.9514 14.9108 26.5714 14.9608Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M25.5412 22.2407C26.9112 22.4707 28.4212 22.2307 29.4812 21.5207C30.8912 20.5807 30.8912 19.0407 29.4812 18.1007C28.4112 17.3907 26.8812 17.1507 25.5112 17.3907" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.5413 14.9608C14.6013 14.9508 14.6713 14.9508 14.7313 14.9608C16.1113 14.9108 17.2113 13.7808 17.2113 12.3808C17.2113 10.9508 16.0613 9.80078 14.6313 9.80078C13.2013 9.80078 12.0513 10.9608 12.0513 12.3808C12.0613 13.7808 13.1613 14.9108 14.5413 14.9608Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.5712 22.2407C14.2012 22.4707 12.6912 22.2307 11.6312 21.5207C10.2212 20.5807 10.2212 19.0407 11.6312 18.1007C12.7012 17.3907 14.2312 17.1507 15.6012 17.3907" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5714 22.4308C20.5114 22.4208 20.4414 22.4208 20.3814 22.4308C19.0014 22.3808 17.9014 21.2508 17.9014 19.8508C17.9014 18.4208 19.0514 17.2708 20.4814 17.2708C21.9114 17.2708 23.0614 18.4308 23.0614 19.8508C23.0514 21.2508 21.9514 22.3908 20.5714 22.4308Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.6615 25.5806C16.2515 26.5206 16.2515 28.0606 17.6615 29.0006C19.2615 30.0706 21.8815 30.0706 23.4815 29.0006C24.8915 28.0606 24.8915 26.5206 23.4815 25.5806C21.8915 24.5206 19.2615 24.5206 17.6615 25.5806Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/ruang-meeting' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Ruang Meeting</span>
                            </Link>
                        </li>*/}
                        <li>
                            <Link to="/campaign" onClick={updatePath.bind(this, '/campaign')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5713 29.8008H23.5713C28.5713 29.8008 30.5713 27.8008 30.5713 22.8008V16.8008C30.5713 11.8008 28.5713 9.80078 23.5713 9.80078H17.5713C12.5713 9.80078 10.5713 11.8008 10.5713 16.8008V22.8008C10.5713 27.8008 12.5713 29.8008 17.5713 29.8008Z" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.5713 17.8008C18.6759 17.8008 19.5713 16.9054 19.5713 15.8008C19.5713 14.6962 18.6759 13.8008 17.5713 13.8008C16.4667 13.8008 15.5713 14.6962 15.5713 15.8008C15.5713 16.9054 16.4667 17.8008 17.5713 17.8008Z" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.2412 26.7508L16.1712 23.4408C16.9612 22.9108 18.1012 22.9708 18.8112 23.5808L19.1412 23.8708C19.9212 24.5408 21.1812 24.5408 21.9612 23.8708L26.1212 20.3008C26.9012 19.6308 28.1612 19.6308 28.9412 20.3008L30.5712 21.7008" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/campaign' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Campaign</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/laporan" onClick={updatePath.bind(this, '/laporan')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5713 29.8008H23.5713C28.5713 29.8008 30.5713 27.8008 30.5713 22.8008V16.8008C30.5713 11.8008 28.5713 9.80078 23.5713 9.80078H17.5713C12.5713 9.80078 10.5713 11.8008 10.5713 16.8008V22.8008C10.5713 27.8008 12.5713 29.8008 17.5713 29.8008Z" stroke={path === '/laporan' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.9014 22.2908L18.2814 19.2008C18.6214 18.7608 19.2514 18.6808 19.6914 19.0208L21.5214 20.4608C21.9614 20.8008 22.5914 20.7208 22.9314 20.2908L25.2414 17.3108" stroke={path === '/laporan' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/laporan' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Laporan</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/user" onClick={updatePath.bind(this, '/user')} className={localStorage.getItem('role') === 'Admin' ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700" : "hidden"}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke={path === '/user' ? '#3375F5' : '#ADADAD'} d="M20.5713 19.8008C23.3327 19.8008 25.5713 17.5622 25.5713 14.8008C25.5713 12.0394 23.3327 9.80078 20.5713 9.80078C17.8099 9.80078 15.5713 12.0394 15.5713 14.8008C15.5713 17.5622 17.8099 19.8008 20.5713 19.8008Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path stroke={path === '/user' ? '#3375F5' : '#ADADAD'} d="M29.1614 29.8008C29.1614 25.9308 25.3114 22.8008 20.5714 22.8008C15.8314 22.8008 11.9814 25.9308 11.9814 29.8008" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/user' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>User</span>
                            </Link>
                        </li>
                        {/*<li>
                            <Link to="/update-profile" onClick={updatePath.bind(this, '/update-profile')} className={localStorage.getItem('role') === 'Admin' ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700" : "hidden"}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke={path === '/update-profile' ? '#3375F5' : '#ADADAD'} d="M20.5713 19.8008C23.3327 19.8008 25.5713 17.5622 25.5713 14.8008C25.5713 12.0394 23.3327 9.80078 20.5713 9.80078C17.8099 9.80078 15.5713 12.0394 15.5713 14.8008C15.5713 17.5622 17.8099 19.8008 20.5713 19.8008Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path stroke={path === '/update-profile' ? '#3375F5' : '#ADADAD'} d="M29.1614 29.8008C29.1614 25.9308 25.3114 22.8008 20.5714 22.8008C15.8314 22.8008 11.9814 25.9308 11.9814 29.8008" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/update-profile' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Profil User</span>
                            </Link>
                        </li>*/}
                        {/*<li>
                            <Link to="/update-password" onClick={updatePath.bind(this, '/update-password')} className={localStorage.getItem('role') === 'Admin' ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700" : "hidden"}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke={path === '/update-password' ? '#3375F5' : '#ADADAD'} d="M20.5713 19.8008C23.3327 19.8008 25.5713 17.5622 25.5713 14.8008C25.5713 12.0394 23.3327 9.80078 20.5713 9.80078C17.8099 9.80078 15.5713 12.0394 15.5713 14.8008C15.5713 17.5622 17.8099 19.8008 20.5713 19.8008Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path stroke={path === '/update-password' ? '#3375F5' : '#ADADAD'} d="M29.1614 29.8008C29.1614 25.9308 25.3114 22.8008 20.5714 22.8008C15.8314 22.8008 11.9814 25.9308 11.9814 29.8008" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/update-password' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Ubah Password</span>
                            </Link>
                        </li>*/}
                    </ul>
                    <ul className="absolute bottom-[24px] left-0 right-0 space-y-2 mx-4 cursor-pointer">
                        <li>
                            <div onClick={logout.bind(this)} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.0112 22.4208L28.5712 19.8608L26.0112 17.3008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.3311 19.8608H28.5011" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.3311 27.8008C15.9111 27.8008 12.3311 24.8008 12.3311 19.8008C12.3311 14.8008 15.9111 11.8008 20.3311 11.8008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap text-[#ADADAD]">Keluar</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }
    
    function DesignerSidebar() {
        return (
            <aside className="w-64 fixed h-screen bg-white border-r-[1px] border-[#EFECEC]" aria-label="Sidebar">
                <div className="overflow-y-auto py-4 px-3 bg-white rounded relative h-screen">
                    <ul className="border-b-[1px] border-[#EFECEC]">
                        <img src={Skrin} className="mx-auto my-[24.5px]" alt="" />
                    </ul>
                    <ul className="space-y-2">
                        <li>
                            <Link to="/" onClick={updatePath.bind(this, '/')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5913 10.6409L12.2013 14.8409C11.3013 15.5409 10.5713 17.0309 10.5713 18.1609V25.5709C10.5713 27.8909 12.4613 29.7909 14.7813 29.7909H26.3613C28.6813 29.7909 30.5713 27.8909 30.5713 25.5809V18.3009C30.5713 17.0909 29.7613 15.5409 28.7713 14.8509L22.5913 10.5209C21.1913 9.54094 18.9413 9.59094 17.5913 10.6409Z" stroke={path === '/' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19.0713 25.8008H22.0713C23.7213 25.8008 25.0713 24.4508 25.0713 22.8008V19.8008C25.0713 18.1508 23.7213 16.8008 22.0713 16.8008H19.0713C17.4213 16.8008 16.0713 18.1508 16.0713 19.8008V22.8008C16.0713 24.4508 17.4213 25.8008 19.0713 25.8008Z" stroke={path === '/' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5713 16.8008V25.8008" stroke={path === '/' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.0713 21.3008H25.0713" stroke={path === '/' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Dashboard</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/cabang" onClick={updatePath.bind(this, '/cabang')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5811 19.0208V23.5108C11.5811 28.0008 13.3811 29.8008 17.8711 29.8008H23.2611C27.7511 29.8008 29.5511 28.0008 29.5511 23.5108V19.0208" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5713 19.8008C22.4013 19.8008 23.7513 18.3108 23.5713 16.4808L22.9113 9.80078H18.2413L17.5713 16.4808C17.3913 18.3108 18.7413 19.8008 20.5713 19.8008Z" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M26.8811 19.8008C28.9011 19.8008 30.3811 18.1608 30.1811 16.1508L29.9011 13.4008C29.5411 10.8008 28.5411 9.80078 25.9211 9.80078H22.8711L23.5711 16.8108C23.7411 18.4608 25.2311 19.8008 26.8811 19.8008Z" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.2112 19.8008C15.8612 19.8008 17.3512 18.4608 17.5112 16.8108L17.7312 14.6008L18.2112 9.80078H15.1612C12.5412 9.80078 11.5412 10.8008 11.1812 13.4008L10.9112 16.1508C10.7112 18.1608 12.1912 19.8008 14.2112 19.8008Z" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5713 24.8008C18.9013 24.8008 18.0713 25.6308 18.0713 27.3008V29.8008H23.0713V27.3008C23.0713 25.6308 22.2413 24.8008 20.5713 24.8008Z" stroke={path.includes('/cabang') === true ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path.includes('/cabang') === true ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Cabang</span>
                            </Link>
                        </li> */}
                        {/*<li>
                            <Link to="/ruang-meeting" onClick={updatePath.bind(this, '/ruang-meeting')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.5714 14.9608C26.5114 14.9508 26.4414 14.9508 26.3814 14.9608C25.0014 14.9108 23.9014 13.7808 23.9014 12.3808C23.9014 10.9508 25.0514 9.80078 26.4814 9.80078C27.9114 9.80078 29.0614 10.9608 29.0614 12.3808C29.0514 13.7808 27.9514 14.9108 26.5714 14.9608Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M25.5412 22.2407C26.9112 22.4707 28.4212 22.2307 29.4812 21.5207C30.8912 20.5807 30.8912 19.0407 29.4812 18.1007C28.4112 17.3907 26.8812 17.1507 25.5112 17.3907" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.5413 14.9608C14.6013 14.9508 14.6713 14.9508 14.7313 14.9608C16.1113 14.9108 17.2113 13.7808 17.2113 12.3808C17.2113 10.9508 16.0613 9.80078 14.6313 9.80078C13.2013 9.80078 12.0513 10.9608 12.0513 12.3808C12.0613 13.7808 13.1613 14.9108 14.5413 14.9608Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.5712 22.2407C14.2012 22.4707 12.6912 22.2307 11.6312 21.5207C10.2212 20.5807 10.2212 19.0407 11.6312 18.1007C12.7012 17.3907 14.2312 17.1507 15.6012 17.3907" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5714 22.4308C20.5114 22.4208 20.4414 22.4208 20.3814 22.4308C19.0014 22.3808 17.9014 21.2508 17.9014 19.8508C17.9014 18.4208 19.0514 17.2708 20.4814 17.2708C21.9114 17.2708 23.0614 18.4308 23.0614 19.8508C23.0514 21.2508 21.9514 22.3908 20.5714 22.4308Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.6615 25.5806C16.2515 26.5206 16.2515 28.0606 17.6615 29.0006C19.2615 30.0706 21.8815 30.0706 23.4815 29.0006C24.8915 28.0606 24.8915 26.5206 23.4815 25.5806C21.8915 24.5206 19.2615 24.5206 17.6615 25.5806Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/ruang-meeting' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Ruang Meeting</span>
                            </Link>
                        </li>*/}
                        <li>
                            <Link to="/campaign" onClick={updatePath.bind(this, '/campaign')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5713 29.8008H23.5713C28.5713 29.8008 30.5713 27.8008 30.5713 22.8008V16.8008C30.5713 11.8008 28.5713 9.80078 23.5713 9.80078H17.5713C12.5713 9.80078 10.5713 11.8008 10.5713 16.8008V22.8008C10.5713 27.8008 12.5713 29.8008 17.5713 29.8008Z" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.5713 17.8008C18.6759 17.8008 19.5713 16.9054 19.5713 15.8008C19.5713 14.6962 18.6759 13.8008 17.5713 13.8008C16.4667 13.8008 15.5713 14.6962 15.5713 15.8008C15.5713 16.9054 16.4667 17.8008 17.5713 17.8008Z" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.2412 26.7508L16.1712 23.4408C16.9612 22.9108 18.1012 22.9708 18.8112 23.5808L19.1412 23.8708C19.9212 24.5408 21.1812 24.5408 21.9612 23.8708L26.1212 20.3008C26.9012 19.6308 28.1612 19.6308 28.9412 20.3008L30.5712 21.7008" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/campaign' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Campaign</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/laporan" onClick={updatePath.bind(this, '/laporan')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5713 29.8008H23.5713C28.5713 29.8008 30.5713 27.8008 30.5713 22.8008V16.8008C30.5713 11.8008 28.5713 9.80078 23.5713 9.80078H17.5713C12.5713 9.80078 10.5713 11.8008 10.5713 16.8008V22.8008C10.5713 27.8008 12.5713 29.8008 17.5713 29.8008Z" stroke={path === '/laporan' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.9014 22.2908L18.2814 19.2008C18.6214 18.7608 19.2514 18.6808 19.6914 19.0208L21.5214 20.4608C21.9614 20.8008 22.5914 20.7208 22.9314 20.2908L25.2414 17.3108" stroke={path === '/laporan' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/laporan' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Laporan</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/user" onClick={updatePath.bind(this, '/user')} className={localStorage.getItem('role') === 'Admin' ? "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700" : "hidden"}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke={path === '/user' ? '#3375F5' : '#ADADAD'} d="M20.5713 19.8008C23.3327 19.8008 25.5713 17.5622 25.5713 14.8008C25.5713 12.0394 23.3327 9.80078 20.5713 9.80078C17.8099 9.80078 15.5713 12.0394 15.5713 14.8008C15.5713 17.5622 17.8099 19.8008 20.5713 19.8008Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path stroke={path === '/user' ? '#3375F5' : '#ADADAD'} d="M29.1614 29.8008C29.1614 25.9308 25.3114 22.8008 20.5714 22.8008C15.8314 22.8008 11.9814 25.9308 11.9814 29.8008" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/user' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>User</span>
                            </Link>
                        </li>
                    </ul>
                    <ul className="absolute bottom-[24px] left-0 right-0 space-y-2 mx-4 cursor-pointer">
                        <li>
                            <div onClick={logout.bind(this)} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.0112 22.4208L28.5712 19.8608L26.0112 17.3008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.3311 19.8608H28.5011" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.3311 27.8008C15.9111 27.8008 12.3311 24.8008 12.3311 19.8008C12.3311 14.8008 15.9111 11.8008 20.3311 11.8008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap text-[#ADADAD]">Keluar</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }

    function PublisherSidebar() {
        return (
            <aside className="w-64 fixed h-screen bg-white border-r-[1px] border-[#EFECEC]" aria-label="Sidebar">
                <div className="overflow-y-auto py-4 px-3 bg-white rounded relative h-screen">
                    <ul className="border-b-[1px] border-[#EFECEC]">
                        <img src={Skrin} className="mx-auto my-[24.5px]" alt="" />
                    </ul>
                    <ul className="space-y-2">
                        {/* <li>
                            <Link to="/ruang-meeting" onClick={updatePath.bind(this, '/ruang-meeting')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.5714 14.9608C26.5114 14.9508 26.4414 14.9508 26.3814 14.9608C25.0014 14.9108 23.9014 13.7808 23.9014 12.3808C23.9014 10.9508 25.0514 9.80078 26.4814 9.80078C27.9114 9.80078 29.0614 10.9608 29.0614 12.3808C29.0514 13.7808 27.9514 14.9108 26.5714 14.9608Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M25.5412 22.2407C26.9112 22.4707 28.4212 22.2307 29.4812 21.5207C30.8912 20.5807 30.8912 19.0407 29.4812 18.1007C28.4112 17.3907 26.8812 17.1507 25.5112 17.3907" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.5413 14.9608C14.6013 14.9508 14.6713 14.9508 14.7313 14.9608C16.1113 14.9108 17.2113 13.7808 17.2113 12.3808C17.2113 10.9508 16.0613 9.80078 14.6313 9.80078C13.2013 9.80078 12.0513 10.9608 12.0513 12.3808C12.0613 13.7808 13.1613 14.9108 14.5413 14.9608Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.5712 22.2407C14.2012 22.4707 12.6912 22.2307 11.6312 21.5207C10.2212 20.5807 10.2212 19.0407 11.6312 18.1007C12.7012 17.3907 14.2312 17.1507 15.6012 17.3907" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5714 22.4308C20.5114 22.4208 20.4414 22.4208 20.3814 22.4308C19.0014 22.3808 17.9014 21.2508 17.9014 19.8508C17.9014 18.4208 19.0514 17.2708 20.4814 17.2708C21.9114 17.2708 23.0614 18.4308 23.0614 19.8508C23.0514 21.2508 21.9514 22.3908 20.5714 22.4308Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.6615 25.5806C16.2515 26.5206 16.2515 28.0606 17.6615 29.0006C19.2615 30.0706 21.8815 30.0706 23.4815 29.0006C24.8915 28.0606 24.8915 26.5206 23.4815 25.5806C21.8915 24.5206 19.2615 24.5206 17.6615 25.5806Z" stroke={path === '/ruang-meeting' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/ruang-meeting' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Ruang Meeting</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="/campaign" onClick={updatePath.bind(this, '/campaign')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5713 29.8008H23.5713C28.5713 29.8008 30.5713 27.8008 30.5713 22.8008V16.8008C30.5713 11.8008 28.5713 9.80078 23.5713 9.80078H17.5713C12.5713 9.80078 10.5713 11.8008 10.5713 16.8008V22.8008C10.5713 27.8008 12.5713 29.8008 17.5713 29.8008Z" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.5713 17.8008C18.6759 17.8008 19.5713 16.9054 19.5713 15.8008C19.5713 14.6962 18.6759 13.8008 17.5713 13.8008C16.4667 13.8008 15.5713 14.6962 15.5713 15.8008C15.5713 16.9054 16.4667 17.8008 17.5713 17.8008Z" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.2412 26.7508L16.1712 23.4408C16.9612 22.9108 18.1012 22.9708 18.8112 23.5808L19.1412 23.8708C19.9212 24.5408 21.1812 24.5408 21.9612 23.8708L26.1212 20.3008C26.9012 19.6308 28.1612 19.6308 28.9412 20.3008L30.5712 21.7008" stroke={path === '/campaign' ? '#3375F5' : '#ADADAD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={path === '/campaign' ? 'ml-3 text-[#3375F5]' : 'ml-3 text-[#ADADAD]'}>Campaign</span>
                            </Link>
                        </li>
                    </ul>
                    <ul className="absolute bottom-[24px] left-0 right-0 space-y-2 mx-4 cursor-pointer">
                        <li>
                            <div onClick={logout.bind(this)} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.0112 22.4208L28.5712 19.8608L26.0112 17.3008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.3311 19.8608H28.5011" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.3311 27.8008C15.9111 27.8008 12.3311 24.8008 12.3311 19.8008C12.3311 14.8008 15.9111 11.8008 20.3311 11.8008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap text-[#ADADAD]">Keluar</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }

    function SuperadminSidebar() {
        return (
            <aside className="w-64 fixed h-screen bg-white border-r-[1px] border-[#EFECEC]" aria-label="Sidebar">
                <div className="overflow-y-auto py-4 px-3 bg-white rounded relative h-screen">
                    <ul className="border-b-[1px] border-[#EFECEC]">
                        <img src={Skrin} className="mx-auto my-[24.5px]" alt="" />
                    </ul>
                    <ul className="space-y-2">
                        <li>
                            <Link to="/" onClick={updatePath.bind(this, '/')} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5913 10.6409L12.2013 14.8409C11.3013 15.5409 10.5713 17.0309 10.5713 18.1609V25.5709C10.5713 27.8909 12.4613 29.7909 14.7813 29.7909H26.3613C28.6813 29.7909 30.5713 27.8909 30.5713 25.5809V18.3009C30.5713 17.0909 29.7613 15.5409 28.7713 14.8509L22.5913 10.5209C21.1913 9.54094 18.9413 9.59094 17.5913 10.6409Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19.0713 25.8008H22.0713C23.7213 25.8008 25.0713 24.4508 25.0713 22.8008V19.8008C25.0713 18.1508 23.7213 16.8008 22.0713 16.8008H19.0713C17.4213 16.8008 16.0713 18.1508 16.0713 19.8008V22.8008C16.0713 24.4508 17.4213 25.8008 19.0713 25.8008Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.5713 16.8008V25.8008" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.0713 21.3008H25.0713" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="ml-3 text-[#3375F5]">Dashboard</span>
                            </Link>
                        </li>
                    </ul>
                    <ul className="absolute bottom-[24px] left-0 right-0 space-y-2 mx-4 cursor-pointer">
                        <li>
                            <div onClick={logout.bind(this)} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.0112 22.4208L28.5712 19.8608L26.0112 17.3008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.3311 19.8608H28.5011" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.3311 27.8008C15.9111 27.8008 12.3311 24.8008 12.3311 19.8008C12.3311 14.8008 15.9111 11.8008 20.3311 11.8008" stroke="#ADADAD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap text-[#ADADAD]">Keluar</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }

    function logout() {
        // todo : cek superadmin juga?
        if (localStorage.getItem("role") == 'Admin' || localStorage.getItem("role") == 'Superadmin') {
            ApiService.get(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/api/logout', {})
                .then(() => {
                    window.location = process.env.REACT_APP_FRONTEND_DOMAIN + '/login'
                })
        } else {
            ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/user/logout', {})
                .then(() => {
                    window.location = process.env.REACT_APP_FRONTEND_DOMAIN + '/login'
                })
        }
        ApiService.purgeAuth();
    }

    if (localStorage.getItem("role") !== 'Superadmin') {
        if (localStorage.getItem('role') === 'Admin') {
            return <AdminSidebar />
        } else  if (localStorage.getItem('role') === 'Content Designer'){
            return <DesignerSidebar />
        } else {
            return <PublisherSidebar />
        }
    } else {
        return <SuperadminSidebar />
    }

}