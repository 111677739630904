import '../../view-template1-grid.css';
import React from 'react'
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactJson from 'react-json-view'
import Close from '../../components/icon/Close'

export default function Preview7(props) {

  function RenderLogo() {
    if (props.singleImages.length !== 0) {
      return (
        <img alt="logo" className="max-w-full w-[100%] h-[100%] object-cover" src={props.singleImages[0].src} />
      )
    } else {
      <div className='max-w-full w-[100%] h-[100%] object-cover'>

      </div>
    }
  }

  function RenderText() {
    let textValue = props.text
    return (
      <div>
        <p className={`text-[` + textValue.fontFamily + `] text-[` + textValue.fontSize
          + `px]`}>
          {textValue.title}
        </p>
        <p className={`text-[` + textValue.teksFontFamily + `] text-[` + textValue.teksFontFamily + `px]`}>
          {textValue.teks}
        </p>
      </div>
    )
  }

  function RenderApi() {
    if (props.api !== "") {

      return (
        <div className='bg-white h-full'>
          <ReactJson src={props.api} />
        </div>
      )

    }
  }

  function RenderHtml() {
    let htmlValue = props.html
    return (
      <div className='bg-white h-full'>
        <div dangerouslySetInnerHTML={{ __html: htmlValue.html }} className="w-full h-full"></div>
      </div>
    )
  }


  function RenderRunningText() {
    return (
      <div id="scroll-container" className='flex items-center h-[100%]'>
        <p id="scroll-text" className={`text-[` + props.runningText.fontFamily + `] text-[` + props.runningText.fontSize + `px]`}>
          {props.runningText.teks}
        </p>
      </div>
    )
  }

  return (
    <div className={props.preview === true ? "template template-preview gap-[20px] gap-y-[20px] fixed top-0 left-0 w-full h-full overflow-hidden" : "hidden"}>
      <div className='absolute top-[0] right-[0]'>
        <button onClick={props.changePreview.bind(this)}>
          <Close />
        </button>
      </div>
      <div className='mb-[20px] max-h-[9vh] grid grid-cols-12 gap-x-[20px]'>
        <div className="col-span-6 h-[8vh] max-h-[8vh] h-[100%] object-cover bg-[#FFFFFF]">
          <RenderLogo />
        </div>
        <div className='col-span-6 h-[8vh] max-h-[8vh] px-[1%] py-[0.5%] bg-[#FFFFFF]'>
          <RenderText />
        </div>
      </div>
      <div className='mb-[20px] max-h-[77vh] grid grid-cols-12 gap-x-[20px]'>
        <div className="col-span-2 max-h-[77vh] h-[77vh] object-cover">
          <RenderApi />
        </div>
        <div className='mb-[20px] h-[77vh] max-h-[77vh] col-span-10 bg-[#FFFFFF]'>
          <RenderHtml />
        </div>
      </div>
      <div className='w-full bg-[#FFFFFF] h-[5vh]'>
        <RenderRunningText />
      </div>
    </div>
  )
}