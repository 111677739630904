
import { Instagram, Twitter, Facebook, Youtube } from "../../assets/svg/SocialMedia"
import Icon from "../../assets/images/login/icon.svg"
import Slider from "../../components/slider"
import { Link, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import Alert from '../../components/alert'
import Sikas from '../../assets/images/logo/sikas.png'
import Siqasir from '../../assets/images/logo/siqasir.png'
import Kyoo from '../../assets/images/logo/kyoo.png'
import Skrin from "../../assets/images/logo/skrin.png"

export default function Register() {
    const navigate = useNavigate()

    const [tnc, setTnc] = useState(false)
    const [province, setProvince] = useState()
    const [businessName, setBusinessName] = useState()
    const [businessCategory, setBusinessCategory] = useState()
    const [listProvinsi, setListProvince] = useState([])
    const [city, setCity] = useState()
    const [listKota, setListCity] = useState([])
    const pronvinceOption = []
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setConfirmationPassword] = useState("")
    const [message, setMessage] = useState('')
    const [type, setType] = useState('error')
    const [isLoaded, setIsLoaded] = useState(false)
    
    useEffect(() => {
        if (isLoaded === false) {
            ApiService.init()
            ApiService
                .get(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/api/get-my-data')
                .then((response) => {
                    navigate('/')
                })
                .catch((error) => {
                    fetch("https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json")
                        .then(response => response.json())
                        .then((res) => {
                            setProvince(res[0].name)
                            setListProvince([...res])
                        })
                })
        }
    }, [isLoaded])

    for (const [index, item] of listProvinsi.entries()) {
        pronvinceOption.push(
            <option value={item.id + '-' + item.name} key={'province' + index}>{item.name}</option>
        )
    }

    const changeListKota = (e) => {
        e.preventDefault();
        var temporaryProvince = e.target.value.split('-')
        fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/` + temporaryProvince[0] + `.json`)
            .then(response => response.json())
            .then(city => {
                setCity(city[0].name)
                setListCity([...city])
            });


        setProvince(e.target.value)
    }

    const updateCity = (e) => {
        e.preventDefault();
        setCity(e.target.value)
    }

    function optionKota() {
        let cityOption = [];

        for (const [index, item] of listKota.entries()) {
            cityOption.push(
                <option value={item.name} key={'city' + index}>{item.name}</option>
            )
        }
        return cityOption
    }

    function setBannerNotification(type, message, redirect) {
        setType(type)
        setMessage(message)
        var alert = document.getElementById('alert');
        alert.classList.toggle('hidden');
        alert.classList.toggle('opacity-[0]')

        setTimeout(() => {
            alert.classList.toggle('opacity-[0]')
        }, 2000)

        setTimeout(() => {
            alert.classList.toggle('hidden');
            if (redirect) window.location = redirect
        }, 2500)
    }


    function validateUserAvailable() {
        const formData = [email, name, businessName, businessCategory, phone, province, city, password, passwordConfirmation];
        if(formData.some((e) => e === "")) {
            setBannerNotification('error', 'Lengkapi semua data anda.')
            return
        }

        if (passwordConfirmation !== password) {
            setBannerNotification('error', 'Password konfirmasi dan password tidak sama.')
            return
        }

        register()
        // ApiService
        //     .post(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/api/user-available', {
        //         'email': email
        //     })
        //     .then((response) => {
        //         setBannerNotification('error', 'Email sudah terdaftar.')
        //         return

        //     }).catch((error) => {
        //         register()
        //     })
        
    }

    function register() {
        let params = {
            email: email,
            name: name,
            password: password,
            passwordConfirmation: passwordConfirmation,
            businessName: businessName,
            businessCategory: businessCategory,
            phone: phone,
            province: province,
            city: city,
            product_id: 4, // product_id Skrin get from api https://dev.api.awandigital.id/api/product
        }
        // axios
        ApiService
            .post(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/api/register_sso', params)
            .then((response) => {
                setBannerNotification('success', 'Berhasil mendaftarkan akun anda.', '/login')
            })
            .catch((error) => {
                console.log("err", error)
                setBannerNotification('error', 'Periksa kembali data anda.')
                return
            })
    }

    return (
        <div className="w-screen block md:flex h-fit register">
            <div className='bg-[#ED5565] hidden md:flex items-center justify-center h-screen left'>
                <div className="w-[308px]">
                    <div>
                        <img src={Icon} className="-ml-[24px]" alt="" />
                    </div>
                    <Slider />
                    <div className='flex mt-[26.83px]'>
                        <a href="https://www.youtube.com/channel/UCtQgKQgDQlHS3sO_LTxxh0Q"><Youtube /></a>
                        <a href="https://www.instagram.com/awandigitalindonesia/"><Instagram/></a>
                        <a href="https://web.facebook.com/profile.php?id=100077383916860"><Facebook /></a>
                        <a href="https://twitter.com/AdiMaketing"><Twitter /></a>
                    </div>
                </div>
            </div>
            <div className='right h-screen bg-[#FFFFFF] relative'>
                <div className="sticky z-[5] top-[50px] w-full flex justify-center mx-auto hidden opacity-[0]" id="alert">
                    <Alert type={type} msg={message} />
                </div>
                <div className='px-[20px] md:px-[39.73px] pt-[30.66px]'>
                    <Link to="/" className="/">
                        <img src={Skrin} alt="" />
                    </Link>
                </div>
                <div className='block md:flex px-[20px] md:px-[0] justify-center'>
                    <div className='form-register '>
                        <div className='title'>
                            <h3>Register</h3>
                            <p>Lengkapi form untuk membuat akun</p>
                        </div>

                        <div>
                            <label htmlFor="nama">Nama Lengkap</label>
                            <div className='relative mt-1'>
                                <div className='svg-email'></div>
                                <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className='input input-bordered w-full pl-[50px]' placeholder='Nama Lengkap' />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="nama">Nama Bisnis</label>
                            <div className='relative mt-1'>
                                <div className='svg-business'></div>
                                <input type="text" id="businessName" onChange={(e) => setBusinessName(e.target.value)} className='input input-bordered w-full pl-[50px]' placeholder='Nama Bisnis' />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="nama">Kategori Bisnis</label>
                            <div className='relative mt-1'>
                                <div className='svg-business'></div>
                                <select className="select w-full pl-[50px]" id="businessCategory" value={businessCategory} onChange={(e) => setBusinessCategory(e.target.value)}>
                                    <option value="">Pilih Kategori</option>
                                    <option value="Health">Health</option>
                                    <option value="Beauty and Treatment">Beauty and Treatment</option>
                                    <option value="Public Service">Public Service</option>
                                    <option value="Automotive">Automotive</option>
                                    <option value="Education">Education</option>
                                    <option value="Financial">Financial</option>
                                    <option value="FnB">FnB</option>
                                    <option value="Print and Design">Print and Design</option>
                                    <option value="Telecom">Telecom</option>
                                    <option value="Entertainment">Entertainment</option>
                                    <option value="Event and Exhibition">Event and Exhibition</option>
                                </select>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="email">Email</label>
                            <div className='relative mt-1'>
                                <div className='svg-email'></div>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className='input input-bordered w-full pl-[50px]' placeholder='Email' />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="phone">No. Handphone</label>
                            <div className='relative mt-1'>
                                <div className='svg-phone'></div>
                                <input type="number" id="phone" onChange={(e) => setPhone(e.target.value)} className='input input-bordered w-full pl-[50px]' placeholder='Ch. +628***' />
                            </div>
                        </div>

                        <div className="lg:flex gap-x-[10px]">
                            <div className="basis-6/12">
                                <label htmlFor="province">Provinsi</label>
                                <div className='relative mt-1'>
                                    <div className='svg-home'></div>
                                    <select className="select w-full pl-[50px]" id="province" value={province} onChange={(e) => changeListKota(e)}>
                                        <option value="">Pilih Provinsi</option>
                                        {pronvinceOption}
                                    </select>
                                </div>
                            </div>

                            <div className="basis-6/12">
                                <label htmlFor="province">Kota</label>
                                <div className='relative mt-1'>
                                    <div className='svg-pin'></div>
                                    <select className="select w-full pl-[50px]" id="city" value={city} onChange={(e) => updateCity(e)}>
                                        <option value="">Pilih Kota</option>
                                        {optionKota()}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password">Password</label>
                            <div className='relative mt-1'>
                                <div className='svg-password'></div>
                                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className='input input-bordered w-full pl-[50px]' placeholder='Masukan Password' />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password">Konfirmasi Password</label>
                            <div className='relative mt-1'>
                                <div className='svg-password'></div>
                                <input type="password" id="konfirmasiPassword" value={passwordConfirmation} onChange={(e) => setConfirmationPassword(e.target.value)} className='input input-bordered w-full pl-[50px]' placeholder='Ketik Ulang Password' />
                            </div>
                        </div>

                        <div className='flex flex-wrap tnc cursor-pointer label' onClick={() => setTnc(!tnc)}>
                            <input type="checkbox" checked={tnc} className="checkbox checkbox-custom" value={tnc} onChange={(e)=>{}} />
                            <p className="label-text">Saya telah membaca dan menyetujui <a href="https://awandigital.id/term_condition.html" target="_blank">syarat dan ketentuan</a> yang berlaku</p>
                        </div>

                        <div className='action mb-[111px]'>
                            <button className='btn w-full' onClick={validateUserAvailable.bind(this)} disabled={!tnc}>
                                Register
                            </button>

                            <p>
                                Sudah punya akun?
                                <Link to="/login" className="text-[#EB008B]">
                                    Login
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
