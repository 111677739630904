import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import TimePicker from "rc-time-picker";
import DatePicker from 'react-datepicker'
import { useParams } from "react-router-dom";
import {
  QueryClient,
  useQuery,
  useMutation,
} from "react-query";

import TextPopup from '../../components/TextPopup'
import Teks from '../../components/panel/Teks'
import RunningTextPopup from '../../components/RunningTextPopup'
import ApiPopup from '../../components/ApiPopup'

import Logo from '../../components/panel/Logo'
import Audio from '../../components/panel/Audio'
import RunningText from '../../components/panel/RunningText'
import Navbar from '../../components/menu/Navbar'
import Back from '../../components/icon/Back'
import Image from '../../components/panel/Image'
import Api from '../../components/panel/Api'
import SingleImagePopupLogo from '../../components/SingleImagePopupLogo'
import ApiService from '../../services/api.service'
import { toastSuccess, toastError } from '../../components/toast';
import ShimmerLoading from '../../components/shimmerLoading';

import Preview from '../../components/icon/Preview'
import Preview10 from './Preview10'
import AddCircle from '../../components/icon/AddCircle';
import moment from 'moment';

export default function Template10() {
  const userEmail = localStorage.getItem('email')

  const [flagDevice, setFlagDevice] = useState([])
  const [flagBranch, setFlagBranch] = useState([])
  const [isTextOn, setIsTextOn] = useState(false)
  const [isApiOn, setIsApiOn] = useState(false)
  const [isRunningTextOn, setIsRunningTextOn] = useState(false)
  const [preview, setPreview] = useState(false)
  const [isSingleImageOnLogo, setIsSingleImageOnLogo] = useState(false);
  // loading semua data campaign
  const [isFetchingCampaignData, setIsFetchingCampaignData] = useState(false)
  const [isFetchingDB, setIsFetchingDB] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [DBList, setDBList] = useState([])
  const DBListRef = React.useRef([])
  const [warehouseList, setWarehouseList] = useState([])
  const dateNow = new Date();

  const [runningText, setRunningText] = useState({
    'teks': '',
    'teksColor': '',
    'fontSize': 24,
    'runningSpeed': 20,
    'fontFamily': 'Arial',
    'bgColor': '#ffffff',
  })

  const [api, setApi] = useState({})
  const apiRef = React.useRef({})

  // ambil data api
  const apiDataMutation = useMutation(async () => {
    try {
      const response = await ApiService.put(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/update-warehouse`, {
        'email': userEmail,
        'warehouse': api?.selectedWarehouse
      });

      const data = response.data.data

      toastSuccess('Gudang berhasil diubah menjadi ' + data.warehouse)
    } catch (error) {
      toastError('Terjadi kesalahan saat mengambil data dari API. ' + error.response?.status);
    }
  })

  useEffect(() => {
    if (api.selectedDb != undefined) {
      requestOpenDb()
    }
  }, [api.selectedDb])

  async function requestOpenDb() {
    try {
      const response = await ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/open-db`, {
        'email': userEmail,
        'db': api.selectedDb
      });

      const res = response.data.data
      const status = res.s;

      if (status) {
        fetchWarehouses()

        const message = res.d
        toastSuccess(message[0])
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message ?? null)
      toastError('Terjadi kesalahan saat mengambil data dari API. ');
    }
  }

  async function fetchWarehouses() {
    try {
      const response = await ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/get-warehouses`, {
        'email': userEmail,
      });

      const res = response.data.data
      const status = res.s;
      if (status) {
        const data = res.d;
        setWarehouseList(data)
      }

    } catch (error) {
      setErrorMessage(error.response?.data?.message ?? null)
      toastError('Terjadi kesalahan saat mengambil data dari API. ');
    }
  }

  // integrasi api
  const integrateApiMutation = useMutation(async () => {
    window.open(`https://api.skrin.awandigital.id/integrate-accurate-api?client_id=${api.clientId}&client_secret=${api.clientSecret}&email=${userEmail}`, '_blank');
  })

  // ambil data campaign sebelumnya jika ada id campaign (untuk edit campaign)
  const { id } = useParams();
  useEffect(() => {
    fetchExistCredentials()
      .then((credentials) => {
        if (id) {
          setIsFetchingCampaignData(true)

          fetchExistCampaign()
        }

        let newApi = { ...api }
        newApi['clientId'] = credentials?.clientId
        newApi['clientSecret'] = credentials?.clientSecret
        newApi['selectedDb'] = credentials?.db
        newApi['selectedWarehouse'] = credentials?.warehouse
        setApi({ ...newApi })
        apiRef.current = newApi;

        if (apiRef.current?.clientId != null && apiRef?.current?.clientSecret != null) {
          checkAccurateAPI()
        }
      })

    window.addEventListener('focus', function() {
      if (DBListRef?.current?.length == 0 && apiRef?.current?.clientId != null && apiRef?.current?.clientSecret != null) {
        checkAccurateAPI()
      }
    });
  }, [])

  const checkAccurateAPI = async () => {
    setIsFetchingDB(true)
    await ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/get-accurate-db?email=${userEmail}`)
      .then((response) => {
        const res = response.data.data
        const status = res.s;

        if (status) {
          const data = res.d;
          setDBList(data)
          console.log('data')
          console.log(data)
          DBListRef.current = data
        }
      }).catch((error) => {
        if (error.response?.status != 404) {
          toastError('Terjadi kesalahan saat mengambil data dari API.');
        }
      }).finally(() => {
        setIsFetchingDB(false)
      })
  }

  const fetchExistCampaign = async () => {
    ApiService.init()
    await ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign/${id}`)
      .then((response) => {
        let data = response.data.data;
        let campaign = data.campaignDetails;
        setCampaignName(data.name)

        let singleImageLogo = [{
          'imageId': campaign[0][0].id,
          'id': campaign[0][0].gallery.id,
          'src': process.env.REACT_APP_BACKEND_DOMAIN + '/storage/' + campaign[0][0].gallery.value
        }]
        setSingleImageLogo([...singleImageLogo])

        setApi({
          ...{
            id: campaign[1][0].id,
            headerFontColor: campaign[1][0].headerFontColor,
            headerColor: campaign[1][0].headerColor,
          }
        })

        setDurationType(data.period)
        setStartTime(data.startTime)
        setEndTime(data.endTime)
        setStartDate(new Date(data.startDate))
        setEndDate(new Date(data.endDate))
        setDay({ ...data.workdays })
        setFlagBranch([...data.isSelectedBranch])
        setFlagDevice([...data.isSelectedDevice])
      })
  }

  function fetchExistCredentials() {
    return ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/get-exist-credential?email=${userEmail}`)
      .then((response) => {
        let data = response.data.data;
        return data;
      })
      .catch((error) => {
        return null;
      })
  }

  useEffect(() => {
    fetchBranches()
    fetchDevices()
  }, [flagBranch])

  function updateData(type, attribute, value) {
    if (type === 'api') {
      let newApi = { ...api }
      newApi[attribute] = value
      setApi({ ...newApi })
      apiRef.current = newApi
    }
  }

  function togglePopup(popup) {
    var layout = document.getElementById('popup')
    if (popup === 'api') {
      setIsApiOn(!isApiOn)
      if (!isApiOn === true) {
        layout.classList.remove('hidden')
        layout.classList.add('flex')
      } else {
        layout.classList.remove('flex')
        layout.classList.add('hidden')
      }
    }

    if (popup === 'singleLogo') {
      setIsSingleImageOnLogo(!isSingleImageOnLogo);
      if (!isSingleImageOnLogo === true) {
        layout.classList.remove('hidden')
        layout.classList.add('flex')
      } else {
        layout.classList.remove('flex')
        layout.classList.add('hidden')
      }
    }

  }

  const [campaignName, setCampaignName] = useState('')
  function updateName(value) {
    setCampaignName(value)
  }

  const [singleImagesLogo, setSingleImageLogo] = useState([])
  function updateSingleImageListLogo(list) {
    setSingleImageLogo([...list])
    var layout = document.getElementById('popup')

    setIsSingleImageOnLogo(!isSingleImageOnLogo)
    if (!isSingleImageOnLogo === true) {
      layout.classList.remove('hidden')
      layout.classList.add('flex')
    } else {
      layout.classList.remove('flex')
      layout.classList.add('hidden')
    }
  }

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [page, setPage] = useState('jadwal')
  const [day, setDay] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  })

  const [startTime, setStartTime] = useState('00:00')
  const [endTime, setEndTime] = useState('00:00')
  const [durationType, setDurationType] = useState('harian')
  const [deviceList, setDeviceList] = useState([])
  let [selectedBranch, setSelectedBranch] = useState([])
  const [search, setSearch] = useState('')

  function searchCabang(value) {
    setSearch(value);

    ApiService.init()
    ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch?search=' + value)
      .then((response) => {
        let data = response.data.data;
        data.forEach((value) => {
          if (selectedBranch.includes(value.id)) {
            value.status = true;
          }
        })
        setBranch([...data])
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function updateCheckedDevice(index) {
    let newBranch = [...branch]
    newBranch[index].status = !newBranch[index].status
    let temporaryBranch = [...selectedBranch];
    if (newBranch[index].status === false) {
      if (selectedBranch.includes(newBranch[index].id)) {
        temporaryBranch.splice(temporaryBranch.indexOf(newBranch[index].id), 1)
      }
    } else {
      temporaryBranch.push(newBranch[index].id)
    }
    setSelectedBranch([...temporaryBranch]);
    setBranch([...newBranch])
  }

  const [branch, setBranch] = useState([])

  function BranchList() {
    let branchOption = []
    for (const [index, value] of branch.entries()) {
      branchOption.push(
        <div
          className="flex flex-wrap items-center py-[15px] border-b-[1px] border-[#E7EAEC] gap-x-[12px]"
          key={'branch-' + index}
        >
          <div className="basis-1/12">
            <input
              type="checkbox"
              checked={value.status}
              onChange={updateCheckedDevice.bind(this, index)}
              className="checkbox checkbox-primary"
            />
          </div>
          <div className="basis-10/12">
            <h3>{value.name}</h3>
            <h4>
              {value.province}, {value.city}, {value.district}
            </h4>
            <p>{value.address}</p>
          </div>
        </div>,
      )
    }
    return branchOption
  }

  function selectDevice(indexBranch, indexDevice) {
    let newDeviceList = [...deviceList]
    newDeviceList[indexBranch].devices[indexDevice].isDeviceSelected = !newDeviceList[indexBranch].devices[indexDevice].isDeviceSelected;
    return () => setDeviceList([...newDeviceList])
  }

  let branchDevice = []
  for (let [index, value] of deviceList.entries()) {
    let devices = [];
    for (let [indexDevice, branchDevice] of value.devices.entries()) {
      devices.push(
        <div className="grid grid-cols-12 gap-x-[10px] py-[12px] border-b-[1px] border-[#E7E9EC]" key={'device-' + value.id + '-' + indexDevice}>
          <div className="col-span-1">
            <input
              type="checkbox"
              className="checkbox checkbox-primary"
              value={branchDevice.isDeviceSelected}
              defaultChecked={branchDevice.isDeviceSelected}
              onChange={selectDevice.bind(this, index, indexDevice)}
            />
          </div>
          <div className="device-name col-span-11">
            <h3>{branchDevice.name}</h3>
            <p>{branchDevice.type}</p>
          </div>
        </div>
      )
    }

    branchDevice.push(
      <div
        className=" bg-base-100 rounded-box cursor-pointer"
        key={'deviceBranch-' + index}
      >
        <div className="collapse-title text-xl font-medium border-b-[1px] border-[#E7E9EC]"
          onClick={collapseContent.bind(this, index)}
        >
          {value.name} <span className='device-total'> ( {value.devices.length} Perangkat ) </span>
        </div>
        <div className="device-list" id={"devices-" + index}>
          {devices}
        </div>
      </div>
    )
  }

  function collapseContent(index, event) {
    event.preventDefault()
    if (event.target === event.currentTarget) {
      let children = document.getElementById('devices-' + index)
      children.classList.toggle('!max-h-fit')
    }
  }

  function changeDay(dayName) {
    let newDay = { ...day }
    newDay[dayName] = !newDay[dayName];
    setDay({ ...newDay })
  }

  function changePage(pageName) {
    if (pageName === 'device') {
      fetchDevices()
      setPage(pageName)
    } else if (pageName === 'cabang') {
      fetchBranches()
      setPage(pageName)
    } else {
      setPage(pageName)
    }

  }

  async function fetchDevices() {
    let query = '/api/branch?';
    for (let i = 0; i < selectedBranch.length; i++) {
      query = query + 'branch_ids[]=' + selectedBranch[i]
      if (i !== selectedBranch.length - 1) {
        query = query + '&'
      }
    }

    if (selectedBranch.length !== 0 && deviceList.length == 0) {
        ApiService.init()
        await ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + query)
          .then((response) => {
            let data = response.data.data
            if (flagDevice.length != 0) {
              data.forEach(function (value) {
                value.devices.forEach(function (device) {
                  if (flagDevice.includes(device.id)) {
                    device.isDeviceSelected = true;
                  }
                })
              })
            }
            setDeviceList([...data])

            setIsFetchingCampaignData(false)
          })
          .catch((error) => {
          })
      } else {
        setDeviceList([])
      }
  }

  async function fetchBranches() {
    let temporaryBranch = []
    if (branch.length === 0) {
      ApiService.init()
      return await ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch')
        .then((response) => {
          let data = response.data.data
          if (flagBranch.length != 0) {
            data.forEach(function (value) {
              if (flagBranch.includes(value.id)) {
                value.status = true;
                temporaryBranch.push(value.id)
              }
            })
          }
          setSelectedBranch([...temporaryBranch])
          setFlagBranch(null)

          setBranch([...data])

          setIsFetchingCampaignData(false)
        })
        .catch((error) => {
          // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
        })
    }
  }

  function saveCampaign() {
    let logoImagesValue = singleImagesLogo.length;

    if (logoImagesValue === 0) {
      toastError('Silahkan isi semua data')
    } else {
      if (campaignName === '') {
        toastError('Silahkan isi nama campaign')
      } else {
        let devices = [];
        // if (id !== undefined) {
        //   devices = flagDevice
        // } else {
          for (const [key, item] of deviceList.entries()) {
            item.devices.forEach((value) => {
              if (value.isDeviceSelected === true) {
                devices.push(value.id);
              }
            })
          }
        // }

        if (devices.length === 0) {
          toastError('Silahkan pilih device')
        } else {
          let campaignData = {
            'template_id': 10,
            'name': campaignName,
            'start_date': startDate,
            'end_date': endDate,
            'start_time': startTime,
            'endTime': endTime,
            'workdays': day,
            'devices': devices,
            'status': 'active',
            'period': durationType
          };

          const campaign = {
            id: id,
            campaignData: campaignData,
            singleImages: singleImagesLogo,
            singleImagesIndex: 0,
            api: api,
            apiIndex: 1,
          };

          ApiService.createOrUpdateCampaign(campaign);

          // if (id !== undefined) {
          //   let campaign = {
          //     'template_id': 9,
          //     'name': campaignName,
          //     'start_date': startDate,
          //     'end_date': endDate,
          //     'start_time': startTime,
          //     'endTime': endTime,
          //     'workdays': day,
          //     'devices': devices,
          //     'status': 'active',
          //     'period': durationType
          //   };

          //   ApiService.initFormData()
          //   ApiService.put(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign/' + id, campaign)
          //     .then((response) => {
          //       let id = response.data.id;

          //       let singleImageData = {
          //         campaign_id: id,
          //         gallery_id: singleImages[0].id,
          //         index: 1,
          //         type: 'image',
          //       }

          //       ApiService.init()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', singleImageData)


          //       let textData = {
          //         campaign_id: id,
          //         index: 2,
          //         type: 'text',
          //         title: text.title,
          //         title_font_size: text.fontSize,
          //         title_font: text.fontFamily,
          //         value: text.teks,
          //         value_font_size: text.teksFontSize,
          //         value_font: text.teksFontFamily
          //       }

          //       ApiService.initFormData()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', textData)

          //       let singleImageTwoData = {
          //         campaign_id: id,
          //         gallery_id: singleImagesTwo[0].id,
          //         index: 3,
          //         type: 'image',
          //       }

          //       ApiService.init()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', singleImageTwoData)

          //       let singleImageLogoData = {
          //         campaign_id: id,
          //         gallery_id: singleImagesLogo[0].id,
          //         index: 4,
          //         type: 'image',
          //       }

          //       ApiService.init()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', singleImageLogoData)


          //       let runningTextData = {
          //         campaign_id: id,
          //         index: 5,
          //         type: 'running_text',
          //         value: runningText.teks,
          //         value_font_size: runningText.fontSize,
          //         value_font: runningText.fontFamily
          //       }

          //       ApiService.initFormData()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', runningTextData)


          //       generateAlert('success', 'Berhasil menyimpan campaign')

          //       setTimeout(() => {
          //         window.location = "/campaign"
          //       }, 2000)
          //     })
          //     .catch((error) => {
          //     })
          // } else {
          //   let campaign = {
          //     'template_id': 9,
          //     'name': campaignName,
          //     'start_date': startDate,
          //     'end_date': endDate,
          //     'start_time': startTime,
          //     'endTime': endTime,
          //     'workdays': day,
          //     'devices': devices,
          //     'status': 'active',
          //     'period': durationType
          //   };

          //   ApiService.initFormData()
          //   ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign', campaign)
          //     .then((response) => {
          //       let id = response.data.id;

          //       let singleImageData = {
          //         campaign_id: id,
          //         gallery_id: singleImages[0].id,
          //         index: 1,
          //         type: 'image',
          //       }

          //       ApiService.init()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', singleImageData)


          //       let textData = {
          //         campaign_id: id,
          //         index: 2,
          //         type: 'text',
          //         title: text.title,
          //         title_font_size: text.fontSize,
          //         title_font: text.fontFamily,
          //         value: text.teks,
          //         value_font_size: text.teksFontSize,
          //         value_font: text.teksFontFamily
          //       }

          //       ApiService.initFormData()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', textData)

          //       let singleImageTwoData = {
          //         campaign_id: id,
          //         gallery_id: singleImagesTwo[0].id,
          //         index: 3,
          //         type: 'image',
          //       }

          //       ApiService.init()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', singleImageTwoData)

          //       let singleImageLogoData = {
          //         campaign_id: id,
          //         gallery_id: singleImagesLogo[0].id,
          //         index: 4,
          //         type: 'image',
          //       }

          //       ApiService.init()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', singleImageLogoData)


          //       let runningTextData = {
          //         campaign_id: id,
          //         index: 5,
          //         type: 'running_text',
          //         value: runningText.teks,
          //         value_font_size: runningText.fontSize,
          //         value_font: runningText.fontFamily
          //       }

          //       ApiService.initFormData()
          //       ApiService.post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/campaign-detail', runningTextData)


          //       generateAlert('success', 'Berhasil menyimpan campaign')

          //       setTimeout(() => {
          //         window.location = "/campaign"
          //       }, 2000)
          //     })
          //     .catch((error) => {
          //     })
          // }

        }
      }
    }
  }

  function changeDurationType(value) {

    setDurationType(value);
    let newDay = { ...day }

    if (value === 'Bulanan') {
      newDay['sunday'] = true;
      newDay['monday'] = true;
      newDay['tuesday'] = true;
      newDay['wednesday'] = true;
      newDay['thursday'] = true;
      newDay['friday'] = true;
      newDay['saturday'] = true;
    }

    setDay({ ...newDay });
  }

  function changePreview() {
    setPreview(!preview)
  }

  return (
    <div className="w-full h-screen cabang-templates bg-white overflow-x-hidden">
      <div className="top-content">
        <Navbar />
      </div>
      <div className="content">
        <div className="header flex flex-wrap">
          <div className={id !== undefined ? "basis-6/12 title" : "hidden"}>
            <Link
              to="/campaign"
              className="flex items-center gap-x-[4px]"
            >
              <Back />
              <h3>Ubah Campaign</h3>
            </Link>
          </div>
          <div className={id === undefined ? "basis-6/12 title" : "hidden"}>
            <Link
              to="/campaign/template"
              className="flex items-center gap-x-[4px]"
            >
              <Back />
              <h3>Buat Campaign</h3>
            </Link>
          </div>
          <div className="basis-6/12 action flex flex-wrap items-center justify-end gap-x-[24px] pl-[24px]">
            <button onClick={changePreview.bind(this)} className="btn !bg-[#FFFFFF] !border-[1px] !border-solid !text-[#3375F5] !border-[#3375F5] gap-x-[12px] !px-[29.5px]">
              <Preview />
              PRATINJAU
            </button>
            <button className='btn gap-x-[12px]' onClick={saveCampaign.bind(this)}>
              <AddCircle />
              SIMPAN CAMPAIGN
            </button>
          </div>
        </div>
        {isFetchingCampaignData ? (
          <div className='main grid grid-cols-5 gap-x-[20px]'>
              <div className='col-span-3'>
                  <div className="h-[36px]">
                      <ShimmerLoading/>
                  </div>
                  <div className='h-[256px] mt-[24px]'>
                      <ShimmerLoading/>
                  </div>
              </div>
              <div className='col-span-2'>
                  <ShimmerLoading/>
              </div>
          </div>
        ) : (
          <div className="main grid grid-cols-5 gap-x-[20px]">
            <div className="col-span-3">
              {errorMessage ? (
                <div className="bg-red-100 rounded-xl p-4 mb-8">
                  {errorMessage}
                </div>
              ) : null}
              <div>
                <label htmlFor="nama-campaign">Nama Campaign</label>
                <input
                  type="text"
                  placeholder="Masukkan Nama Campaign disini"
                  value={campaignName}
                  onChange={(e) => updateName(e.target.value)}
                  className="w-full input mt-2"
                />
              </div>
              <div id="template10">
                <div className="grid grid-cols-12 gap-x-[16px]">
                  <div className="col-span-12 logo flex justify-between items-center bg-blue-100 px-[8px] py-[11px] cursor-pointer"
                    onClick={togglePopup.bind(this, 'singleLogo')}
                  >
                      {singleImagesLogo.length === 0 ? (
                        <Logo/>
                      ) : (
                        <div className='h-[100%] object-cover'>
                          <img src={singleImagesLogo[0].src} className="h-[100%] object-cover" alt="" />
                        </div>
                      )}
                      <div className="flex items-center font-bold">
                        <div className="flex flex-col items-end pe-2 me-2 border-black border-r-2">
                          <span>{moment(dateNow).format("dddd")}</span>
                          <span>{moment(dateNow).format("D MMMM YYYY")}</span>
                        </div>
                        <h4 className="text-xl">{moment(dateNow).format("HH:mm:ss")}</h4>
                      </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-x-[16px]">
                  <div
                    className="col-span-12 media px-[8px] py-[11px] flex items-center justify-center cursor-pointer"
                    onClick={togglePopup.bind(this, 'api')}
                  >
                    {api?.url?.length === 0 ? (
                      <Api />
                    ) : (
                      <table className='h-[100%] w-[100%]'>
                        <thead style={{
                          color: api.headerFontColor,
                          backgroundColor: api.headerColor,
                        }}>
                          <tr>
                            <th className="py-2 uppercase">No.</th>
                            <th className="py-2 uppercase">Item</th>
                            <th className="py-2 uppercase">Stock</th>
                            <th className="py-2 uppercase">Status</th>
                          </tr>
                        </thead>
                        <tbody className="font-bold">
                          {Array.from({ length: 3 }).map((_, index) => (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center">Nama Item</td>
                              <td className="text-center">{index + 10}</td>
                              <td className={`text-center uppercase text-green-500`}>available</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="running-text px-[8px] overflow-hidden py-[11px] cursor-pointer">
                  {api?.url?.length === 0 ? (
                    "Last Updated"
                  ) : (
                    "Last Updated : " + moment(dateNow).format("D MMMM YYYY HH:mm:ss")
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-2 campaing-setting">
              <div
                className={
                  page === 'jadwal' ? 'schedule h-full relative' : 'hidden'
                }
              >
                <div className="title">
                  <h3>Jadwal</h3>
                </div>
                <div className="grid grid-cols-12 w-full time-input">
                  <div className="col-span-5">
                    <h3>Waktu Mulai</h3>
                    <TimePicker
                      placeholder="00:00"
                      showSecond={false}
                      focusOnOpen={true}
                      format={'HH mm'}
                      value={moment(startTime, [moment.ISO_8601, 'HH:mm'])}
                      onChange={e => setStartTime(e.format('HH:mm'))}
                    />
                  </div>
                  <div className="col-span-2 flex justify-center items-center">
                    -
                  </div>
                  <div className="col-span-5">
                    <h3>Berakhir Sampai</h3>
                    <TimePicker
                      placeholder="00:00"
                      showSecond={false}
                      focusOnOpen={true}
                      format={'HH mm'}
                      value={moment(endTime, [moment.ISO_8601, 'HH:mm'])}
                      onChange={e => setEndTime(e.format('HH:mm'))}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 w-full date-input">
                  <div className="col-span-5">
                    <h3>Tanggal Mulai</h3>
                    <DatePicker
                      value={startDate}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="col-span-2 flex justify-center items-center">
                    -
                  </div>
                  <div className="col-span-5">
                    <h3>Berakhir Sampai</h3>
                    <DatePicker
                      value={endDate}
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
                <div className="w-full period-select">
                  <select className="select w-full" value={durationType} onChange={e => changeDurationType(e.target.value)}>
                    <option value={'harian'}>Harian</option>
                    <option value={'Bulanan'}>Bulanan</option>
                  </select>
                </div>
                <div className="w-full day-group flex flex-wrap">

                  <ul className="inline-flex gap-x-[12px]">
                    <li>
                      <h3>HARI KERJA</h3>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="sunday"
                        name="sunday"
                        value={day.sunday}
                        onChange={(event) => changeDay('sunday', event.target.value)}
                      />
                      {
                        day.sunday ?
                          <label htmlFor="sunday" className='!bg-[#3375F5] !text-[#FFFFFF]'>S</label>
                          :
                          <label htmlFor="sunday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >S</label>
                      }
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="monday"
                        name="monday"
                        value={day.monday}
                        onChange={(event) => changeDay('monday', event.target.value)}
                      />
                      {
                        day.monday ?
                          <label htmlFor="monday" className='!bg-[#3375F5] !text-[#FFFFFF]'>M</label>
                          :
                          <label htmlFor="monday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >M</label>
                      }
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="tuesday"
                        name="tuesday"
                        value={day.tuesday}
                        onChange={(event) => changeDay('tuesday', event.target.value)}
                      />
                      {
                        day.tuesday ?
                          <label htmlFor="tuesday" className='!bg-[#3375F5] !text-[#FFFFFF]'>T</label>
                          :
                          <label htmlFor="tuesday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >T</label>
                      }
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="wednesday"
                        name="wednesday"
                        value={day.wednesday}
                        onChange={(event) => changeDay('wednesday', event.target.value)}
                      />
                      {
                        day.wednesday ?
                          <label htmlFor="wednesday" className='!bg-[#3375F5] !text-[#FFFFFF]'>W</label>
                          :
                          <label htmlFor="wednesday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >W</label>
                      }
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="thursday"
                        name="thursday"
                        value={day.thursday}
                        onChange={(event) => changeDay('thursday', event.target.value)}
                      />
                      {
                        day.thursday ?
                          <label htmlFor="thursday" className='!bg-[#3375F5] !text-[#FFFFFF]'>T</label>
                          :
                          <label htmlFor="thursday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >T</label>
                      }
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="friday"
                        name="friday"
                        value={day.friday}
                        onChange={(event) => changeDay('friday', event.target.value)}
                      />
                      {
                        day.friday ?
                          <label htmlFor="friday" className='!bg-[#3375F5] !text-[#FFFFFF]'>F</label>
                          :
                          <label htmlFor="friday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >F</label>
                      }
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="saturday"
                        name="saturday"
                        value={day.saturday}
                        onChange={(event) => changeDay('saturday', event.target.value)}
                      />
                      {
                        day.saturday ?
                          <label htmlFor="saturday" className='!bg-[#3375F5] !text-[#FFFFFF]'>S</label>
                          :
                          <label htmlFor="saturday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >S</label>
                      }
                    </li>
                  </ul>
                </div>
                <div className="absolute bottom-0 right-[0]">
                  <button
                    className="btn btn-outline"
                    onClick={changePage.bind(this, 'cabang')}
                  >
                    Selanjutnya
                  </button>
                </div>
              </div>
              <div
                className={
                  page === 'cabang' ? 'branch h-full relative' : 'hidden'
                }
              >
                <div className="title">
                  <h3>CABANG</h3>
                </div>
                <div className="branch-list gap-y-[27px] flex flex-wrap">
                  <div className="basis-full max-h-[40vh] overflow-auto">
                    <div className="mb-[24px]">
                      <input type="text" placeholder="SEARCH" value={search} onChange={e => searchCabang(e.target.value)} />
                    </div>
                    <BranchList />
                  </div>
                </div>
                <div className="absolute bottom-0 left-[0] w-full flex justify-between">
                  <button
                    className="btn btn-outline"
                    onClick={changePage.bind(this, 'jadwal')}
                  >
                    SEBELUMNYA
                  </button>

                  <button
                    className="btn btn-outline"
                    onClick={changePage.bind(this, 'device')}
                  >
                    Selanjutnya
                  </button>
                </div>
              </div>
              <div
                className={
                  page === 'device' ? 'device h-full relative' : 'hidden'
                }
              >
                <div className="title">
                  <h3>PERANGKAT</h3>
                </div>
                <div className="branch-list max-h-[40vh] overflow-auto">
                  {branchDevice}
                </div>
                <div className="absolute bottom-0 left-[0] w-full flex justify-between">
                  <button
                    className="btn btn-outline"
                    onClick={changePage.bind(this, 'cabang')}
                  >
                    SEBELUMNYA
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="fixed w-screen h-screen top-0 left-0 toggle-menu flex hidden"
        id="popup"
      >
        <SingleImagePopupLogo isSingleImageOnLogo={isSingleImageOnLogo} togglePopup={togglePopup} updateSingleImageListLogo={updateSingleImageListLogo} />
        <ApiPopup
          isApiOn={isApiOn}
          togglePopup={togglePopup}
          api={api}
          updateData={updateData}
          apiDataMutation={apiDataMutation}
          integrateApiMutation={integrateApiMutation}
          DBList={DBList}
          warehouseList={warehouseList}
          isFetchingDB={isFetchingDB}
        />
      </div>
      <Preview10
        singleImages={singleImagesLogo}
        api={api}
        changePreview={changePreview} preview={preview} />
    </div>
  )
}
