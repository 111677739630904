import React, { useState } from 'react'
import Skrin from "../../assets/images/logo/skrin.png"
import { Instagram, Twitter, Facebook, Youtube } from "../../assets/svg/SocialMedia"
import Icon from "../../assets/images/login/icon.svg"
import Slider from "../../components/slider.jsx"
import ApiService from '../../services/api.service'
import Alert from '../../components/alert'
import { Link, useParams, useSearchParams } from "react-router-dom";


export default function ResetPassword() {
    const { token } = useParams();
    const [ searchParams ] = useSearchParams();
    const [message, setMessage] = useState('Periksa kembali data anda.')
    const [type, setType] = useState('error')
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setConfirmationPassword] = useState("")

    function setBannerNotification(type, message, redirect) {
        setType(type)
        setMessage(message)
        var alert = document.getElementById('alert');
        alert.classList.toggle('hidden');
        alert.classList.toggle('opacity-[0]')

        setTimeout(() => {
            alert.classList.toggle('opacity-[0]')
        }, 2000)

        setTimeout(() => {
            alert.classList.toggle('hidden');
            if (redirect) window.location = redirect
        }, 2500)
    }

    function reset() {
        ApiService
            .post(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/api/reset-password', {
                token: token,
                email: searchParams.get('email'),
                password: password,
                password_confirmation: passwordConfirmation,
            })
            .then((response) => {
                setBannerNotification('success', 'Berhasil mengganti password anda', '/login')
            })
            .catch((error) => {
                ApiService
                    .post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/reset-password', {
                        token: token,
                        email: searchParams.get('email'),
                        password: password,
                        password_confirmation: passwordConfirmation,
                    })
                    .then((response) => {
                        setBannerNotification('success', 'Berhasil mengganti password anda', '/login')
                    })
                    .catch((error) => {
                        setBannerNotification('error', 'Silahkan periksa kembali password anda')
                    })
            })
    }
    return (
        <div className="w-screen flex h-screen forgot-password">
            <div className='bg-[#27AE61] hidden md:flex items-center justify-center h-screen left'>
                <div className="w-[308px]">
                    <div>
                        <img src={Icon} className="-ml-[24px]" alt="" />
                    </div>
                    <Slider />
                    <div className='flex mt-[26.83px]'>
                        <a href="https://www.youtube.com/channel/UCtQgKQgDQlHS3sO_LTxxh0Q"><Youtube /></a>
                        <a href="https://www.instagram.com/awandigitalindonesia/"><Instagram/></a>
                        <a href="https://web.facebook.com/profile.php?id=100077383916860"><Facebook /></a>
                        <a href="https://twitter.com/AdiMaketing"><Twitter /></a>
                    </div>
                </div>
            </div>
            <div className='right h-screen relative'>
                <div className='absolute top-[50px] w-full mx-auto flex justify-center hidden opacity-[0]' id="alert">
                    <Alert type={type} msg={message} />
                </div>
                <div className='px-[20px] md:px-[39.73px] pt-[30.66px]'>
                    <Link to="/" className="/">
                        <img src={Skrin} alt="" />
                    </Link>
                </div>
                <div className='flex justify-center'>
                    <div className='form-login'>
                        <div className='title'>
                            <h3>Password Baru Anda</h3>
                            <p>Kami bantu pulihkan akunmu kembali</p>
                        </div>
                    
                        <div className='mb-[31px]'>
                            <label htmlFor="password">Password</label>
                            <div className='relative'>
                                <div className='svg-password'></div>
                                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className='input input-bordered w-full pl-[50px]' placeholder='Masukan Password' />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password">Konfirmasi Password</label>
                            <div className='relative'>
                                <div className='svg-password'></div>
                                <input type="password" id="konfirmasiPassword" value={passwordConfirmation} onChange={(e) => setConfirmationPassword(e.target.value)} className='input input-bordered w-full pl-[50px]' placeholder='Ketik Ulang Password' />
                            </div>
                        </div>

                        <div className='action'>
                            <button className='btn w-full' onClick={reset.bind(this)}>
                               Continue
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
