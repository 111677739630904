import React, { useState, useEffect } from 'react';
import Profile from "../../assets/images/profile/profile.png"
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";
import ApiService from '../../services/api.service'
import Pencil from '../../components/icon/Pencil'
import Trash from '../../components/icon/Trash'
import Dot from '../../components/icon/Dot'
import utilitiesUnstyled from 'daisyui/dist/utilities-unstyled';
import HeaderDropdown from "../../components/menu/HeaderDropdown"

export default function Detail() {
    const { id } = useParams();
    const [toggleAddDevice, setToggleAddDevice] = useState(false)
    const [deviceList, setDeviceList] = useState([])
    const [device, setDevice] = useState({
        'branch_id': id,
        'name': '',
        'type': '',
        'status': 'Aktif',
    })
    const [isToggleDelete, setIstToggleDelete] = useState([])
    const [deleteId, setDeleteId] = useState()
    const [cabang, setCabang] = useState({})

    function toggleDevice() {
        setToggleAddDevice(!toggleAddDevice)
    }

    useEffect(() => {
        ApiService.init()
        ApiService
            .get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch/' + id)
            .then((response) => {
                setDeviceList(response.data.devices)
                setCabang({
                    'city': response.data.city,
                    'province': response.data.province,
                    'district': response.data.district,
                    'address': response.data.address,
                    'name': response.data.name,
                })
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }, [id])

    function updateData(attribute, value) {
        const newDevice = { ...device }
        newDevice[attribute] = value
        setDevice({ ...newDevice })
    }

    function saveDevice() {
        ApiService.init()
        ApiService
            .post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/device',
                device
            )
            .then((response) => {
                window.location = '/cabang/detail/' + id
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }

    let devices = []
    for (const [index, item] of deviceList.entries()) {
        devices.push(
            <div className="collapse static" key={'devices-' + index}>
                <input type="checkbox" className="peer" />
                <div className="collapse-title flex flex-wrap items-center">
                    <div className='basis-2/12 flex'>
                        {/* <svg className="arrow-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9383 11.2992L14.9396 11.3006L18.1496 14.5106C18.3585 14.7195 18.2098 15.0702 17.9199 15.0702H11.6899H6.07995C5.79266 15.0702 5.64662 14.7142 5.85028 14.5106L11.0303 9.33056C11.5672 8.79364 12.4419 8.79346 12.9791 9.33C12.9792 9.33019 12.9794 9.33038 12.9796 9.33056L14.9383 11.2992Z" fill="#3375F5" stroke="#1A67FB" strokeWidth="1.5" />
                        </svg> */}
                        <h4 className='nama-cabang'>
                            {item.name}
                        </h4>
                    </div>
                    <div className='basis-4/12'>
                        {item.type}
                    </div>
                    <div className='basis-5/12'>
                        <div className={item.status === 'Tidak Aktif' ? 'status inactive' : 'status active'}>
                            {item.status}
                        </div>
                    </div>
                    <div className='basis-1/12 flex justify-end'>
                        <div className="dropdown dropdown-end !absolute">
                            <label tabIndex={0} className="cursor-pointer bg-[gray]">
                                <div className="w-10 rounded-full flex justify-end">
                                    <Dot />
                                </div>
                            </label>
                            <ul tabIndex={0} className="px-[16px] py-[8px] shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-[254px] h-[113px]">
                                <li className='border-b-[1px] border-[rgba(173,173,173,0.3)] pb-[8px]'>
                                    <Link to={"/cabang/detail/" + item.id} className='flex flex-wrap justify-between'>
                                        <div className=''>Ubah</div>
                                        <div className=''>
                                            <Pencil />
                                        </div>
                                    </Link>
                                </li>
                                <li className='pt-[8px]'>
                                    <div className='flex flex-wrap justify-between' onClick={toggleDelete.bind(this, item.id)}>
                                        <div>
                                            Hapus
                                        </div>
                                        <div>
                                            <Trash />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="collapse-content">
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th className='rounded-[0]'>Nama Campaign</th>
                                <th>Durasi</th>
                                <th>Status</th>
                                <th>User</th>
                                <th></th>
                                <th className='rounded-[0]'></th>
                            </tr>
                        </thead>
                        <tbody>
                            <DeviceDetail campaigns={item.campaigns} id={item.id} type={'campaign'} />
                        </tbody>
                    </table>
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th className='rounded-[0]'>Nama Meetings</th>
                                <th>Durasi</th>
                                <th>Status</th>
                                <th>User</th>
                                <th></th>
                                <th className='rounded-[0]'></th>
                            </tr>
                        </thead>
                        <tbody>
                            <DeviceDetailMeeting campaigns={item.meetings} id={item.id} type={'meeting'} />
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    function DeviceDetail(props) {
        let campaignList = [], i = 0;
        if (props.campaigns != undefined) {
            while (i < props.campaigns.length) {
                let campaign = props.campaigns[i];
                campaignList.push(
                    <tr key={'index-campaign-' + i + '-' + campaign.id}>
                        <td>{campaign.name}</td>
                        <td>
                            <div className='flex gap-x-[4px]'>
                                <div>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.8333 9.33333C13.8333 12.5533 11.22 15.1667 7.99996 15.1667C4.77996 15.1667 2.16663 12.5533 2.16663 9.33333C2.16663 6.11333 4.77996 3.5 7.99996 3.5C11.22 3.5 13.8333 6.11333 13.8333 9.33333Z" fill="white" stroke="#292D32" strokeOpacity="0.64" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8 5.8335V9.16683" stroke="#292D32" strokeOpacity="0.64" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 1.8335H10" stroke="#292D32" strokeOpacity="0.64" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    {campaign.duration}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={campaign.status === 'active' ? 'status active' : 'status inactive'}>
                                {campaign.status}
                            </div>
                            <p className='last-update'>
                                Last update: {campaign.campaignLastUpdate}
                            </p>
                        </td>
                        <td>
                            <p className='name'>
                                {campaign.user.name}
                            </p>
                            <p className='email'>
                                {campaign.user.email}
                            </p>
                        </td>
                        <td>
                            <div className='flex items-center copy-link gap-x-[4px]'>
                                <div>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99335 12.1668H11C13.0133 12.1668 14.6667 10.5202 14.6667 8.50016C14.6667 6.48683 13.02 4.8335 11 4.8335H9.99335" stroke="#3375F5" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.99998 4.8335H4.99998C2.97998 4.8335 1.33331 6.48016 1.33331 8.50016C1.33331 10.5135 2.97998 12.1668 4.99998 12.1668H5.99998" stroke="#3375F5" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.33331 8.5H10.6666" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <button className={props.type === 'campaign' ? 'block' : 'hidden'} onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_DOMAIN}/view/${campaign.templateId}/${campaign.campaignId}/${campaign.url}`)}>
                                        SALIN LINK
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2" cy="16" r="2" transform="rotate(-90 2 16)" fill="#3375F5" fillOpacity="0.5" />
                                <circle cx="2" cy="9" r="2" transform="rotate(-90 2 9)" fill="#3375F5" fillOpacity="0.5" />
                                <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#3375F5" fillOpacity="0.5" />
                            </svg>
                        </td>
                    </tr>
                )
                i++;
            }
        }

        return campaignList;
    }

    function DeviceDetailMeeting(props) {
        let campaignList = [], i = 0;
        if (props.campaigns != undefined) {
            while (i < props.campaigns.length) {
                let campaign = props.campaigns[i];
                campaignList.push(
                    <tr key={'index-campaign-' + i + '-' + campaign.id}>
                        <td>{campaign.name}</td>
                        <td>
                            <div className='flex gap-x-[4px]'>
                                <div>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.8333 9.33333C13.8333 12.5533 11.22 15.1667 7.99996 15.1667C4.77996 15.1667 2.16663 12.5533 2.16663 9.33333C2.16663 6.11333 4.77996 3.5 7.99996 3.5C11.22 3.5 13.8333 6.11333 13.8333 9.33333Z" fill="white" stroke="#292D32" strokeOpacity="0.64" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8 5.8335V9.16683" stroke="#292D32" strokeOpacity="0.64" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 1.8335H10" stroke="#292D32" strokeOpacity="0.64" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    {campaign.duration}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={campaign.status === 'active' ? 'status active' : 'status inactive'}>
                                {campaign.status}
                            </div>
                            <p className='last-update'>
                                Last update: {campaign.campaignLastUpdate}
                            </p>
                        </td>
                        <td>
                            <p className='name'>
                                {campaign.user.name}
                            </p>
                            <p className='email'>
                                {campaign.user.email}
                            </p>
                        </td>
                        <td>
                            <div className='flex items-center copy-link gap-x-[4px]'>
                                <div>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99335 12.1668H11C13.0133 12.1668 14.6667 10.5202 14.6667 8.50016C14.6667 6.48683 13.02 4.8335 11 4.8335H9.99335" stroke="#3375F5" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.99998 4.8335H4.99998C2.97998 4.8335 1.33331 6.48016 1.33331 8.50016C1.33331 10.5135 2.97998 12.1668 4.99998 12.1668H5.99998" stroke="#3375F5" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.33331 8.5H10.6666" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <button className='block' onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_DOMAIN}/view/${campaign.meetingId}/${campaign.url}`)}>
                                        SALIN LINK
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2" cy="16" r="2" transform="rotate(-90 2 16)" fill="#3375F5" fillOpacity="0.5" />
                                <circle cx="2" cy="9" r="2" transform="rotate(-90 2 9)" fill="#3375F5" fillOpacity="0.5" />
                                <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#3375F5" fillOpacity="0.5" />
                            </svg>
                        </td>
                    </tr>
                )
                i++;
            }
        }

        return campaignList;
    }

    function toggleDelete(value) {
        if (isToggleDelete === true) {
            setIstToggleDelete(false)
        } else {
            setDeleteId(value)
            setIstToggleDelete(true)
        }
    }

    function deleteBranch() {
        ApiService.init()
        ApiService
            .delete(process.env.REACT_APP_BACKEND_DOMAIN + '/api/device/' + deleteId)
            .then((response) => {
                window.location = '/cabang/detail/' + response.data.id
            })
            .catch((error) => {
                // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
    }

    return (
        <div className="w-full min-h-[100vh] h-fit bg-white cabang-detail">
            <div className='top-content'>
                <div className='navbar flex flex-wrap justify-end'>
                    <div className="">
                        <div className="flex justify-center">
                            <button className="btn btn-transparent btn-menu">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.0001 5.85014C20.0001 7.20876 18.8987 8.31014 17.5401 8.31014C16.1815 8.31014 15.0801 7.20876 15.0801 5.85014C15.0801 4.49152 16.1815 3.39014 17.5401 3.39014C18.8987 3.39014 20.0001 4.49152 20.0001 5.85014Z" fill="#3375F5" stroke="#3375F5" />
                                    <path d="M8.92 5.85014C8.92 7.20876 7.81862 8.31014 6.46 8.31014C5.10138 8.31014 4 7.20876 4 5.85014C4 4.49152 5.10138 3.39014 6.46 3.39014C7.81862 3.39014 8.92 4.49152 8.92 5.85014Z" fill="#3375F5" stroke="#3375F5" />
                                    <path d="M20.0001 18.1499C20.0001 19.5086 18.8987 20.6099 17.5401 20.6099C16.1815 20.6099 15.0801 19.5086 15.0801 18.1499C15.0801 16.7913 16.1815 15.6899 17.5401 15.6899C18.8987 15.6899 20.0001 16.7913 20.0001 18.1499Z" fill="#3375F5" stroke="#3375F5" />
                                    <path d="M6.46 21.1099C8.09476 21.1099 9.42 19.7847 9.42 18.1499C9.42 16.5152 8.09476 15.1899 6.46 15.1899C4.82524 15.1899 3.5 16.5152 3.5 18.1499C3.5 19.7847 4.82524 21.1099 6.46 21.1099Z" fill="#3375F5" />
                                </svg>
                            </button>
                        </div>
                        <HeaderDropdown/>
                    </div>
                </div>
                <div className='content'>
                    <div className='header flex flex-wrap'>
                        <div className='basis-4/12 title '>
                            <Link to="/cabang" className="flex items-center gap-x-[4px]">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.165 6.91846L4.08337 14.0001L11.165 21.0818" stroke="#1A67FB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M23.9166 14H4.28162" stroke="#1A67FB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <h3>Cabang {cabang.name}</h3>
                            </Link>
                        </div>
                        {/* <div className='basis-5/12 search flex items-center'>
                            <div>
                                <h3>
                                    CARI
                                </h3>
                            </div>
                        </div> */}
                    </div>
                    <div className='location flex items-center'>
                        <div className='basis-10/12'>
                            <div className='flex pin'>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00004 9.45346C9.1488 9.45346 10.08 8.52221 10.08 7.37346C10.08 6.2247 9.1488 5.29346 8.00004 5.29346C6.85129 5.29346 5.92004 6.2247 5.92004 7.37346C5.92004 8.52221 6.85129 9.45346 8.00004 9.45346Z" stroke="#3375F5" />
                                    <path d="M2.4133 6.16016C3.72664 0.386831 12.28 0.393498 13.5866 6.16683C14.3533 9.5535 12.2466 12.4202 10.4 14.1935C9.05997 15.4868 6.93997 15.4868 5.5933 14.1935C3.7533 12.4202 1.64664 9.54683 2.4133 6.16016Z" stroke="#3375F5" />
                                </svg>
                                <h3>Lokasi Cabang</h3>
                            </div>
                            <div className='province'>
                                <h3>{cabang.province}, {cabang.district}, {cabang.city}</h3>
                            </div>
                            <div className='street'>
                                {cabang.address}
                            </div>
                        </div>
                        <div className='flex-2/12'>
                            <button className='btn btn-transparent btn-outline' onClick={toggleDevice.bind(this)}>
                                + Tambah Perangkat
                            </button>
                        </div>

                    </div>
                    <div className=''>
                        <div className='action flex px-[40px]'>
                            <div className='basis-6/12'>
                                <h3>Daftar Perangkat</h3>
                            </div>
                        </div>

                        <div className='collapse-list pb-[24px]'>
                            {devices}
                        </div>
                    </div>
                </div>
            </div>
            <div className={toggleAddDevice === true ? 'fixed flex w-screen h-screen top-0 left-0 toggle-menu' : 'hidden'}>
                <div>
                    <div className='title flex flex-wrap items-center'>
                        <div className='basis-10/12'>
                            <h3>Tambah Perangkat</h3>
                        </div>
                        <div className='basis-2/12 flex justify-end'>
                            <button className='btn btn-transparent mr-0' onClick={toggleDevice.bind(this)}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z" fill="black" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='content'>
                        <div>
                            <label htmlFor="namaPerangkat">Nama Perangkat</label>
                            <input type="text" className="text" name="namaPerangkat" id="" value={device.name} onChange={(e) => updateData('name', e.target.value)} />
                        </div>

                        <div>
                            <label htmlFor="tipePerangkat">Tipe Perangkat</label>
                            <input type="text" className="text" name="tipePerangkat" id="" value={device.type} onChange={(e) => updateData('type', e.target.value)} />
                        </div>

                        <div>
                            <label htmlFor="tipePerangkat">STATUS</label>
                            <select className="select w-full" value={device.status} onChange={(e) => updateData('status', e.target.value)}>
                                <option disabled>Pilih status</option>
                                <option>Aktif</option>
                                <option>Tidak Aktif</option>
                            </select>
                        </div>

                        <div>
                            <button className='btn w-full' onClick={saveDevice.bind(this)}> SIMPAN </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={isToggleDelete === true ? 'delete-popup fixed bg-[rgba(41,45,50,0.5)] top-[0] h-[100vh] w-[100vw] -ml-[16rem] flex justify-center' : '!hidden'} id="deleteForm">
                <div className="">
                    <div className="border-b-[0.5px] border-[#D9D9D9]">
                        <div className="flex justify-between px-[32px] items-center !h-[80px]">
                            <h3>Hapus Cabang</h3>
                            <button className="btn btn-transparent !p-0" onClick={toggleDelete.bind(this)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.9996 10.5858L18.3635 4.22183L19.7777 5.63604L13.4138 12L19.7777 18.364L18.3635 19.7782L11.9996 13.4142L5.6356 19.7782L4.22138 18.364L10.5853 12L4.22138 5.63604L5.6356 4.22183L11.9996 10.5858Z" fill="black" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="px-[32px]">
                        <h4>
                            Apakah Anda yakin akan menghapus cabang?
                        </h4>
                        <div className="grid grid-cols-2 gap-x-[26px]">
                            <button className="btn-outline" onClick={toggleDelete.bind(this)}>BATAL</button>
                            <button className="btn" onClick={deleteBranch.bind(this)}>HAPUS</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
