import React from "react";

export default function Add() {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.6665 6.99984C1.6665 4.06181 4.06181 1.6665 6.99984 1.6665C9.93786 1.6665 12.3332 4.06181 12.3332 6.99984C12.3332 9.93786 9.93786 12.3332 6.99984 12.3332C4.06181 12.3332 1.6665 9.93786 1.6665 6.99984ZM7.93734 9.33317V7.93734H9.33317C9.84848 7.93734 10.2707 7.51515 10.2707 6.99984C10.2707 6.48453 9.84848 6.06234 9.33317 6.06234H7.93734V4.6665C7.93734 4.15119 7.51515 3.729 6.99984 3.729C6.48453 3.729 6.06234 4.15119 6.06234 4.6665V6.06234H4.6665C4.15119 6.06234 3.729 6.48453 3.729 6.99984C3.729 7.51515 4.15119 7.93734 4.6665 7.93734H6.06234V9.33317C6.06234 9.84848 6.48453 10.2707 6.99984 10.2707C7.51515 10.2707 7.93734 9.84848 7.93734 9.33317Z"
                fill="white"
                stroke="white"
            />
        </svg>
    );
}