import React from "react";

export default function Active() {
    return (
        <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M27.75 9C27.75 11.925 25.425 14.25 22.5 14.25H7.5C4.575 14.25 2.25 11.925 2.25 9C2.25 6.075 4.575 3.75 7.5 3.75H22.5C25.425 3.75 27.75 6.075 27.75 9Z" fill="#1A67FB" fillOpacity="0.46" />
            <g filter="url(#filter0_dd_1284_40545)">
                <circle cx="20.5" cy="8.5" r="7.5" fill="#3375F5" />
                <circle cx="20.5" cy="8.5" r="7.25" stroke="url(#paint0_linear_1284_40545)" strokeWidth="0.5" />
                <circle cx="20.5" cy="8.5" r="7.25" stroke="url(#paint1_linear_1284_40545)" strokeWidth="0.5" />
            </g>
            <defs>
                <filter id="filter0_dd_1284_40545" x="12" y="0" width="17" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.237602 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1284_40545" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_1284_40545" result="effect2_dropShadow_1284_40545" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1284_40545" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_1284_40545" x1="13.0734" y1="1" x2="13.0734" y2="15.8532" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.01" />
                    <stop offset="0.8" stopOpacity="0.02" />
                    <stop offset="1" stopOpacity="0.04" />
                </linearGradient>
                <linearGradient id="paint1_linear_1284_40545" x1="13" y1="1" x2="13" y2="16" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.12" />
                    <stop offset="0.2" stopColor="white" stopOpacity="0.06" />
                    <stop offset="1" stopColor="white" stopOpacity="0.01" />
                </linearGradient>
            </defs>
        </svg>

    );
}