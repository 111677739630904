import React from "react";

export default function Trash() {
    return (
        <svg
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.1108 6.09337C16.1508 5.80004 13.1731 5.64893 10.2042 5.64893C8.44417 5.64893 6.68417 5.73781 4.92417 5.91559L3.11084 6.09337"
                fill="#96A0AA"
            />
            <path
                d="M19.1108 6.09337C16.1508 5.80004 13.1731 5.64893 10.2042 5.64893C8.44417 5.64893 6.68417 5.73781 4.92417 5.91559L3.11084 6.09337"
                stroke="#96A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 5.19566L8.19556 4.03122C8.33778 3.18678 8.44444 2.55566 9.94667 2.55566H12.2756C13.7778 2.55566 13.8933 3.22233 14.0267 4.04011L14.2222 5.19566"
                fill="#96A0AA"
            />
            <path
                d="M8 5.19566L8.19556 4.03122C8.33778 3.18678 8.44444 2.55566 9.94667 2.55566H12.2756C13.7778 2.55566 13.8933 3.22233 14.0267 4.04011L14.2222 5.19566"
                stroke="#96A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1998 8.90234L16.622 17.8535C16.5242 19.249 16.4442 20.3335 13.9642 20.3335H8.25753C5.77753 20.3335 5.69753 19.249 5.59975 17.8535L5.02197 8.90234"
                stroke="#96A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.62646 15.4443H12.5865"
                stroke="#96A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.88867 11.8892H13.3331"
                stroke="#96A0AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}