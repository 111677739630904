import '../../view-template1-grid.css';
import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  QueryClient,
  useQuery,
} from "react-query";
import moment from 'moment';
import { toastSuccess, toastError } from '../../components/toast';
import ToastInfo from '../../components/icon/ToastInfo';
import ShimmerLoading from '../../components/shimmerLoading';
import ProgressBar from '../../components/ProgressBar';

export default function Template10() {
  const userEmail = localStorage.getItem('email')

  const { id, code } = useParams();
  const [campaign, setCampaign] = useState([])
  const [visible, setVisible] = useState(true)
  const [notification, setNotification] = useState({})
  const [currentTime, setCurrentTime] = useState(new Date())
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date())
  const itemsPerPage = 15;
  // const dataStaleTime = 2 * (60 * 1000);
  const dataStaleTime = 5 * (60 * 1000);
  const [loadingPercentage, setLoadingPercentage] = useState(0)
  const [refetchCount, setRefetchCount] = useState(0)

  useEffect(() => {
    fetchData()

    const fetchDataInterval = setInterval(() => {
      fetchData();
    }, 300000);

    const currentTimeInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    const refetchListStockInterval = setInterval(() => {
      setLastUpdatedAt(new Date())
      setRefetchCount(refetchCount + 1)
      console.log('data was staled at ' + new Date())
      refetchApi()
    }, dataStaleTime);

    return () => {
      clearInterval(fetchDataInterval);
      clearInterval(currentTimeInterval);
      clearInterval(refetchListStockInterval);
    };
  }, []);

  const fetchData = () => {
    const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let day = [], startTime = null, endTime = null;

    const d = new Date();
    let dayName = weekday[d.getDay()];

    var seconds = d.getSeconds();
    var minutes = d.getMinutes();
    var hour = ("0" + d.getHours()).slice(-2);

    let times = hour + ':' + minutes + ':' + seconds;

    ApiService.init()
    ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign/${id}/preview?code=${code}`)
      .then((response) => {
        setCampaign({ ...response.data.data });
        let weekList = response.data.data.workdays;

        if (weekList['sunday'] === true) {
          day.push('sunday')
        }

        if (weekList['monday'] === true) {
          day.push('monday')
        }

        if (weekList['tuesday'] === true) {
          day.push('tuesday')
        }

        if (weekList['wednesday'] === true) {
          day.push('wednesday')
        }

        if (weekList['thursday'] === true) {
          day.push('thursday')
        }

        if (weekList['friday'] === true) {
          day.push('friday')
        }

        if (weekList['saturday'] === true) {
          day.push('saturday')
        }

        startTime = response.data.data.startTime;
        endTime = response.data.data.endTime;

        if (day.includes(dayName) === true) {
            // console.log(times)
            // console.log(endTime)
            // console.log(times > endTime)
          if (times > startTime && times < endTime) {
            setVisible(true)
          } else if (times > endTime) {
            setVisible(false)
            setNotification({
              status: 'info',
              message: 'Waktu tayang selesai',
            })
          } else {
            setVisible(false)
          setNotification({
              status: 'info',
              message: 'Waktu tayang belum dimulai',
            })
          }
        } else {
          setVisible(false)
          setNotification({
            status: 'info',
            message: 'Waktu tayang belum dimulai',
          })
        }
      })
      .catch((err) => {
        if (err?.response?.status == 403) {
          const res = err.response.data.data;

          setVisible(false)
          if (times > res.endTime) {
          setNotification({
              status: 'info',
              message: 'Waktu tayang selesai',
            })
          } else {
          setNotification({
              status: 'info',
              message: 'Waktu tayang belum dimulai',
            })
          }
        }
      })
  }

  const queryClient = new QueryClient();
  const {
    data: listStockApiData,
    isFetching: isFetchingApi,
    refetch: refetchApi,
  } = useQuery(["data-api"], () => fetchApiData(), {
    staleTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const fetchDummyApiData = async () => {
    let listStockData = [];
    let pageIndex = 1;
    
    let res;
    while (pageIndex <= 79) {
      pageIndex++;
      await new Promise(resolve => setTimeout(resolve, 0));

      listStockData = listStockData.concat({
        "no": "PE04.086.00.080",
        "quantity": 100000000,
        ...(refetchCount == 0 ? {"name": "Test Item Refetch"} : {"name": "Test Item"}),
        "id": 5998,
        "upcNo": "PE04.086.00.080",
        "quantityInAllUnit": "0 CTN"
      })

      const percentage = Math.floor((pageIndex / 79) * 100);
      setLoadingPercentage(percentage);
    }

    setCurrentPage(1)

    return listStockData;
  };

  const fetchListStockPerPage = async (page) => {
    const response = await ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/get-list-stock`, {
      'email': userEmail,
      'warehouseName': 'ANC-STOCK-HO',
      'page': page,
      'pageSize': 100,
      'sort': 'name|asc'
    });

    const res = response.data.data;
    const status = res.s;

    if (status) {
      return res;
    }
  }

  const fetchApiData = async () => {
    if (Object.keys(campaign).length !== 0) {
      try {
        // const response = await ApiService.get(campaign.campaignDetails[1][0].value);
        let listStockData = [];
        let pageIndex = 1;
        
        let res;
        while (res = await fetchListStockPerPage(pageIndex)) {
          if (res.d.length === 0) {
            break;
          }

          pageIndex++;

          const data = res.d;
          const filteredRes = data.filter((el) => el.quantity !== 0);
          listStockData = listStockData.concat(filteredRes);

          if (res.sp.pageCount !== 0) {
            const percentage = Math.floor((pageIndex / res.sp.pageCount) * 100);
            setLoadingPercentage(percentage);
          }
        }

        return listStockData;
      } catch (error) {
        if (error.response?.status === 404) {
          toastError('API dengan url tersebut tidak ditemukan.');
        } else {
          toastError('Terjadi kesalahan saat mengambil data dari API.');
        }
      } finally {
        setCurrentPage(1)
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    refetchApi()
  }, [campaign])

  function RenderTimestamp() {
    const day = currentTime.toLocaleString('en-US', { weekday: 'long' });
    const date = currentTime.toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    const time = currentTime.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });

    if (Object.keys(campaign).length !== 0) {
      return (
        <div className="flex items-center font-bold">
          <div className="flex flex-col items-end pe-4 me-4 border-black border-r-2 text-3xl">
            <span>{day}</span>
            <span>{date}</span>
          </div>
          <h4 className="text-3xl">{time}</h4>
        </div>
      )
    }
  }

  function RenderBottomSection() {
    const renderPagination = () => {
      const pages = [];

      for (let i = 1; i <= totalPages; i++) {
        // if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
        //   pages.push(i);
        // } else if (pages[pages.length - 1] !== '...') {
        //   pages.push('...');
        // }
        // if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
          pages.push(i);
        // }
      }

      return (
        <div className="flex items-center gap-2">
          {pages.length > 1 ? pages.map((page, index) => (
            <div
              key={index}
              onClick={() => handlePageChange(page)}
              className={`inline-block w-4 h-4 rounded-full cursor-pointer ${page === currentPage ? 'bg-blue-500' : 'border-2 border-blue-500'}`}
            ></div>
          )) : null}
        </div>
      )
    };

    if (Object.keys(campaign).length !== 0) {
      return (
        <div className="flex justify-between px-[24px] py-[24px] h-auto text-2xl font-bold">
          {"Last Updated : " + moment(lastUpdatedAt, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY HH:mm:ss")}
          {renderPagination()}
        </div>
      )
    }
  }

  useEffect(() => {
    setTotalPages(Math.ceil(listStockApiData?.length / itemsPerPage));
  }, [listStockApiData]);

  function RenderTable() {
    const currentItemsPerPage = listStockApiData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

    if (Object.keys(campaign).length !== 0) {
      return (
        <table className={`w-full ${(isFetchingApi && listStockApiData == undefined) || currentItemsPerPage?.length >= itemsPerPage ? 'h-full' : null}`}>
          <thead style={{
            color: campaign.campaignDetails[1][0].headerFontColor,
            backgroundColor: campaign.campaignDetails[1][0].headerColor ?? "#fff",
          }}>
            <tr>
              <th className="py-2 text-2xl uppercase"></th>
              <th className="py-2 text-2xl uppercase text-left">Item</th>
              <th className="py-2 text-2xl uppercase">Stock</th>
              <th className="py-2 text-2xl uppercase">Status</th>
            </tr>
          </thead>
          <tbody className="font-extrabold bg-white">
            {isFetchingApi && listStockApiData == undefined ? (
              <tr className="h-full">
                <td colSpan="4" className="h-full relative">
                  <ShimmerLoading/>
                  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                    <ProgressBar percentage={loadingPercentage}/>
                  </div>
                </td>
              </tr>
            ) : currentItemsPerPage?.map((obj, index) => (
                <tr key={index}>
                  <td className={`${currentItemsPerPage?.length >= itemsPerPage ? null : 'py-2' } w-[10%] text-3xl text-center`}>
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td className={`${currentItemsPerPage?.length >= itemsPerPage ? null : 'py-2' } w-[60%] text-3xl text-left`}>
                    {obj.name}
                  </td>
                  <td className={`${currentItemsPerPage?.length >= itemsPerPage ? null : 'py-2' } w-[10%] text-3xl text-center`}>
                    {obj.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className={`${currentItemsPerPage?.length >= itemsPerPage ? null : 'py-2' } w-[20%] text-3xl text-center uppercase ${obj.quantity > 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {obj.quantity > 0 ? 'available' : 'sold out'}
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
      )
    }
  }

  const handlePageChange = (newPage) => {
    if (typeof newPage === 'number') {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextPage = currentPage < totalPages ? currentPage + 1 : 1;
      handlePageChange(nextPage);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [currentPage, totalPages]);

  if (visible === false) {
    if (Object.keys(notification).length !== 0) {
      return (
        <div className={`px-4 py-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-max flex items-start gap-4 rounded-xl
            ${notification.status == 'success' ? 'bg-green-100 border-2 border-green-400' : notification.status == 'error' ? 'bg-red-100 border-2 border-red-400' : 'bg-blue-100 border-2 border-blue-400'}`}>
          <ToastInfo />
          <div className="flex flex-col">
            <span className="font-bold">{notification.status == 'success' ? 'Berhasil' : notification.status == 'error' ? 'Terjadi Kesalahan' : 'Informasi'}</span>
            <span>{notification?.message ?? '-'}</span>
          </div>
          <a href={`${process.env.REACT_APP_FRONTEND_DOMAIN}/campaign`} className="bg-white border-2 border-gray-300 rounded-lg px-4 py-2">
            Kembali
          </a>
        </div>
      )
    }
  } else {
    return (
      <div className="template template-preview flex flex-col justify-between gap-[20px] gap-y-[10px] overflow-hidden">
        <div className='max-h-[16vh] grid grid-cols-12 gap-x-[20px] rounded'>
          <div className="col-span-12 logo bg-white flex justify-between items-center h-[12vh] max-h-[12vh] h-[100%] px-[8px] py-[11px] rounded">
            {Object.keys(campaign).length !== 0 ? (
              <img
                alt="logo"
                className="max-w-full h-[100%] object-cover"
                src={process.env.REACT_APP_BACKEND_DOMAIN + "/storage/" + campaign.campaignDetails[0][0].gallery.value}
              />
            ) : null}
            <RenderTimestamp />
          </div>
        </div>
        <div className="col-span-12 h-full rounded overflow-auto bg-white">
          <RenderTable />
        </div>
        <div className='w-full bg-white rounded'>
          <RenderBottomSection />
        </div>
      </div>
    )
  }
}