import React, { useState } from 'react'
import Skrin from "../../assets/images/logo/skrin.png"
import { Instagram, Twitter, Facebook, Youtube } from "../../assets/svg/SocialMedia"
import Icon from "../../assets/images/login/icon.svg"
import Slider from "../../components/slider"
import { Link, useNavigate } from 'react-router-dom'
import ApiService from '../../services/api.service'
import JwtService from '../../services/jwt.service'
import Alert from '../../components/alert'
import { useEffect } from 'react'
import axios from 'axios'
import moment from "moment";

export default function Login() {
    const [remember, setRemember] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [customAlertMessage, setCustomAlertMessage] = useState('Akun Email atau Password Anda Salah.')
    const [isLoaded, setIsLoaded] = useState(false)

    const navigate = useNavigate()

    function login() {
        axios
            .post(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/oauth/token', {
                username: email,
                password: password,
                grant_type: 'password',
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET,
            })
            .then((response) => {
                if (response.status === 200) {
                    JwtService.saveToken(response.data.access_token)

                    ApiService.init()
                    ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/get-my-data', {})
                    // ApiService.get('http://localhost:8000' + '/api/get-my-data', {})
                        .then((response) => {
                          let signageUser = response.data.data.signage;
                          let role = signageUser.status;
                          let name = response.data.data.awd?.name ?? signageUser.name;
                          let email = response.data.data.awd?.email ?? signageUser.email;
                          let lisenceId = signageUser?.lisenceId;
                          let limitBranch = signageUser?.company?.maxBranch;
                          let limitDevice = signageUser?.company?.maxDevice;
                          let limitCampaign = signageUser?.company?.maxCampaign;
                          let limitMedia = signageUser?.company?.maxMedia;
                          let tanggal = signageUser?.company?.createdAt;
                          let tambahanMasaTrial = signageUser?.company?.masaTrial;

                          if (signageUser?.status !== "Superadmin" && signageUser?.idLisence === 1) {
                            let endDate = moment(signageUser?.company.createdAt);
                            let startDate = moment();

                            let diff = moment.duration(endDate.diff(startDate)).asDays();
                            let rentangHari = Math.abs(Math.round(diff));
                            if (rentangHari > 30 + signageUser?.company.masaTrial) {
                              // dispatch(showNotif())
                              ApiService.purgeAuth()
                              window.location = '/login'
                            }
                          }

                          localStorage.setItem("role", role);
                          localStorage.setItem("name", name);
                          localStorage.setItem("email", email);
                          localStorage.setItem("lisenceId", lisenceId);
                          localStorage.setItem("branch", limitBranch);
                          localStorage.setItem("device", limitDevice);
                          localStorage.setItem("campaign", limitCampaign);
                          localStorage.setItem("media", limitMedia);
                          localStorage.setItem("tanggal", tanggal);
                          localStorage.setItem("tambahanMasaTrial", tambahanMasaTrial);

                          navigate('/')
                        })
                        .catch((error) => {
                            // ApiService.purgeAuth();
                            setCustomAlertMessage("Terjadi kesalahan.")

                            var alert = document.getElementById('alert');
                            alert.classList.toggle('hidden');
                            alert.classList.toggle('opacity-[0]')

                            setTimeout(() => {
                                alert.classList.toggle('opacity-[0]')
                            }, 2000)

                            setTimeout(() => {
                                alert.classList.toggle('hidden');
                            }, 2500)

                        })
                }
            })
            .catch((awd_error) => {
                axios
                  .post(process.env.REACT_APP_BACKEND_DOMAIN + "/api/user/login", { email, password })
                  .then((response) => {
                        if (response.status === 200) {
                            JwtService.saveToken(response.data.accessToken)
                        }
                  })
                  .catch((error) => {
                    if (awd_error?.response?.status == 401) {
                        setCustomAlertMessage("Silahkan periksa email Anda untuk verifikasi.")
                    } else if (awd_error?.response?.status == 500) {
                        setCustomAlertMessage("Terjadi kesalahan.")
                    } else {
                        setCustomAlertMessage("Akun Email atau Password Anda Salah.")
                    }
                    var alert = document.getElementById('alert');
                    alert.classList.toggle('hidden');
                    alert.classList.toggle('opacity-[0]')

                    setTimeout(() => {
                        alert.classList.toggle('opacity-[0]')
                    }, 2000)

                    setTimeout(() => {
                        alert.classList.toggle('hidden');
                    }, 2500)
                  })
            })
    }

    useEffect(() => {
        if (isLoaded === false) {
            setIsLoaded(true)
            ApiService.init()
            ApiService
                .get(process.env.REACT_APP_AWD_BACKEND_DOMAIN + '/api/get-my-data?product_id=4')
                .then((response) => {
                    navigate('/')
                })
                .catch((error) => {
                    ApiService.purgeAuth()
                })
        }
    })
    return (
        <div className="w-screen md:flex h-screen login">
            <div className='bg-[#5557DB] hidden md:flex items-center justify-center h-screen left'>
                <div className='w-[308px]'>
                    <div>
                        <img src={Icon} className="-ml-[24px]" alt="" />
                    </div>
                    <Slider />
                    <div className='flex mt-[26.83px]'>
                        <a href="https://www.youtube.com/channel/UCtQgKQgDQlHS3sO_LTxxh0Q"><Youtube /></a>
                        <a href="https://www.instagram.com/awandigitalindonesia/"><Instagram/></a>
                        <a href="https://web.facebook.com/profile.php?id=100077383916860"><Facebook /></a>
                        <a href="https://twitter.com/AdiMaketing"><Twitter /></a>
                    </div>
                </div>
            </div>
            <div className='right h-screen relative'>
                <div className='absolute top-[50px] w-full mx-auto flex justify-center hidden opacity-[0]' id="alert">
                    <Alert type={'error'} msg={customAlertMessage} />
                </div>
                <div className='px-[20px] md:px-[39.73px] pt-[11px] md:pt-[30.66px]'>
                    <Link to="/" className="/">
                        <img src={Skrin} alt="" />
                    </Link>
                </div>
                <div className='flex justify-center'>
                    <div className='form-login'>
                        <div className='title'>
                            <h3>Login</h3>
                            <p>Selamat datang kembali di Skrin</p>
                        </div>

                        <div>
                            <label htmlFor="email">Email</label>
                            <div className='relative'>
                                <div className='svg-email'></div>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className='input input-bordered w-full pl-[50px]' />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password">Password</label>
                            <div className='relative'>
                                <div className='svg-password'></div>
                                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className='input input-bordered w-full pl-[50px]' />
                            </div>
                        </div>

                        <div className='flex justify-end'>
                            {/* <div className='remember-me cursor-pointer label' onClick={() => setRemember(!remember)}>
                                <input type="checkbox" checked={remember} className="checkbox" value={remember}  onChange={(e)=>{}}/>
                                <p className="label-text">Ingat saya</p>
                            </div> */}
                            <div className='forgot-password'>
                                <Link to="/forgot-password" className="/">
                                    Lupa Password?
                                </Link>
                            </div>
                        </div>

                        <div className='action'>
                            <button className='btn w-full' onClick={login.bind(this)}>
                                Login
                            </button>

                            <p>
                                Belum punya akun?
                                <Link to="/register" className="text-[#EB008B]">
                                    Daftar Sekarang
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
