import React from "react";
import './component.scss'
import { toast } from 'react-toastify';

function toastSuccess(msg, position = "right", toastId = null) {
    return toast.success(msg, {
        toastId: toastId,
        position: `top-${position}`,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
    });
}

function toastError(msg, position = "right", toastId = null) {
    return toast.error(msg, {
        toastId: toastId,
        position: `top-${position}`,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
    });
}

export { toastSuccess, toastError }