import '../../view-template1-grid.css';
import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  QueryClient,
  useQuery,
} from "react-query";
import moment from 'moment';
import { toastSuccess, toastError } from '../../components/toast';
import ToastInfo from '../../components/icon/ToastInfo';

export default function Template10() {
  const { id, code } = useParams();
  const [campaign, setCampaign] = useState([])
  const [visible, setVisible] = useState(true)
  const [notification, setNotification] = useState({})
  const [currentTime, setCurrentTime] = useState(new Date())
  const [stockListData, setStockListData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    fetchData()

    const fetchDataInterval = setInterval(() => {
      fetchData();
    }, 300000);

    const currentTimeInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(fetchDataInterval);
      clearInterval(currentTimeInterval);
    };
  }, []);

  const fetchData = () => {
    const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let day = [], startTime = null, endTime = null;

    const d = new Date();
    let dayName = weekday[d.getDay()];

    var seconds = d.getSeconds();
    var minutes = d.getMinutes();
    var hour = d.getHours();

    let times = hour + ':' + minutes + ':' + seconds;

    ApiService.init()
    ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign/${id}/preview?code=${code}`)
      .then((response) => {
        setCampaign({ ...response.data.data });
        let weekList = response.data.data.workdays;

        if (weekList['sunday'] === true) {
          day.push('sunday')
        }

        if (weekList['monday'] === true) {
          day.push('monday')
        }

        if (weekList['tuesday'] === true) {
          day.push('tuesday')
        }

        if (weekList['wednesday'] === true) {
          day.push('wednesday')
        }

        if (weekList['thursday'] === true) {
          day.push('thursday')
        }

        if (weekList['friday'] === true) {
          day.push('friday')
        }

        if (weekList['saturday'] === true) {
          day.push('saturday')
        }

        startTime = response.data.data.startTime;
        endTime = response.data.data.endTime;

        if (day.includes(dayName) === true) {
          console.log(times)
          console.log(endTime)
          console.log(times > endTime)
          if (times > startTime && times < endTime) {
            setVisible(true)
          } else if (times > endTime) {
            setVisible(false)
          setNotification({
              status: 'info',
              message: 'Waktu tayang selesai',
            })
          } else {
            setVisible(false)
          setNotification({
              status: 'info',
              message: 'Waktu tayang belum dimulai',
            })
          }
        } else {
          setVisible(false)
          setNotification({
            status: 'info',
            message: 'Waktu tayang belum dimulai',
          })
        }
      })
      .catch((err) => {
        if (err.response.status == 403) {
          const res = err.response.data.data;

          setVisible(false)
          console.log(times)
          console.log(res.endTime)
          console.log(times > res.endTime)
          if (times > res.endTime) {
          setNotification({
              status: 'info',
              message: 'Waktu tayang selesai',
            })
          } else {
          setNotification({
              status: 'info',
              message: 'Waktu tayang belum dimulai',
            })
          }
        }
      })
  }

  const queryClient = new QueryClient();
  const {
    data: apiData,
    isFetching: isFetchingApi,
    refetch: refetchApi,
  } = useQuery(["data-api"], () => fetchApiData(), {
    staleTime: 5 * (60 * 1000),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const fetchApiData = async () => {
    if (Object.keys(campaign).length !== 0) {
      try {
        const response = await ApiService.get(campaign.campaignDetails[1][0].value);
        return response.data;
      } catch (error) {
        if (error.response?.status === 404) {
          toastError('API dengan url tersebut tidak ditemukan.');
        } else {
          toastError('Terjadi kesalahan saat mengambil data dari API.');
        }
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    refetchApi()
  }, [campaign])

  function RenderLogo() {
    if (Object.keys(campaign).length !== 0) {
      return (
        <img alt="logo" className="max-w-full h-[100%] object-cover" src={process.env.REACT_APP_BACKEND_DOMAIN + "/storage/" + campaign.campaignDetails[0][0].gallery.value} />
      )
    }
  }

  function RenderTimestamp() {
    const day = currentTime.toLocaleString('en-US', { weekday: 'long' });
    const date = currentTime.toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    const time = currentTime.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });

    if (Object.keys(campaign).length !== 0) {
      return (
      <div className="flex items-center font-bold">
        <div className="flex flex-col items-end pe-2 me-2 border-black border-r-2">
          <span>{day}</span>
          <span>{date}</span>
        </div>
        <h4 className="text-xl">{time}</h4>
      </div>
    )
    }
  }

  function RenderBottomSection() {
    const renderPagination = () => {
      const pages = [];

      for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
          pages.push(i);
        } else if (pages[pages.length - 1] !== '...') {
          pages.push('...');
        }
      }

      return (
        <div className="flex items-center gap-2">
          {pages.map((page, index) => (
            <div
              key={index}
              onClick={() => handlePageChange(page)}
              className={`inline-block w-4 h-4 rounded-full ${page === currentPage ? 'bg-blue-500' : 'border-2 border-blue-500'}`}
            ></div>
          ))}
        </div>
      )
    };

    if (Object.keys(campaign).length !== 0) {
      return (
        <div className="flex justify-between px-[8px] overflow-hidden py-[11px] cursor-pointer">
          {"Last Updated : " + apiData?.lastUpdated ? moment(apiData?.lastUpdated, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY HH:mm:ss") : '-'}
          {renderPagination()}
        </div>
      )
    }
  }

  useEffect(() => {
    const itemsPerPage = 9;
    setTotalPages(Math.ceil(apiData?.items?.length / itemsPerPage));
  }, [apiData?.items]);

  function RenderTable() {
    const itemsPerPage = 9;

    if (Object.keys(campaign).length !== 0) {
      return (
        <table className='h-[100%] w-[100%] text-xl'>
          <thead style={{
            color: campaign.campaignDetails[1][0].headerFontColor,
            backgroundColor: campaign.campaignDetails[1][0].headerColor ?? "#fff",
          }}>
            <tr>
              <th className="py-2 uppercase">No.</th>
              <th className="py-2 uppercase">Item</th>
              <th className="py-2 uppercase">Stock</th>
              <th className="py-2 uppercase">Status</th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {apiData?.items
              ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((obj, index) => (
                <tr key={index}>
                  <td className="text-center">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td className="text-center">{obj.itemName}</td>
                  <td className="text-center">{obj.value}</td>
                  <td className={`text-center uppercase ${obj.status === 'available' ? 'text-green-500' : 'text-red-500'}`}>{obj.status}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )
    }
  }

  const handlePageChange = (newPage) => {
    if (typeof newPage === 'number') {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextPage = currentPage < totalPages ? currentPage + 1 : 1;
      handlePageChange(nextPage);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentPage, totalPages]);

  if (visible === false) {
    if (Object.keys(notification).length !== 0) {
      return (
        <div className={`px-4 py-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-max flex items-start gap-4 rounded-xl
            ${notification.status == 'success' ? 'bg-green-100 border-2 border-green-400' : notification.status == 'error' ? 'bg-red-100 border-2 border-red-400' : 'bg-blue-100 border-2 border-blue-400'}`}>
          <ToastInfo />
          <div className="flex flex-col">
            <span className="font-bold">{notification.status == 'success' ? 'Berhasil' : notification.status == 'error' ? 'Terjadi Kesalahan' : 'Informasi'}</span>
            <span>{notification?.message ?? '-'}</span>
          </div>
          <a href={`${process.env.REACT_APP_FRONTEND_DOMAIN}/campaign`} className="bg-white border-2 border-gray-300 rounded-lg px-4 py-2">
            Kembali
          </a>
        </div>
      )
    }
  } else {
    return (
      <div className="template template-preview gap-[20px] gap-y-[20px] overflow-hidden">
        <div className='mb-[20px] max-h-[13vh] grid grid-cols-12 gap-x-[20px]'>
          <div className="col-span-12 logo bg-white flex justify-between items-center h-[8vh] max-h-[8vh] h-[100%] px-[8px] py-[11px]">
            {Object.keys(campaign).length !== 0 ? (
              <img
                alt="logo"
                className="max-w-full h-[100%] object-cover"
                src={process.env.REACT_APP_BACKEND_DOMAIN + "/storage/" + campaign.campaignDetails[0][0].gallery.value}
              />
            ) : null}
            <RenderTimestamp />
          </div>
        </div>
        <div className='mb-[20px] max-h-[73vh] grid grid-cols-12 gap-x-[20px]'>
          <div className="col-span-12 max-h-[73vh] h-[73vh] object-cover">
            <RenderTable />
          </div>
        </div>
        <div className='w-full bg-[#FFFFFF]'>
          <RenderBottomSection />
        </div>
      </div>
    )
  }
}