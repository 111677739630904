import React, { useRef, useState, useEffect, useCallback, memo } from 'react'
import Dropzone from './dropzone/Dropzone'
import ImageGrid from './dropzone/components/ImageGrid'
import './dropzone/index.css'
import ApiService from '../services/api.service'
import { Gallery } from 'react-grid-gallery'
import { toastSuccess, toastError } from '../components/toast';

import '../components/component.scss'

export default function ImagePopup(props) {
  const [random, setRandom] = useState(false)
  const [gallery, setGallery] = useState([])
  const [selectedImage, setSelectedImage] = useState([])
  const [section, setSection] = useState('unggah')
  const [duration, setDuration] = useState(0)
  const [durationGallery, setDurationGallery] = useState(0)
  const hasSelected = [[]]
  const [images, setImages] = useState([])
  const [nextPage, setNextPage] = useState("");
  const [isAction, setIsAction] = useState(false);
  const [isMediaExceed, setIsMediaExceed] = useState(true)
  const maxMedia = localStorage.getItem("media");

  let selectedGallery = []
  let files = []

  useEffect(() => {
    setIsAction(true)
    console.log('tes')
    ApiService.init()
      ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/gallery', {})
        .then((response) => {
          let galleryList = response.data.data
    console.log(maxMedia)
          if (maxMedia != undefined && galleryList.length < maxMedia) {
            setIsMediaExceed(false)
          }
        })
        .catch((error) => {
        })
        .finally(() => {
          setIsAction(false)
        })
  }, [])

  function updateValue(value) {
    setRandom(value)
  }

  function deleteItem(index) {
    files.splice(index, 1)
  }

  function updateDuration(value) {
    setDuration(value)
  }


  function updateDurationGallery(value) {
    setDurationGallery(value)
  }

  function sectionChange(newValue) {
    if (newValue == 'gallery') {
      setIsAction(true)
      ApiService.init()
      ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/gallery', {})
        .then((response) => {
          let galleryList = response.data.data
          let imagesList = []
          setNextPage(response.data.nextPageUrl)
          galleryList.forEach((value) => {
            imagesList.push({
              src:
                process.env.REACT_APP_BACKEND_DOMAIN +
                '/storage/' +
                value.value,
              id: value.id,
              width: 1000,
              height: 1000,
              isSelected: false,
              name: value.filename
            })
          })
          setGallery([...imagesList])
          hasSelected = gallery.some((image) => image.isSelected)
          // setNextPage(response.data.nextPageUrl)
        })
        .catch((error) => {
        })
        .finally(() => {
          setIsAction(false)
        })
    }

    setSection(newValue)
  }


  function loadMore() {
    ApiService.init()
    ApiService.get(nextPage, {})
      .then((response) => {
        let galleryList = response.data.data
        let imagesList = []
        setNextPage(response.data.nextPageUrl)

        galleryList.forEach((value) => {

          imagesList.push({
            src:
              process.env.REACT_APP_BACKEND_DOMAIN +
              '/storage/' +
              value.value,
            id: value.id,
            width: 1000,
            height: 1000,
            isSelected: false,
            name: value.filename
          })
        })
        setGallery([...gallery, ...imagesList])
        hasSelected = gallery.some((image) => image.isSelected)
      })
      .catch((error) => {
      })
  }

  function ImagePopup() {
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.map((file, index) => {
        const reader = new FileReader()
        const fileSize = Math.round(file.size / 1024);
        let bodyFormData = new FormData();

        bodyFormData.append('type', 'image');
        bodyFormData.append('data', file);

        reader.onload = function (e) {
          if (localStorage.getItem('license') == 'trial')
            if (checkType(e.target.result) == 'video' && fileSize > 10000) {
              toastError("Ukuran media terlalu besar. Silahkan upgrade ke versi premium", "left", "validation")
              return;
            } else if (checkType(e.target.result) == 'image' && fileSize > 3000) {
              toastError("Ukuran media terlalu besar. Silahkan upgrade ke versi premium", "left", "validation")
              return;
            }

          ApiService.initFormData()
          ApiService.postFormData(process.env.REACT_APP_BACKEND_DOMAIN + '/api/gallery', bodyFormData)
            .then((response) => {
              setImages((prevState) => [
                ...prevState,
                {
                  id: response.data.id,
                  src: e.target.result,
                  type: checkType(e.target.result),
                  name: file.name,
                  file: file,
                },
              ])
            })
            .catch((error) => {
              // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
            })
        }
        reader.readAsDataURL(file)
        return file
      })
    }, [])

    function checkType(file) {
      if (file.includes('video')) {
        return 'video'
      } else if (file.includes('image')) {
        return 'image'
      }
    }

    function deleteImage(index) {
      let newImages = [...images]
      newImages.splice(index, 1)
      setImages([...newImages])
    }

    function handleSelect(index: number) {
      let imageCollection = [...selectedImage];

      if (imageCollection.length === 0) {
        imageCollection.push(gallery[index]);
      } else {
        let counter = 0;
        let result = imageCollection.some(function (el) {
          counter += 1;
          return el.id === gallery[index].id;
        });

        if (result === false) {
          imageCollection.push(gallery[index]);
        } else {
          imageCollection.splice(counter-1, 1)
        }

      }

      setSelectedImage(...[imageCollection]);
      setDuration(duration)



      selectedGallery.push({ ...gallery[index] })

      const nextImages = gallery.map((image, i) =>
        i === index ? { ...image, isSelected: !image.isSelected } : image,
      )

      setGallery(nextImages)
    }

    const SelectedImageGallery = memo(() => {
      let images = []

      // Rendering individual images
      const Image = ({ image }) => {
        return (
          <div className="file-item">
            <img
              alt={`img - ${image.id}`}
              src={image.src}
              className="file-img"
            />
          </div>
        );
      };

      for (const [index, value] of selectedImage.entries()) {
        images.push(
          <div className="w-full flex flex-wrap tr" key={`${value.id}-image-` + index} >
            <div className="relative basis-6/12 td">
              <div className="image-layout">
                <button
                  className="btn btn-transparent mr-0"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#242424" />
                  </svg>
                </button>
                <Image image={value} />
              </div>
            </div>
            <div className="basis-6/12 td flex items-center justify-center">
              {value.name}
            </div>
          </div>
        )
      }

      return images
    })

    return (
      <div className={props.isImageOn === true ? ' image-popup' : 'hidden'} key="image-upload">
        <div className="title flex flex-wrap items-center">
          <div className="basis-10/12">
            <h3>Tambah Media</h3>
          </div>
          <div className="basis-2/12 flex justify-end">
            <button
              className="btn btn-transparent mr-0"
              onClick={props.togglePopup.bind(this, 'image')}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="tabs w-full flex">
          <a
            className={
              section === 'unggah'
                ? 'tab tab-bordered basis-6/12 h-[50px] tab-active'
                : 'tab tab-bordered basis-6/12 h-[50px]'
            }
            onClick={sectionChange.bind(this, 'unggah')}
          >
            Unggah
          </a>
          <a
            className={
              section === 'gallery'
                ? 'tab tab-bordered basis-6/12 h-[50px] tab-active'
                : 'tab tab-bordered basis-6/12 h-[50px]'
            }
            onClick={sectionChange.bind(this, 'gallery')}
          >
            Galeri
          </a>
        </div>
        <section
          className={
            section === 'unggah' ? 'content unggah relative' : 'hidden'
          }
        >
          {isAction ? (
              <div className="loading-spinner"></div>
          ) : isMediaExceed ? (
              <span className="bg-blue-100">Media dibatasi.</span>
          ) : (
              <Dropzone onDrop={onDrop} />
          )}
          <div className={images.length === 0 ? 'hidden' : 'shuffle flex items-center gap-x-[6px]'}>
            <input
              type="checkbox"
              value={random}
              onChange={(e) => updateValue(e.target.value)}
              className="checkbox checkbox-primary"
            />
            <span className="label-text">Shuffle Media</span>
          </div>
          <div className={images.length === 0 ? 'hidden' : 'flex gap-x-[42px]'}>
            <ImageGrid images={images} deleteImage={deleteImage} />
            <div className="basis-4/12 duration">
              <h3>Durasi tiap file 2</h3>
              <input type="number" autoFocus="autoFocus" className="input" value={duration} onChange={(e) => updateDuration(e.target.value)} />
              <div className='flex justify-end mt-[8vh] px-[48px] absolute bottom-0'>
                <button className='btn upercase' onClick={props.updateImageList.bind(this, images, duration)}>Simpan Gambar</button>
              </div>
            </div>
          </div>
        </section>
        <section
          className={
            section === 'gallery' ? 'content relative gallery mb-[100px]' : 'hidden'
          }
        >
          <div className={props.isImageOn === true ? '' : 'hidden'}>
            <div className='gallery-section min-h-[30vh] max-h-[30vh] overflow-auto'>
                <Gallery images={gallery} onSelect={handleSelect} />                
              <div className='w-fit cursor-pointer mx-auto mt-[15px] border-[1px] px-[15px] py-[5px] border-[#D6E3FD] text-[12px] text-[#3375F5] font-[Inter]' onClick={loadMore.bind(this)}>
                Show More Image
              </div>
            </div>
            <div className='flex gap-x-[42px] px-[33px] mt-[30px]'>
              <section className="file-list table w-8/12">
                <div className="thead">
                  <div className="flex tr">
                    <div className="th basis-6/12">File</div>
                    <div className="th basis-6/12">Nama File</div>
                  </div>
                </div>
                <div className="tbody">
                  <SelectedImageGallery />
                </div>
              </section>
              <div className="basis-4/12 duration relative h-[30vh]">
                <h3>Durasi tiap file</h3>
                <input type="number" autoFocus="autoFocus" className="input" value={durationGallery} onChange={(e) => updateDurationGallery(e.target.value)} />
                <div className='flex justify-end mt-[8vh] px-[48px] absolute bottom-0'>
                  <button className='btn upercase' onClick={props.updateImageList.bind(this, selectedImage, durationGallery)}>Simpan Gambar</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  return <ImagePopup />
}
