import '../../view-template1-grid.css';
import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import '../campaign/index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactJson from 'react-json-view'

export default function ViewMeeting() {
    const { id, code } = useParams();
    const [campaign, setCampaign] = useState([])
    const [visible, setVisible] = useState(true);
    const [singleImages, setSingleImage] = useState([])

    function RenderMedia() {
        if (singleImages.length !== 0) {
            return (
                <img alt="logo" className="max-w-full w-[100%] h-[100%] object-cover"  src={process.env.REACT_APP_BACKEND_DOMAIN + "/storage/" + singleImages[0].gallery.value} />
            )
        } else {
            <div className='max-w-full w-[100%] h-[100%] object-cover'>

            </div>
        }
    }

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const [schedules, setSchedules] = useState([{
        title: '',
        description: '',
        date: '',
        startTime: '00:00',
        endTime: '00:00'
    }])

    function RenderSchedule() {
        let display = []
        if (schedules.length > 0) {
            schedules.map((item, index) => {
                display.push(
                    <div key={'display-' + index}>
                        <div className='w-full h-[20px] bg-[#E6E6E6] text-[10px] leading-[12px] text-[Inter] flex justify-start items-center px-[8px] mt-[13px] mb-[10px]'>{convert(item.date)}</div>
                        <div className='px-[8px]'>
                            <div className='inline-flex text-[12px] leading-[15px] text-[Inter]'>{item.startTime} - {item.endTime}</div>
                            <div className='text-[#79A4F9] text-[Inter] text-[12px]'>{item.title}</div>
                            <div className='text-[#ADADAD] text-[Inter] text-[10px]'>{item.description}</div>
                        </div>
                    </div>
                )
            })
        }

        return display;
    }

    useEffect(() => {
        const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        let day = [], startTime = null, endTime = null;

        const d = new Date();
        let dayName = weekday[d.getDay()];

        var seconds = d.getSeconds();
        var minutes = d.getMinutes();
        var hour = d.getHours();
        ApiService.init()
        ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/meeting/${id}/preview?code=${code}`)
            .then((response) => {

                let meetingDetails = response.data.data.meetingDetails;
                setSingleImage([...meetingDetails[0]])
                
                let newSchedule = [...schedules]
                newSchedule.shift()
                meetingDetails[1].forEach(function (value) {
                    newSchedule.push({
                        title: value.title,
                        description: value.description,
                        date: new Date(value.startDate),
                        startTime: value.startTime,
                        endTime: value.endTime
                    })
                })

                setSchedules([...newSchedule])

                let weekList = response.data.data.workdays;

                if (weekList['sunday'] === true) {
                    day.push('sunday')
                }

                if (weekList['monday'] === true) {
                    day.push('monday')
                }

                if (weekList['tuesday'] === true) {
                    day.push('tuesday')
                }

                if (weekList['wednesday'] === true) {
                    day.push('wednesday')
                }

                if (weekList['thursday'] === true) {
                    day.push('thursday')
                }

                if (weekList['friday'] === true) {
                    day.push('friday')
                }

                if (weekList['saturday'] === true) {
                    day.push('saturday')
                }

                startTime = response.data.data.startTime;
                endTime = response.data.data.endTime;

                if (day.includes(dayName) === true) {

                    seconds = d.getSeconds();
                    minutes = d.getMinutes();
                    hour = d.getHours();

                    let times = hour + ':' + minutes + ':' + seconds;
                    if (times > startTime && times < endTime) {
                        setVisible(true)
                    } else {
                        setVisible(false)
                    }
                } else {
                    setVisible(false)
                }
            })

        setInterval(() => {
            const d = new Date();
            let dayName = weekday[d.getDay()];

            var seconds = d.getSeconds();
            var minutes = d.getMinutes();
            var hour = d.getHours();
            ApiService.init()
            ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/meeting/${id}/preview?code=${code}`)
                .then((response) => {
                    let meetingDetails = response.data.data.meetingDetails;
                    setSingleImage([...meetingDetails[0]])
                    
                    let newSchedule = [...schedules]
                    newSchedule.shift()
                    meetingDetails[1].forEach(function (value) {
                        newSchedule.push({
                            title: value.title,
                            description: value.description,
                            date: new Date(value.startDate),
                            startTime: value.startTime,
                            endTime: value.endTime
                        })
                    })
    
                    setSchedules([...newSchedule])
                    
                    let weekList = response.data.data.workdays;

                    if (weekList['sunday'] === true) {
                        day.push('sunday')
                    }

                    if (weekList['monday'] === true) {
                        day.push('monday')
                    }

                    if (weekList['tuesday'] === true) {
                        day.push('tuesday')
                    }

                    if (weekList['wednesday'] === true) {
                        day.push('wednesday')
                    }

                    if (weekList['thursday'] === true) {
                        day.push('thursday')
                    }

                    if (weekList['friday'] === true) {
                        day.push('friday')
                    }

                    if (weekList['saturday'] === true) {
                        day.push('saturday')
                    }

                    startTime = response.data.data.startTime;
                    endTime = response.data.data.endTime;

                    if (day.includes(dayName) === true) {
                        seconds = d.getSeconds();
                        minutes = d.getMinutes();
                        hour = d.getHours();

                        let times = hour + ':' + minutes + ':' + seconds;
                        if (times > startTime && times < endTime) {
                            setVisible(true)
                        } else {
                            setVisible(false)
                        }
                    } else {
                        setVisible(false)
                    }
                })
        }, 30000);

    }, [id, code])


    if (visible === false) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className="template  template-preview grid grid-cols-12 gap-[20px] gap-y-[20px] overflow-hidden">
                <div className='col-span-8 h-full !h-[96vh] bg-white'>
                    <RenderMedia />
                </div>
                <div className='col-span-4 h-full !h-[96vh] bg-white'>
                    <RenderSchedule />
                </div>
            </div>
        )
    }
}