import '../../view-template1-grid.css';
import React from 'react'
import Slider from "react-slick";
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactJson from 'react-json-view'
import Close from '../../components/icon/Close'

export default function Preview3(props) {
  function RenderLogo() {
    if (props.singleImages.length !== 0) {
      return (
        <img alt="logo" className="max-w-full w-[100%] h-[100%] object-cover" src={props.singleImages[0].src} />
      )
    } else {
      <div className='max-w-full w-[100%] h-[100%] object-cover'>

      </div>
    }
  }

  function RenderText() {
    let textValue = props.text
    return (
      <div>
        <p className={`text-[` + textValue.fontFamily + `] text-[` + textValue.fontSize
          + `px]`}>
          {textValue.title}
        </p>
        <p className={`text-[` + textValue.teksFontFamily + `] text-[` + textValue.teksFontFamily + `px]`}>
          {textValue.teks}
        </p>
      </div>
    )
  }

  function RenderApi() {
    if (props.api !== "") {

      return (
        <div className='bg-white h-full'>
          <ReactJson src={props.api} />
        </div>
      )

    }
  }

  function RenderMedia() {
    if (props.images.length != 0) {
      let slideValue = props.images
      if (slideValue.length > 1) {
        let slider = []
        slideValue.forEach(function (value, index) {
          slider.push(
            <div key={index} className="max-w-full w-[100%] max-h-[100%] h-[100%]">
              <img alt="SliderImage" className="max-w-full w-[100%] max-h-[100%] h-[100%] object-cover" src={value.src} />
            </div>
          )
        })

        var settings = {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: props.duration * 1000,
          cssEase: "linear"
        };
        return (
          <Slider {...settings}>
            {slider}
          </Slider>
        );
      } else {
        return (
          <div className="max-w-full w-[100%] h-[100%] max-h-[100%] ">
            <img alt="SliderImage" className="max-w-full w-[100%] h-[100%] max-h-[100%] object-cover" src={props.images[0].src} />
          </div>
        )
      }
    }
  }

  return (
    <div className={props.preview === true ? "template template-preview gap-[20px] gap-y-[20px] fixed top-0 left-0 w-full h-full overflow-hidden" : "hidden"}>
      <div className='absolute top-[0] right-[0]'>
        <button onClick={props.changePreview.bind(this)}>
          <Close />
        </button>
      </div>
      <div className='mb-[20px] max-h-[14vh] grid grid-cols-12 gap-x-[20px]'>
        <div className="col-span-6 max-h-[14vh] h-[14vh] object-cover bg-[#FFFFFF]">
          <RenderLogo />
        </div>
        <div className='col-span-6 max-h-[14vh] px-[1%] py-[0.5%] bg-[#FFFFFF]'>
          <RenderText />
        </div>
      </div>
      <div className='mb-[20px] max-h-[87vh] grid grid-cols-12 gap-x-[20px]'>
        <div className="col-span-2 max-h-[84vh] h-[84vh] object-cover">
          <RenderApi />
        </div>
        <div className='mb-[20px] h-[84vh] max-h-[84vh] col-span-10 bg-[#FFFFFF]'>
          <RenderMedia />
        </div>
      </div>
    </div>
  )
}