import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import TimePicker from "rc-time-picker";
import DatePicker from 'react-datepicker'
import { useParams } from "react-router-dom";

import ImagePopup from '../../components/ImagePopup'
import RunningTextPopup from '../../components/RunningTextPopup'

import MediaSlide from '../../components/panel/MediaSlide'
import RunningText from '../../components/panel/RunningText'

import Audio from '../../components/panel/Audio'
import Navbar from '../../components/menu/Navbar'
import Back from '../../components/icon/Back'

import ApiService from '../../services/api.service'
import Alert from '../../components/alert'

import Preview from '../../components/icon/Preview'
import Preview5 from './Preview5'
import AddCircle from '../../components/icon/AddCircle';
import moment from 'moment';
import { validateCampaign } from './CampaignUtils'

export default function Template5() {
  const [flagDevice, setFlagDevice] = useState([])
  const [flagBranch, setFlagBranch] = useState([])
  const [alertProps, setAlertProps] = useState({
    'type': 'error',
    'msg': 'Silahkan isi semua data'
  })
  const [imageDuration, setImageDuration] = useState(0)
  const [isImageOn, setIsImageOn] = useState(false)
  const [isRunningTextOn, setIsRunningTextOn] = useState(false)
  const [preview, setPreview] = useState(false)
  const [runningText, setRunningText] = useState({
    'teks': '',
    'teksColor': '',
    'fontSize': 24,
    'runningSpeed': 20,
    'fontFamily': 'Arial',
    'bgColor': '#ffffff',
  })

  const [images, setImages] = useState([])
  function updateImageList(list, duration) {
    setImageDuration(duration)
    setImages([...list])
    var layout = document.getElementById('popup')

    setIsImageOn(!isImageOn)
    if (!isImageOn === true) {
      layout.classList.remove('hidden')
      layout.classList.add('flex')
    } else {
      layout.classList.remove('flex')
      layout.classList.add('hidden')
    }
  }

  function updateData(type, attribute, value) {
    if (type === 'running') {
      let newRunningText = { ...runningText }
      newRunningText[attribute] = value
      setRunningText({ ...newRunningText })
    }
  }

  function togglePopup(popup) {
    var layout = document.getElementById('popup')
    if (popup === 'image') {
      setIsImageOn(!isImageOn)
      if (!isImageOn === true) {
        layout.classList.remove('hidden')
        layout.classList.add('flex')
      } else {
        layout.classList.remove('flex')
        layout.classList.add('hidden')
      }
    }

    if (popup === 'running') {
      setIsRunningTextOn(!isRunningTextOn)
      if (!isRunningTextOn === true) {
        layout.classList.remove('hidden')
        layout.classList.add('flex')
      } else {
        layout.classList.remove('flex')
        layout.classList.add('hidden')
      }
    }
  }

  const [campaignName, setCampaignName] = useState('')
  function updateName(value) {
    setCampaignName(value)
  }

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [page, setPage] = useState('jadwal')
  const [day, setDay] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  })

  const [startTime, setStartTime] = useState('00:00')
  const [endTime, setEndTime] = useState('00:00')
  const [durationType, setDurationType] = useState('harian')
  const [deviceList, setDeviceList] = useState([])
  let [selectedBranch, setSelectedBranch] = useState([])
  const [search, setSearch] = useState('')

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      ApiService.init()
      ApiService.get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign/${id}`)
        .then((response) => {
          let data = response.data.data;
          let campaign = data.campaignDetails;
          setCampaignName(data.name)


          setRunningText({
            ...{
              id: campaign[0][0].id,
              teks: campaign[0][0].value,
              fontSize: campaign[0][0].valueFontSize,
              fontFamily: campaign[0][0].valueFont,
            }
          })

          let imageData = []
          campaign[1].forEach(function (value) {
            setImageDuration(value.duration)
            imageData.push({
              'imageId': value.id,
              'id': value.gallery.id,
              'src': process.env.REACT_APP_BACKEND_DOMAIN + '/storage/' + value.gallery.value
            })
          })
          setImages([...imageData])

          setDurationType(data.period)
          setStartTime(data.startTime)
          setEndTime(data.endTime)
          setStartDate(new Date(data.startDate))
          setEndDate(new Date(data.endDate))
          setDay({ ...data.workdays })
          setFlagBranch([...data.isSelectedBranch])
          setFlagDevice([...data.isSelectedDevice])
        })
    }
  }, [])

  function searchCabang(value) {
    setSearch(value);

    ApiService.init()
    ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch?search=' + value)
      .then((response) => {
        let data = response.data.data;
        data.forEach((value) => {
          if (selectedBranch.includes(value.id)) {
            value.status = true;
          }
        })
        setBranch([...data])
      })
      .catch((error) => {
        // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
      })
  }

  function updateCheckedDevice(index) {
    let newBranch = [...branch]
    newBranch[index].status = !newBranch[index].status
    let temporaryBranch = [...selectedBranch];
    if (newBranch[index].status === false) {
      if (selectedBranch.includes(newBranch[index].id)) {
        temporaryBranch.splice(temporaryBranch.indexOf(newBranch[index].id), 1)
      }
    } else {
      temporaryBranch.push(newBranch[index].id)
    }
    setSelectedBranch([...temporaryBranch]);
    setBranch([...newBranch])
  }

  const [branch, setBranch] = useState([])

  function BranchList() {
    let branchOption = []
    for (const [index, value] of branch.entries()) {
      branchOption.push(
        <div
          className="flex flex-wrap items-center py-[15px] border-b-[1px] border-[#E7EAEC] gap-x-[12px]"
          key={'branch-' + index}
        >
          <div className="basis-1/12">
            <input
              type="checkbox"
              checked={value.status}
              onChange={updateCheckedDevice.bind(this, index)}
              className="checkbox checkbox-primary"
            />
          </div>
          <div className="basis-10/12">
            <h3>{value.name}</h3>
            <h4>
              {value.province}, {value.city}, {value.district}
            </h4>
            <p>{value.address}</p>
          </div>
        </div>,
      )
    }
    return branchOption
  }

  function selectDevice(indexBranch, indexDevice) {
    let newDeviceList = [...deviceList]
    newDeviceList[indexBranch].devices[indexDevice].isDeviceSelected = !newDeviceList[indexBranch].devices[indexDevice].isDeviceSelected;
    return () => setDeviceList([...newDeviceList])
  }

  let branchDevice = []
  for (let [index, value] of deviceList.entries()) {
    let devices = [];
    for (let [indexDevice, branchDevice] of value.devices.entries()) {
      devices.push(
        <div className="grid grid-cols-12 gap-x-[10px] py-[12px] border-b-[1px] border-[#E7E9EC]" key={'device-' + value.id + '-' + indexDevice}>
          <div className="col-span-1">
            <input
              type="checkbox"
              className="checkbox checkbox-primary"
              value={branchDevice.isDeviceSelected}
              defaultChecked={branchDevice.isDeviceSelected}
              onChange={selectDevice.bind(this, index, indexDevice)}
            />
          </div>
          <div className="device-name col-span-11">
            <h3>{branchDevice.name}</h3>
            <p>{branchDevice.type}</p>
          </div>
        </div>
      )
    }

    branchDevice.push(
      <div
        className=" bg-base-100 rounded-box cursor-pointer"
        key={'deviceBranch-' + index}
      >
        <div className="collapse-title text-xl font-medium border-b-[1px] border-[#E7E9EC]"
          onClick={collapseContent.bind(this, index)}
        >
          {value.name} <span className='device-total'> ( {value.devices.length} Perangkat ) </span>
        </div>
        <div className="device-list" id={"devices-" + index}>
          {devices}
        </div>
      </div>
    )
  }

  function collapseContent(index, event) {
    event.preventDefault()
    if (event.target === event.currentTarget) {
      let children = document.getElementById('devices-' + index)
      children.classList.toggle('!max-h-fit')
    }
  }

  function changeDay(dayName) {
    let newDay = { ...day }
    newDay[dayName] = !newDay[dayName];
    setDay({ ...newDay })
  }

  function changePage(pageName) {
    if (pageName === 'device') {
      let query = '/api/branch?';
      for (let i = 0; i < selectedBranch.length; i++) {
        query = query + 'branch_ids[]=' + selectedBranch[i]
        if (i !== selectedBranch.length - 1) {
          query = query + '&'
        }
      }

      if (selectedBranch.length !== 0) {
        ApiService.init()
        ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + query)
          .then((response) => {
            let data = response.data.data
            if (flagDevice.length != 0) {
              data.forEach(function (value) {
                value.devices.forEach(function (device) {
                  if (flagDevice.includes(device.id)) {
                    device.isDeviceSelected = true;
                  }
                })
              })
            }
            setFlagDevice([])
            setDeviceList([...data])

          })
          .catch((error) => {
          })
      } else {
        setDeviceList([])
      }
      setPage(pageName)

    } else if (pageName === 'cabang') {
      let temporaryBranch = []
      ApiService.init()
      ApiService.get(process.env.REACT_APP_BACKEND_DOMAIN + '/api/branch')
        .then((response) => {
          if (branch.length === 0) {
            let data = response.data.data
            if (flagBranch.length != 0) {
              data.forEach(function (value) {
                if (flagBranch.includes(value.id)) {
                  value.status = true;
                  temporaryBranch.push(value.id)
                }
              })
              setSelectedBranch([...temporaryBranch])
              setFlagBranch(null)
            }

            setBranch([...data])

          }
          setPage(pageName)

        })
        .catch((error) => {
          // window.location = process.env.REACT_APP_AUTH_DOMAIN + '/login'
        })
    } else {
      setPage(pageName)
    }

  }

  function generateAlert(type, message) {
    let newAlert = { ...alertProps }
    newAlert['type'] = type
    newAlert['msg'] = message
    setAlertProps({ ...newAlert })

    var alert = document.getElementById('alert');
    alert.classList.toggle('hidden');
    alert.classList.toggle('opacity-[0]')

    setTimeout(() => {
      alert.classList.toggle('opacity-[0]')
    }, 2000)

    setTimeout(() => {
      alert.classList.toggle('hidden');
    }, 2500)
  }

  function changeDurationType(value) {

    setDurationType(value);
    let newDay = { ...day }

    if (value === 'Bulanan') {
      newDay['sunday'] = true;
      newDay['monday'] = true;
      newDay['tuesday'] = true;
      newDay['wednesday'] = true;
      newDay['thursday'] = true;
      newDay['friday'] = true;
      newDay['saturday'] = true;
    }

    setDay({ ...newDay });
  }

  function saveCampaign() {
    const textValue = runningText.teks;
    const imagesValue = images.length;
    const devices = [];
    for (const [key, item] of deviceList.entries()) {
      item.devices.forEach((value) => {
        if (value.isDeviceSelected === true) {
          devices.push(value.id);
        }
      })
    }

    const isValid = validateCampaign({
      values: [textValue,imagesValue],
      campaignName: campaignName,
      devices: devices,
    })

    if (isValid) {
      let campaignData = {
        'template_id': 5,
        'name': campaignName,
        'start_date': startDate,
        'end_date': endDate,
        'start_time': startTime,
        'endTime': endTime,
        'workdays': day,
        'devices': devices,
        'status': 'active',
        'period': durationType
      };

      const campaign = {
        id: id,
        campaignData: campaignData,
        images: images,
        imagesIndex: 2,
        imageDuration: imageDuration,
        runningText: runningText,
        runningTextIndex: 1,
      };

      ApiService.createOrUpdateCampaign(campaign);
    }
  }


  function changePreview() {
    setPreview(!preview)
  }

  return (
    <div className="w-full h-screen cabang-templates bg-white">
      <div className="top-content">
        <Navbar />
      </div>
      <div className="content">
        <div className="header flex flex-wrap">
          <div className={id !== undefined ? "basis-6/12 title" : "hidden"}>
            <Link
              to="/campaign"
              className="flex items-center gap-x-[4px]"
            >
              <Back />
              <h3>Ubah Campaign</h3>
            </Link>
          </div>
          <div className={id === undefined ? "basis-6/12 title" : "hidden"}>
            <Link
              to="/campaign/template"
              className="flex items-center gap-x-[4px]"
            >
              <Back />
              <h3>Buat Campaign</h3>
            </Link>
          </div>
          <div className="basis-6/12 action flex flex-wrap items-center justify-end gap-x-[24px] pl-[24px]">
            <button onClick={changePreview.bind(this)} className="btn !bg-[#FFFFFF] !border-[1px] !border-solid !text-[#3375F5] !border-[#3375F5] gap-x-[12px] !px-[29.5px]">
              <Preview />
              PRATINJAU
            </button>
            <button className='btn gap-x-[12px]' onClick={saveCampaign.bind(this)}>
              <AddCircle />
              TAMBAH CAMPAIGN
            </button>
          </div>
        </div>
        <div className="main grid grid-cols-5 gap-x-[20px]">
          <div className="col-span-3">
            <div>
              <label htmlFor="nama-campaign">Nama Campaign</label>
              <input
                type="text"
                placeholder="Masukkan Nama Campaign disini"
                value={campaignName}
                onChange={(e) => updateName(e.target.value)}
                className="w-full input"
              />
            </div>
            <div id="template5">
              <div
                className="running-text px-[8px] overflow-hidden py-[11px] cursor-pointer"
                onClick={togglePopup.bind(this, 'running')}
              >
                {runningText.teks === '' ? (
                  <RunningText />
                ) : (
                  runningText.teks
                )}
              </div>
              <div
                className="w-full media h-[49vh] 2xl:h-[55vh] px-[8px] py-[11px] flex items-center justify-center cursor-pointer"
                onClick={togglePopup.bind(this, 'image')}
              >
                {images.length === 0 ? (
                  <MediaSlide />
                ) : (
                  <div className='max-w-full max-h-full h-full w-full'>
                    <img src={images[0].src} className="object-cover w-[100%] h-[100%]" alt="" />
                  </div>
                )}
              </div>
            </div>
            {/* <div className="w-full mt-[24px] mb-[5px]">
              <button className="btn btn-outline">
                <Audio />
              </button>
            </div> */}
          </div>
          <div className="col-span-2 campaing-setting">
            <div
              className={
                page === 'jadwal' ? 'schedule h-full relative' : 'hidden'
              }
            >
              <div className="title">
                <h3>Jadwal</h3>
              </div>
              <div className="grid grid-cols-12 w-full time-input">
                <div className="col-span-5">
                  <h3>Waktu Mulai</h3>
                  <TimePicker
                    placeholder="00:00"
                    showSecond={false}
                    focusOnOpen={true}
                    format={'HH mm'}
                    value={moment(startTime, [moment.ISO_8601, 'HH:mm'])}
                    onChange={e => setStartTime(e.format('LT'))}
                  />
                </div>
                <div className="col-span-2 flex justify-center items-center">
                  -
                </div>
                <div className="col-span-5">
                  <h3>Berakhir Sampai</h3>
                  <TimePicker
                    placeholder="00:00"
                    showSecond={false}
                    focusOnOpen={true}
                    format={'HH mm'}
                    value={moment(endTime, [moment.ISO_8601, 'HH:mm'])}
                    onChange={e => setEndTime(e.format('HH:mm'))}
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 w-full date-input">
                <div className="col-span-5">
                  <h3>Tanggal Mulai</h3>
                  <DatePicker
                    value={startDate}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div className="col-span-2 flex justify-center items-center">
                  -
                </div>
                <div className="col-span-5">
                  <h3>Berakhir Sampai</h3>
                  <DatePicker
                    value={endDate}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>
              <div className="w-full period-select">
                <select className="select w-full" value={durationType} onChange={e => changeDurationType(e.target.value)}>
                  <option value={'harian'}>Harian</option>
                  <option value={'Bulanan'}>Bulanan</option>
                </select>
              </div>
              <div className="w-full day-group flex flex-wrap">

                <ul className="inline-flex gap-x-[12px]">
                  <li>
                    <h3>HARI KERJA</h3>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="sunday"
                      name="sunday"
                      value={day.sunday}
                      onChange={(event) => changeDay('sunday', event.target.value)}
                    />
                    {
                      day.sunday ?
                        <label htmlFor="sunday" className='!bg-[#3375F5] !text-[#FFFFFF]'>S</label>
                        :
                        <label htmlFor="sunday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >S</label>
                    }
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="monday"
                      name="monday"
                      value={day.monday}
                      onChange={(event) => changeDay('monday', event.target.value)}
                    />
                    {
                      day.monday ?
                        <label htmlFor="monday" className='!bg-[#3375F5] !text-[#FFFFFF]'>M</label>
                        :
                        <label htmlFor="monday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >M</label>
                    }
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="tuesday"
                      name="tuesday"
                      value={day.tuesday}
                      onChange={(event) => changeDay('tuesday', event.target.value)}
                    />
                    {
                      day.tuesday ?
                        <label htmlFor="tuesday" className='!bg-[#3375F5] !text-[#FFFFFF]'>T</label>
                        :
                        <label htmlFor="tuesday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >T</label>
                    }
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="wednesday"
                      name="wednesday"
                      value={day.wednesday}
                      onChange={(event) => changeDay('wednesday', event.target.value)}
                    />
                    {
                      day.wednesday ?
                        <label htmlFor="wednesday" className='!bg-[#3375F5] !text-[#FFFFFF]'>W</label>
                        :
                        <label htmlFor="wednesday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >W</label>
                    }
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="thursday"
                      name="thursday"
                      value={day.thursday}
                      onChange={(event) => changeDay('thursday', event.target.value)}
                    />
                    {
                      day.thursday ?
                        <label htmlFor="thursday" className='!bg-[#3375F5] !text-[#FFFFFF]'>T</label>
                        :
                        <label htmlFor="thursday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >T</label>
                    }
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="friday"
                      name="friday"
                      value={day.friday}
                      onChange={(event) => changeDay('friday', event.target.value)}
                    />
                    {
                      day.friday ?
                        <label htmlFor="friday" className='!bg-[#3375F5] !text-[#FFFFFF]'>F</label>
                        :
                        <label htmlFor="friday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >F</label>
                    }
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="saturday"
                      name="saturday"
                      value={day.saturday}
                      onChange={(event) => changeDay('saturday', event.target.value)}
                    />
                    {
                      day.saturday ?
                        <label htmlFor="saturday" className='!bg-[#3375F5] !text-[#FFFFFF]'>S</label>
                        :
                        <label htmlFor="saturday" className='!bg-[#FFFFFF] !text-[#3375F5] !border-[#3375F5]' >S</label>
                    }
                  </li>
                </ul>
              </div>
              <div className="absolute bottom-0 right-[0]">
                <button
                  className="btn btn-outline"
                  onClick={changePage.bind(this, 'cabang')}
                >
                  Selanjutnya
                </button>
              </div>
            </div>
            <div
              className={
                page === 'cabang' ? 'branch h-full relative' : 'hidden'
              }
            >
              <div className="title">
                <h3>CABANG</h3>
              </div>
              <div className="branch-list gap-y-[27px] flex flex-wrap">
                <div className="basis-full max-h-[40vh] overflow-auto">
                  <div className="mb-[24px]">
                    <input type="text" placeholder="SEARCH" value={search} onChange={e => searchCabang(e.target.value)} />
                  </div>
                  <BranchList />
                </div>
              </div>
              <div className="absolute bottom-0 left-[0] w-full flex justify-between">
                <button
                  className="btn btn-outline"
                  onClick={changePage.bind(this, 'jadwal')}
                >
                  SEBELUMNYA
                </button>

                <button
                  className="btn btn-outline"
                  onClick={changePage.bind(this, 'device')}
                >
                  Selanjutnya
                </button>
              </div>
            </div>
            <div
              className={
                page === 'device' ? 'device h-full relative' : 'hidden'
              }
            >
              <div className="title">
                <h3>PERANGKAT</h3>
              </div>
              <div className="branch-list max-h-[40vh] overflow-auto">
                {branchDevice}
              </div>
              <div className="absolute bottom-0 left-[0] w-full flex justify-between">
                <button
                  className="btn btn-outline"
                  onClick={changePage.bind(this, 'cabang')}
                >
                  SEBELUMNYA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="fixed w-screen h-screen top-0 left-0 toggle-menu flex hidden"
        id="popup"
      >
        <ImagePopup isImageOn={isImageOn} togglePopup={togglePopup} updateImageList={updateImageList} />
        <RunningTextPopup
          isRunningTextOn={isRunningTextOn}
          togglePopup={togglePopup}
          runningText={runningText}
          updateData={updateData}
        />
      </div>
      <Preview5 changePreview={changePreview} duration={imageDuration} preview={preview} runningText={runningText} images={images} />
    </div>
  )
}
