import axios from 'axios'
import JwtService from './jwt.service'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_DOMAIN
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
    axios.defaults.headers.post['Content-Type'] =
      'application/json'
    axios.defaults.headers.common[
      'Source'
    ] = process.env.REACT_APP_FRONTEND_DOMAIN
    // axios.defaults.headers.common["ApiSource"] = 'http://localhost:8000';
    axios.defaults.headers.common["ApiSource"] = process.env.REACT_APP_BACKEND_DOMAIN;
    // axios.defaults.headers.common["ApiSource"] = "https://api.skrin.awandigital.id";
    axios.defaults.headers.common["Name"] = "Skrin";
  },

  initFormData() {
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_DOMAIN
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
    axios.defaults.headers.post['Content-Type'] =
      'multipart/form-data'
  },

  initAllowCors() {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept'
  },

  reInitAuthorization() {
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_DOMAIN
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken}`
  },

  reInitBaseURL() {
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_DOMAIN
  },

  reInitBaseURLAWD() {
    axios.defaults.baseURL = process.env.REACT_APP_AWD_BACKEND_DOMAIN
  },

  setHeaderMultipartFormData() {
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  },

  resetHeader() {
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
  },

  query(resource) {
    return axios.get(resource)
  },

  get(resource, params, options) {
    return axios
      .get(`${resource}`, {
        params:
          params !== undefined && options?.isSnakeCaseParams != false ? snakecaseKeys(params, { deep: true }) : params,
        transformResponse: [
          (data) => {
            try {
              return camelcaseKeys(JSON.parse(data), { deep: true })
            } catch (error) {
            }
          },
        ],
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  getHTML(resource, params) {
    return axios
      .get(`${resource}`, {
        params:
          params !== undefined ? snakecaseKeys(params, { deep: true }) : params,
        transformResponse: [
          (data) => {
            try {
              return data;
            } catch (error) {
            }
          },
        ],
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  getFile(resource, params) {
    return axios
      .get(`${resource}`, {
        params:
          params !== undefined ? snakecaseKeys(params, { deep: true }) : params,
        responseType: 'blob',
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  post(resource, params) {
    return axios
      .post(
        `${resource}`,
        snakecaseKeys(params, { deep: true }),
        {
          transformResponse: [
            (data) => {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
              }
            },
          ],
        }
      )
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  patch(resource, params) {
    return axios
      .patch(
        `${resource}`,
        snakecaseKeys(params, { deep: true }),
        {
          transformResponse: [
            (data) => {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
              }
            },
          ],
        }
      )
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  postFormData(resource, params) {
    return axios
      .post(
        `${resource}`, params
      )
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  update2(resource, params) {
    return axios
      .put(
        `${resource}`, params
      )
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    return axios
      .put(
        `${resource}`,
        params ? snakecaseKeys(params, { deep: true }) : null,
        {
          transformResponse: [
            (data) => {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
              }
            },
          ],
        }
      )
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  delete(resource, params) {
    return axios
      .delete(resource, {
        params: params ? snakecaseKeys(params, { deep: true }) : null,
        transformResponse: [
          (data) => {
            try {
              return camelcaseKeys(JSON.parse(data), { deep: true })
            } catch (error) {
            }
          },
        ],
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.purgeAuth()
          throw new Error('Somethings Wrong')
        }
        throw err
      })
  },

  purgeAuth() {
    // state.loggedIn = false
    // state.user = {}
    // state.errors = {}
    JwtService.destroyToken()
    // router.push({ path: '/auth/login' })
  },

  // getBase64(file) {
  //   return new Promise((resolve) => {
  //     let fileInfo;
  //     let baseURL = "";
  //     // Make new FileReader
  //     let reader = new FileReader();

  //     // Convert the file to base64 text
  //     reader.readAsDataURL(file);

  //     // on reader load somthing...
  //     reader.onload = () => {
  //       // Make a fileInfo Object
  //       baseURL = reader.result;
  //       resolve(baseURL);
  //     };
  //   });
  // },

  createOrUpdateCampaign(campaign) {
    ApiService.initFormData();

    const campaignRequest = campaign.id
      ? ApiService.put(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign/${campaign.id}`,
          campaign.campaignData
        )
      : ApiService.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign`,
          campaign.campaignData
        );

    return campaignRequest.then((response) => {
      const campaignId = response.data.id;
      const requests = [];
      ApiService.init();

      if (campaign.singleImages) {        
        const singleImageData = {
          campaign_id: campaignId,
          gallery_id: campaign.singleImages[0].id,
          index: campaign.singleImagesIndex,
          type: 'image',
        };

        requests.push(
          ApiService.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign-detail`,
            singleImageData
          ),
        );
      }

      if (campaign.text) {        
        requests.push(
          ApiService.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign-detail`,
            {
              campaign_id: campaignId,
              index: campaign.textIndex,
              type: 'text',
              title: campaign.text.title,
              title_font_size: campaign.text.fontSize,
              title_font: campaign.text.fontFamily,
              title_color: campaign.text.titleColor,
              value: campaign.text.teks,
              value_font_size: campaign.text.teksFontSize,
              value_font: campaign.text.teksFontFamily,
              value_color: campaign.text.teksColor,
              bg_color: campaign.text.bgColor,
            }
          ),
        );
      }

      if (campaign.images) {
      // Add image requests to the array
        campaign.images.forEach((value, index) => {
          requests.push(
            ApiService.post(
              `${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign-detail`,
              {
                campaign_id: campaignId,
                gallery_id: value.id,
                index: campaign.imagesIndex,
                type: 'image',
                index_carousel: index,
                duration: campaign.imageDuration,
              }
            )
          );
        });
      }

      if (campaign.runningText) {
        const runningTextData = {
          campaign_id: campaignId,
          index: campaign.runningTextIndex,
          type: 'running_text',
          running_speed: campaign.runningText.runningSpeed,
          value: campaign.runningText.teks,
          value_color: campaign.runningText.teksColor,
          value_font_size: campaign.runningText.fontSize,
          value_font: campaign.runningText.fontFamily,
          bg_color: campaign.runningText.bgColor,
        };

        requests.push(
          ApiService.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign-detail`,
            runningTextData
          )
        );
      }

      if (campaign.api) {
        const apiData = {
          campaign_id: campaignId,
          type: 'api',
          index: campaign.apiIndex,
          value: campaign.api.selectedDb,
          header_font_color: campaign.api.headerFontColor,
          header_color: campaign.api.headerColor,
          client_id: campaign.api.clientId,
        }

        requests.push(
          ApiService.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign-detail`,
            apiData
          )
        );
      }

      if (campaign.html) {
        let htmlData = {
          campaign_id: campaignId,
          type: 'html',
          index: campaign.htmlIndex,
          platform: campaign.html.tipe,
          value: campaign.html.html
        }

        requests.push(
          ApiService.post(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/campaign-detail`,
            htmlData
          )
        );
      }

      // Use Promise.all to wait for all requests to complete
      return Promise.all(requests).then(() => {
        setTimeout(() => {
          window.location = '/campaign';
        }, 2000);
      });
    });
  },
}

export default ApiService
