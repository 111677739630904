import '../../view-template1-grid.css';
import React from 'react'
import './index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Close from '../../components/icon/Close'

export default function Preview4(props) {
  function RenderMedia() {
    if (props.singleImages.length !== 0) {
      return (
        <img alt="logo" className="max-w-full w-[100%] h-[100%] object-cover" src={props.singleImages[0].src} />
      )
    } else {
      <div className='max-w-full w-[100%] h-[100%] object-cover'>

      </div>
    }
  }


  return (
    <div className={props.preview === true ? "template template-preview gap-[20px] gap-y-[20px] fixed top-0 left-0 w-full h-full overflow-hidden" : "hidden"}>
      <div className='absolute top-[0] right-[0]'>
        <button onClick={props.changePreview.bind(this)}>
          <Close />
        </button>
      </div>
      <div className='mb-[20px] h-[94vh] max-h-[94vh] col-span-10 bg-[#FFFFFF]'>
        <RenderMedia />
      </div>
    </div>
  )
}