const LoadingTable = ({ colSpan }) => {
  return (
    <tr className="bg-white">
      <td colSpan={colSpan} className="text-center py-6 px-6">
        <div className="space-y-4">
          <div className="text-md">Loading...</div>
        </div>
      </td>
    </tr>
  );
};

export default LoadingTable;