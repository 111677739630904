import React from "react";

export default function Api() {
    return (
        <svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.2002 6.7998V9.59979" stroke="#3375F5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.30001 6.6C6.01798 6.6 6.60002 6.01798 6.60002 5.30001C6.60002 4.58204 6.01798 4 5.30001 4C4.58204 4 4 4.58204 4 5.30001C4 6.01798 4.58204 6.6 5.30001 6.6Z" stroke="#3375F5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.19999 11.9996C5.86273 11.9996 6.39998 11.4624 6.39998 10.7996C6.39998 10.1369 5.86273 9.59961 5.19999 9.59961C4.53725 9.59961 4 10.1369 4 10.7996C4 11.4624 4.53725 11.9996 5.19999 11.9996Z" stroke="#3375F5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.8001 11.9996C11.4628 11.9996 12.0001 11.4624 12.0001 10.7996C12.0001 10.1369 11.4628 9.59961 10.8001 9.59961C10.1373 9.59961 9.6001 10.1369 9.6001 10.7996C9.6001 11.4624 10.1373 11.9996 10.8001 11.9996Z" stroke="#3375F5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.25342 6.7998C5.43342 7.4998 6.07341 8.01979 6.82674 8.01312L8.20007 8.00647C9.24674 7.9998 10.1401 8.67314 10.4668 9.61314" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.9815 13H20.294L23.3665 4.27273H25.3182L28.3949 13H26.7074L24.3764 6.0625H24.3082L21.9815 13ZM22.0369 9.57812H26.6392V10.848H22.0369V9.57812ZM30.1444 13V4.27273H33.4171C34.0876 4.27273 34.6501 4.39773 35.1046 4.64773C35.562 4.89773 35.9072 5.24148 36.1401 5.67898C36.3759 6.11364 36.4938 6.60795 36.4938 7.16193C36.4938 7.72159 36.3759 8.21875 36.1401 8.65341C35.9043 9.08807 35.5563 9.4304 35.0961 9.6804C34.6359 9.92756 34.0691 10.0511 33.3958 10.0511H31.2268V8.75142H33.1827C33.5748 8.75142 33.8958 8.68324 34.1458 8.54688C34.3958 8.41051 34.5805 8.22301 34.6998 7.98438C34.8219 7.74574 34.883 7.47159 34.883 7.16193C34.883 6.85227 34.8219 6.57955 34.6998 6.34375C34.5805 6.10795 34.3944 5.92472 34.1415 5.79403C33.8915 5.66051 33.5691 5.59375 33.1742 5.59375H31.7254V13H30.1444ZM40.048 4.27273V13H38.467V4.27273H40.048Z" fill="#3375F5" />
        </svg>
    );
}