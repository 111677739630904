import './App.css';
import { Outlet } from 'react-router-dom'
import Sidebar from './components/menu/Sidebar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="w-full bg-[#FCFCFC] h-screen">
      <ToastContainer/>
      <div className='App'>
        <div className='flex flex-wrap'>
          <div>
              <Sidebar />
          </div>
          <div id='content' className='bg-white'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
