import React from "react";

export default function Copy() {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99335 12.1668H11C13.0133 12.1668 14.6667 10.5202 14.6667 8.50016C14.6667 6.48683 13.02 4.8335 11 4.8335H9.99335" stroke="#3375F5" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.99998 4.8335H4.99998C2.97998 4.8335 1.33331 6.48016 1.33331 8.50016C1.33331 10.5135 2.97998 12.1668 4.99998 12.1668H5.99998" stroke="#3375F5" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.33331 8.5H10.6666" stroke="#3375F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}