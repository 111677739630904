import React, { useState } from "react";
import Alert from '../alert'
import ApiService from '../../services/api.service'
import { toastSuccess, toastError } from '../../components/toast';

export default function CreateUser(props) {
    const [alertProps, setAlertProps] = useState({
        'type': 'error',
        'msg': 'Silahkan isi semua field yang ada.'
    })

    const [user, setUser] = useState({
        name: '',
        email: '',
        status: 'Content Designer'
    })

    function saveUser() {
        if (user.name === '' || user.email === '' || user.status === '') {
            toastError("Silahkan isi semua field yang ada.", "left", "failed")
        } else {
            ApiService.init()
            ApiService.post(`${process.env.REACT_APP_AWD_BACKEND_DOMAIN}/api/invite-user`, {
                'users': [user]
            })
                .then((response) => {
                    toastSuccess("Berhasil menambah user", "left", "success")
                    window.location.reload();
                })
                .catch((error) => {
                    if (error.response.status == 403) {
                        toastError("User telah mencapai batas maksimal", "left", "failed")
                    } else {
                        toastError("Gagal menambah user", "left", "failed")
                    }
                })
        }
    }

    function updateData(field, value) {
        let newUser = { ...user };
        newUser[field] = value;
        setUser(newUser)
    }

    return (
        <div className={props.toggleAddUser === true ? 'fixed w-screen h-screen top-0 right-0 flex justify-end toggle-menu' : 'hidden'}>
            <div>
                <div className='title flex flex-wrap items-center'>
                    <div className='basis-10/12'>
                        <h3>Tambah User</h3>
                    </div>
                    <div className='basis-2/12 flex justify-end'>
                        <button className='btn btn-transparent mr-0' onClick={props.toggleUser}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 6.58579L14.364 0.221826L15.7782 1.63604L9.41426 8L15.7782 14.364L14.364 15.7782L8.00004 9.41421L1.63608 15.7782L0.22187 14.364L6.58583 8L0.22187 1.63604L1.63608 0.221826L8.00004 6.58579Z" fill="black" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className='content'>
                    <div>
                        <label htmlFor="namaBranch">Nama User</label>
                        <input type="text" className="text" name="" id="" value={user.name} onChange={e => updateData('name', e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="namaBranch">Email</label>
                        <input type="email" className="text" name="" id="" value={user.email} onChange={e => updateData('email', e.target.value)} />
                    </div>

                    <div>
                        <label htmlFor="provinsi">Role</label>
                        <div className='relative'>
                            <div className='svg-pin'></div>
                            <select className="select w-full" id="" value={props.status} onChange={(e) => updateData('status', e.target.value)} placeholder="Pilih Role">
                                <option disabled>Pilih Role</option>
                                <option>Content Designer</option>
                                <option>Content Publisher</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <button className='btn' onClick={saveUser.bind(this)}> SIMPAN </button>
                    </div>
                </div>
            </div>
        </div>
    )
}