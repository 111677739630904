import React, { useState, useEffect } from 'react'
import ApiService from '../../services/api.service'
import Navbar from '../../components/menu/Navbar'
import Dot from '../../components/icon/Dot'
import Pencil from '../../components/icon/Pencil'
import Trash from '../../components/icon/Trash'
import TrashGray from '../../components/icon/TrashGray'
import Close from '../../components/icon/Close'
import CreateUser from '../../components/user/CreateUser'
import EditUser from '../../components/user/EditUser'
import axios from 'axios'
import { toastSuccess, toastError } from '../../components/toast';
import { useNavigate } from "react-router-dom";

export default function UpdatePassword() {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState()
  
  const updatePassword = async () => {
    if (currentPassword == "" || newPassword == "" || newPasswordConfirmation == "") {
      toastError("Silahkan isi semua field yang ada.", "center", "validation")
    } else if (newPassword != newPasswordConfirmation) {
      toastError("Konfirmasi password tidak sesuai!", "center", "validation")
    } else {
      const data = {
        'currentPassword': currentPassword,
        'newPassword': newPassword,
        'newPasswordConfirmation': newPasswordConfirmation,
      }

      ApiService.init();
      ApiService.reInitBaseURLAWD();
      await ApiService
        .put("api/update-password", data, {})
        .then((response) => {
            toastSuccess("Password berhasil diupdate!", "center", "success")
            navigate('/dashboard')
          })
          .catch((error) => {
            toastError("Password gagal diupdate!", "center", "failed")
          });
    }
  }

  if (localStorage.getItem('role') === 'Admin') {
    return (
      <div className="w-full min-h-screen h-fit bg-white cabang">
        <div className="top-content">
          <Navbar />
          <div className="content">
            <div className="header flex flex-wrap">
              <div className="basis-4/12 title">
                <h3>Ubah Password</h3>
              </div>
            </div>
            <div className="grid grid-cols-2 mb-2">
              <input
                type="password"
                className={`input input-bordered !outline-0 input-bordered !border-custom-purple-light text-gray-900 text-sm !focus:ring-0 !focus:ring-offset-0 focus:border-2 block w-full`}
                placeholder="Password Lama"
                value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}
                name="currentPassword"
              />
            </div>
            <div className="grid grid-cols-2 mb-2">
              <input
                type="password"
                className={`input input-bordered !outline-0 input-bordered !border-custom-purple-light text-gray-900 text-sm !focus:ring-0 !focus:ring-offset-0 focus:border-2 block w-full`}
                placeholder="Password"
                value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                name="newPassword"
              />
            </div>
            <div className="grid grid-cols-2 mb-2">
              <input
                type="password"
                className={`input input-bordered !outline-0 input-bordered !border-custom-purple-light text-gray-900 text-sm !focus:ring-0 !focus:ring-offset-0 focus:border-2 block w-full`}
                placeholder="Konfirmasi Password"
                value={newPasswordConfirmation} onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                name="newPasswordConfirmation"
              />
            </div>

            <div className="my-4">
              <div className="relative">
                <button type='button' onClick={updatePassword} className='btn'> SIMPAN </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
